/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : publisherCompanyinformation.css
 *Desc: CSS code changes
 */
 @media only screen and (max-width : 768px) {
    .captcha
    {
    padding-left: 60px;
    }
    .picky__input {
        height: 40px !important;
        width: 58% !important;
    }
    .logo-1{
        width:83%!important;
    }
    .ant-form label{
        font-size: 13px!important;
        font-weight: 700!important;
    }
    }
/* media query for captcha at tablet  size*/
@media only screen and (min-width : 992px) {
    .captcha
    {
    padding-left: 80px;
    }
    }
    /* media query for captcha at tablet  size*/ 

    @media only screen and (min-width: 1200px){
    .captcha
    {
    padding-left: 120px;
    }
    }
    /* media query for captcha at laptop large size and above*/ 
    /* kiran-bug 4239-changes done for responsiveness issue */
    @media only screen and (min-width: 375px) and (max-width: 425px){
        #upload_logo {
            padding-left: 0px !important;
            text-justify: auto;
          }
          #adj_file {
            margin-left: 30px !important;
          }
        .logo-1{
          margin-left:165px!important;
          width:115px!important;
        }
        .captcha
       {
        margin-left: -57px !important;
       }
       .picky__input {
        height: 40px !important;
        width: 58% !important;
    }
    }
    @media only screen and (min-width: 320px)and (max-width: 375px){
      
        #upload_logo {
            padding-left: 0px !important;
            text-justify: auto;
          }
          #adj_file {
            margin-left: 30px !important;
            margin-top: -10px !important;
          }
        .logo-1{
          margin-left:140px!important;
          width:110px!important;
        }
        .picky__input {
            height: 40px !important;
            width: 58% !important;
        }
       
        .captcha
       {
        margin-left: -53px !important;
       }

    }
    @media only screen and (max-width: 320px){
        .logo-1{
          margin-left:135px!important;
        }
    }
    @media only screen and (min-width: 768px)and (max-width: 1024px){
        .logo-1{
            width:150%!important;
        }
    }
    @media only screen and (min-width: 1024px)and (max-width: 1440px){
       
    }
    @media only screen and (max-width: 1024px){
        .picky__input {
            height: 40px !important;
            width: 58% !important;
        }
    }
    @media only screen and (max-width: 1024px) and (min-width: 768px) {
        .picky__input {
            height: 40px !important;
            width: 58% !important;
        }
    }        
         /* kiran-bug 4239-added media query for responsiveness */
    @media only screen and (max-width: 425px) and (min-width: 320px) {
        #adj_file {
        margin-top: -4px !important;
        }
    }

    @media only screen and (max-width: 375px){
        .captcha
        {
         margin-left: -69px !important;
         width: 100% !important;
        }

    }

    @media screen and (min-width: 320px) {
        .captcha {
          margin-left: -91px !important;
          margin-top: 30px !important;
          width: 100% !important;
        }
      }
      @media screen and (min-width: 768px) {
        .captcha {
            padding-left: 127px!important;
        }
      }
      @media screen and (min-width: 1024px) {
        .captcha {
            padding-left: 205px!important;
        }
      }
      @media screen and (min-width: 1440px) {
        .captcha {
            padding-left: 280px!important;
        }
      }