/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/* @uther Mufiz Khan
   Bug-4114
   File-changPassword.css
   Title-RESPONSIVE -Prod issue-Ref-(3910)-Agency-setting option-Responsiveness issue(On priority) */

@media only screen and (max-width: 320px) {
  #cardMainDiv {
    margin-left: -18px;
    padding: 27px;
    width: 56rem;
    height: 188rem;
    margin-top: -32px;
    background-color: #d8dde6;
  }
  #containerForPsFirs {
    background-color: #d8dde6;
    position: absolute;
    width: 113% !important;
    height: 113% !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 375px) {
  #cardMainDiv {
    margin-left: -18px;
    padding: 27px;
    width: 55rem;
    height: 197rem;
    scroll-snap-stop: 0;
    margin-top: -32px;
    background-color: #d8dde6;
  }
  #containerForPsFirs {
    background-color: #d8dde6;
    position: absolute;
    width: 113% !important;
    height: 113% !important;
  }
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  #cardMainDiv {
    margin-left: -18px;
    padding: 27px;
    width: 70rem;
    height: 212rem;
    scroll-snap-stop: 0;
    margin-top: -32px;
    background-color: #d8dde6;
  }
  #containerForPsFirs {
    background-color: #d8dde6;
    position: absolute;
    width: 113% !important;
    height: 113% !important;
  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  #cardMainDiv {
    padding: 132px;
    width: 77rem;
    height: 219rem;
    margin-top: -110px;
    background-color: #d8dde6;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #cardMainDiv {
    margin-top: -72px;
    padding: 172px;
    padding-top: 9rem;
    width: 92rem;
    height: 127rem;
    background-color: #d8dde6;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  #cardMainDiv {
    padding: 172px;
    padding-top: 9rem;
    width: 144rem;
    height: 168rem;
    margin-top: -83px;
    background-color: #d8dde6;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 2000px) {
  #cardMainDiv {
    padding: 172px;
    padding-top: 14rem;
    /* width: 200rem; */
    height: 105rem;
    margin-top: -110px;
    background-color: #d8dde6;
  }
}

#CardIdPs {
  width: 50rem;
}
#cardBodyForPass {
  background-color: #d8dde6;
}
#cardMainDiv {
  border: none;
}

@media only screen and (min-width: 2000px) and (max-width: 2560px) {
  #cardMainDiv {
    padding: 172px;
    height: 158rem;
    width: 257rem;
    padding-top: 17rem;
    margin-bottom: 49rem;
    margin-top: -110px;
    background-color: #d8dde6;
  }
  #cardBodyForPass {
    padding-bottom: 51rem;
    width: 256rem;
    background-color: #d8dde6;
    margin-left: -170px;
  }
}
/* kiran-bug-4249-added media query for responsiveness */
/* 4251 - Umesh Ahire - R28.8-Publisher login-Setting option-change password page issue  */
@media only screen and (min-width: 320px) and (max-width: 767px) {
.cp-container .ant-card{
  width: 100% !important;
  height: auto !important; 
  margin: 3% !important; 
  display: block !important; 
  border: 2px solid rgba(0, 0, 0, 0.11) !important; 
  border-radius: 8px !important; 
  background-color: rgba(20, 72, 152, 0) !important;
  padding: 5% !important;
}
  .changePassAdv .ant-card {
    width: 94% !important;
    height: auto !important;
    margin: 3% !important;
    display: block !important;
    border: 2px solid rgba(0, 0, 0, 0.11) !important;
    border-radius: 8px !important;
    background-color: rgba(20, 72, 152, 0) !important;
    padding: 5% !important;
  }
}


/* 4251 - Umesh Ahire - R28.8-Publisher login-Setting option-change password page issue  */
.bg-custom-light{
  background-color: rgb(216, 221, 230) !important; 
 }

.bg-Adv-container {    
  background-color: rgb(216, 221, 230) !important; 
 }
