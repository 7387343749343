/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :kiran mate
 *@fileName : forgotPasswordEmail.css
 *Desc: css code changes
 */
.error_reset_password .ant-form-explain {
	margin-top: -20px;
}
@media only screen and (min-width: 0px) and (max-width: 320px) {
	#forgot_password_background {
		background-image: url("/Android.png");
	}
	#forgot_password_otp_background {
		background-image: url("/Android.png") !important;
		background-size: "cover";
		background-position: "center";
		background-repeat: "no-repeat";
	}
	#forgot_password_reset_background {
		background-image: url("/Android.png") !important;
	}
	#forgot_password_form {
		margin-left: 386px;
		margin-right: -381px;
	}
	#forgot_password_otp_form_card {
		margin-left: 89px;
	}
	#forgot_password_reset_form_card {
		margin-left: 89px;
	}
}
/* kiran-4280-change media query */
@media only screen and (min-width: 320px) and (max-width: 375px) {
	#forgot_password_background {
		background-image: url("/Android.png");
	}
	#forgot_password_otp_background {
		background-image: url("/Android.png") !important;
	}
	#forgot_password_reset_background {
		background-image: url("/Android.png") !important;
	}
	#forgot_password_form {
		margin-left: 416px;
		margin-right: -359px;
	}
	#forgot_password_otp_form_card {
		margin-left: 73px;
		margin-top: -30px;
	}
	#forgot_password_reset_form_card {
		margin-left: 73px;
		margin-top: -30px;
	}
}
@media only screen and (min-width: 375px) and (max-width: 426px) {
	#forgot_password_background {
		background-image: url("/Android.png");
	}
	#forgot_password_otp_background {
		background-image: url("/Android.png") !important;
	}
	#forgot_password_reset_background {
		background-image: url("/Android.png") !important;
	}
	#forgot_password_form {
		margin-top: -50px;
		margin-left: 415px;
		margin-right: -368px;
	}
	#forgot_password_otp_form_card {
		margin-left: 97px;
		margin-top: -30px;
	}
	#forgot_password_reset_form_card {
		margin-left: 97px;
	}
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
	#forgot_password_background {
		background-image: url("/Login_skeleton_4.png");
	}
	#forgot_password_otp_background {
		background-image: url("/Login_skeleton_4.png");
	}
	#forgot_password_reset_background {
		background-image: url("/Login_skeleton_4.png");
	}
	#forgot_password_form {
		margin-left: 210px;
		margin-right: -176px;
	}
	#forgot_password_otp_form {
		margin-left: -518px;
		margin-right: -176px;
	}
	#forgot_password_reset_form {
		margin-left: -600px;
		margin-right: -176px;
	}
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
	#forgot_password_background {
		background-image: url("/Login_skeleton_3.png");
	}
	#forgot_password_otp_background {
		background-image: url("/Login_skeleton_3.png");
	}
	#forgot_password_reset_background {
		background-image: url("/Login_skeleton_3.png");
	}
	#forgot_password_form {
		margin-left: 136px;
		margin-right: -97px;
	}
	#forgot_password_otp_form {
		margin-left: -540px;
		margin-right: -97px;
	}
	#forgot_password_reset_form {
		margin-left: -657px;
		margin-right: -97px;
	}
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
	#forgot_password_background {
		background-image: url("/Login_skeleton_3.png");
	}
	#forgot_password_otp_background {
		background-image: url("/Login_skeleton_3.png");
	}
	#forgot_password_reset_background {
		background-image: url("/Login_skeleton_3.png");
	}
	#forgot_password_form {
		margin-left: 0px;
		margin-right: 29px;
	}
	#forgot_password_otp_form {
		margin-left: -689px;
		margin-right: 29px;
	}
	#forgot_password_reset_form {
		margin-left: -689px;
		margin-right: 29px;
	}
}

@media only screen and (min-width: 1441px) and (max-width: 2560px) {
	#forgot_password_background {
		background-image: url("/Login_skeleton_3.png");
	}
	#forgot_password_otp_background {
		background-image: url("/Login_skeleton_3.png");
	}
	#forgot_password_reset_background {
		background-image: url("/Login_skeleton_3.png");
	}
	#forgot_password_form {
		margin-top: -56px;
	}
	#forgot_password_otp_form {
		margin-left: -799px;
		margin-right: -15px;
		padding-top: 40px;
	}
	#forgot_password_reset_form {
		margin-left: -799px;
		margin-right: -15px;
		padding-top: 80px;
	}
}
