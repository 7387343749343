/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :kiran mate
 *@fileName : dashboardPublisher.css
 *Desc: css code changes
 */
.topnav {
  overflow: hidden;
  background-color: #f1f1f1;
  height:35px;
}
.custom-class {
  background-image: linear-gradient(to right,#144999,#144999);
  }
  /* .modal-footer {
    border-top: 3px solid #0000b3;
  } */
  .cust-class {
    position:absolute;
    top:58% !important;
    left:28%;
    transform: translate(0, -50%) !important;
    -ms-transform: translate(0, -50%) !important;
    -webkit-transform: translate(0, -50%) !important;
    margin:auto 5%;
    width:90%;
    height:80%;
  }
  .cancel-button{
    border-radius: 3px;
    border: 2px solid #144999;
  
  }
.topnav a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 5px 16px;
  text-decoration: none;
  font-size: 12px;
  font-weight:500;
  font-family:'roboto';
  border-bottom: 3px solid transparent;
}
.topnav a:hover {
  /* border-bottom: 3px solid #144c9b; */
  /* font-size: 18px; */
  /* color:#00b3b3  sunita-task-3008- commented code it's giving bluish color to tab name */
  color:#000 !important;
}
.topnav a.active {
  border-bottom: 3px solid #144c9b;
  opacity: 1.0; /* sunita-task-3008-opacity given to tabNames */
}
/*  sunita -task-3008- added for increasing font size and opacity of tab name  */
.topnav .countClass
{
  font-size:16px !important;
  color: black;
  opacity: 0.4;

}
/* Bidding And Creatives Button */
.ant-btn {
    color: #fff;
  background-image: linear-gradient(to right,#28166f,#007cc3);
}
.ant-btn:hover, .ant-btn:focus {
  color: #fff;
  background-image: linear-gradient(to right,#28166f,#007cc3);
  /* border-color: #40a9ff;*/
}
.NewCampaign {
  /* width: 110px;
  height: 30px;
  padding-top: 8px;
  border-radius: 9px; */
  background-image: linear-gradient(to right,#28166f,#007cc3);
  /* display: inline-block;
  vertical-align: middle;
  text-align: center;
  color:white;
  font-size:12px; */
}
.Counter {
  /* width: 135px;
  height: 30px;
  padding-top: 8px;
  border-radius: 9px; */
  background-image: linear-gradient(to right,#28166f,#007cc3);
  /* display: inline-block;
  vertical-align: middle;
  text-align: center;
  color:white;
  font-size:12px; */
}
.RFP {
  /* width: 135px;
  height: 30px;
  padding-top: 8px;
  border-radius: 9px; */
  background-image: linear-gradient(to right,#28166f,#007cc3);
  /* display: inline-block;
  vertical-align: middle;
  text-align: center;
  color:white;
  font-size:12px; */
}
.NewsLetter {
  /* width: 110px;
  height: 30px;
  padding-top: 8px;
  border-radius: 9px; */
  background-image: linear-gradient(to right,#28166f,#007cc3);
  /* display: inline-block;
  vertical-align: middle;
  text-align: center;
  color:white;
  font-size:12px; */
}
.CallScript {
  /* width: 110px;
  height: 30px;
  padding-top: 8px;
  border-radius: 9px; */
  background-image: linear-gradient(to right,#28166f,#007cc3);
  /* display: inline-block;
  vertical-align: middle;
  text-align: center;
  color:white;
  font-size:12px; */
}
.LandingPage {
  /* width: 110px;
  height: 30px;
  padding-top: 8px;
  border-radius: 9px; */
  background-image: linear-gradient(to right,#28166f,#007cc3);
  /* display: inline-block;
  vertical-align: middle;
  text-align: center;
  color:white;
  font-size:12px; */
}
.NewCampaignG{
  /* width: 110px;
  height: 30px;
  padding-top: 8px;
  border-radius: 9px; */
  background-image: linear-gradient(to right,#ffffff,#ffffff) !important;
  color:black !important;
  /* display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size:12px; */
}
.CounterG {
  /* width: 135px;
  height: 30px;
  padding-top: 8px;
  border-radius: 9px; */
  background-image: linear-gradient(to right,#ffffff,#ffffff) !important;
  color:black !important;
  /* display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size:12px; */
}
.RFPG {
  /* width: 135px;
  height: 30px;
  padding-top: 8px;
  border-radius: 9px; */
  background-image: linear-gradient(to right,#ffffff,#ffffff) !important;
  color:black !important;
  /* display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size:12px; */
}
.NewsLetterG {
  background-image: linear-gradient(to right,#ffffff,#ffffff) !important;
  color:black !important;
  /* width: 110px;
  height: 30px;
  padding-top: 8px;
  border-radius: 9px;
  background-color: white;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color:black;
  font-size:12px;
 */
}
.CallScriptG {
  background-image: linear-gradient(to right,#ffffff,#ffffff) !important;
  color:black !important;
  /* width: 110px;
  height: 30px;
  padding-top: 8px;
  border-radius: 9px;
  background-color: white;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color:black;
  font-size:12px;
 */
}
.LandingPageG {
  /* width: 110px;
  height: 30px;
  padding-top: 8px;
  border-radius: 9px;
  background-color: white;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  color:black;
  font-size:12px; */
  background-image: linear-gradient(to right,#ffffff,#ffffff) !important;
  color:black !important;
}
/* kiran- bug 4255-changes classname to resolved conflicts for responsiveness */
.dropdownPub {
position: relative;
/* display: inline-block; */
}
.dropdown-contentPub {
display: none;
position: absolute;
right: 0;
background-color: #f9f9f9;
min-width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 100000;
}
.dropdown-contentPub a {
color: black;
padding: 6px 8px;
text-decoration: none;
display: block;
}
.dropdown-contentPub a:hover { background-image: linear-gradient(to right,#28166f,#007cc3);color:white}
.dropdownPub:hover .dropdown-contentPub {display: block;}
/* .dropdown:hover .dropbtn {background-color: #3e8e41;} */
.table-header{
color: #fff; 
background-image: linear-gradient(to right,#144999,#144999);
}
.add-button
{
background-color: #144999;
color:white;
}
.ant-progress-inner {
position: relative;
display: inline-block;
width: 100%;
vertical-align: middle;
background-color: #b3aaaa;
border-radius: 100px;
}
.ant-progress-text {
display: inline-block;
/* width: 2em; */
margin-left: 8px;
color:#333!important;
font-size: 1em;
line-height: 1;
white-space: nowrap;
text-align: left;
vertical-align: middle;
word-break: normal;
font-weight: 600;
}
div.dot1 {
white-space: nowrap; 
/* width: 48%;  sunita-task-3008-commented this css as it's affecting*/
overflow: hidden;
text-overflow: ellipsis;   
text-align: left;
}
div.dot2 {
white-space: nowrap; 
width: 155px; 
overflow: hidden;
text-overflow: ellipsis;   
text-align: left;
}
.fadeOut{
opacity:0;
width:0;
height:0;
transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.fadeIn{
opacity:1;
width:600px;
height:260px;
transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}
/* Button used to open the chat form - fixed at the bottom of the page */
.open-button {
background-color: #555;
color: white;
padding: 16px 20px;
border: none;
cursor: pointer;
opacity: 0.8;
position: fixed;
bottom: 23px;
right: 28px;
width: 280px;
}
/* The popup chat - hidden by default */
.chat-popup {
display: none;
position: fixed;
bottom: 0;
right: 15px;
border: 3px solid #f1f1f1;
z-index: 9;
}
/* Add styles to the form container */
.form-container {
max-width: 300px;
padding: 10px;
background-color: white;
}
/* Full-width textarea */
.form-container textarea {
width: 100%;
padding: 15px;
margin: 5px 0 22px 0;
border: none;
background: #f1f1f1;
resize: none;
min-height: 200px;
}
/* When the textarea gets focus, do something */
.form-container textarea:focus {
background-color: #ddd;
outline: none;
}
/* Set a style for the submit/send button */
.form-container .btn {
background-color: #4CAF50;
color: white;
padding: 16px 20px;
border: none;
cursor: pointer;
width: 100%;
margin-bottom:10px;
opacity: 0.8;
}
/* Add a red background color to the cancel button */
.form-container .cancel {
background-color: red;
}
/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
opacity: 1;
}
.footer {
position: fixed;
left: 0;
bottom: 0;
width: 100%;
background-color: #555;
color: white;
text-align: center;
z-index: 1;
/* padding-bottom: 42px; */
}
div.dotUnreadAlert {
white-space: nowrap; 
overflow: hidden;
text-overflow: ellipsis;   
text-align: left;
}
@media only screen and (max-width: 320px)
{
/* .container-fluid {
 
  width: 208% !important;
} */
.pacingStyle
{
  font-size: 18px!important;
  width: 150px !important;
  height: 40px !important;
  font-family: roboto!important;
  background-color: white !important;
  border: 1.4px solid rgb(20, 76, 155) !important;
}
.agencyNameStyle
{
font-size: 18px!important;
width: 150px !important;
height: 40px !important;
font-family: roboto!important;
background-color: white !important;
border: 1.4px solid rgb(20, 76, 155) !important;
}
.filterStyle
{
font-size:19px!important;
font-Family: "roboto"! important;
}
.topnav.color a {
padding: 0px 16px !important;}
.rowStyle{margin-left: 19%!important}
.barchart1{
  margin-left: 30px !important;
}
.piechart1{
  /* margin-left: 30px !important; */
}
} 
@media only screen and (max-width: 425px) and (min-width: 320px)
{
.biddingDiv
{
width: 420px;
}
/* .barchart1{
  margin-left: 18px !important;
  width: 384px !important;
}
.piechart1{
  margin-left: -55px !important;
  width: 378px !important;
} */
}
@media only screen  and (max-width: 375px) and (min-width: 321px)
{
/* .container-fluid {width: 175% !important;} */
.pacingStyle
{
  font-size: 18px!important;
  width: 150px !important;
  height: 40px !important;
  font-family: roboto!important;
  background-color: white !important;
  border: 1.4px solid rgb(20, 76, 155) !important;
}
.agencyNameStyle
{
font-size: 18px!important;
width: 150px !important;
height: 40px !important;
font-family: roboto!important;
background-color: white !important;
border: 1.4px solid rgb(20, 76, 155) !important;
}
.filterStyle
{font-size:18px!important;
font-Family: "roboto"! important;
}
.topnav.color a { padding: 1px 16px !important;}
.rowStyle{margin-left: 19%!important}

.barchart1{
  margin-left: 20px !important;
}
}
@media only screen  and (max-width: 425px) and (min-width: 376px)
{
/* .container-fluid {
  width: 175% !important;
} */
.pacingStyle
{
    font-size: 18px!important;
    width: 150px !important;
    height: 40px !important;
    font-family: roboto!important;
    background-color: white !important;
    border: 1.4px solid rgb(20, 76, 155) !important;
}
.agencyNameStyle
{
  font-size: 18px!important;
  width: 150px !important;
  height: 40px !important;
  font-family: roboto!important;
  background-color: white !important;
  border: 1.4px solid rgb(20, 76, 155) !important;
}
.filterStyle
{
font-size:18px!important;
font-Family: "roboto"! important;
}
.topnav.color a {
padding: 1px 16px !important;}
.rowStyle{margin-left: 1%!important}
}

@media only screen and (max-width: 600px) and (min-width: 426px)
{
/* .container-fluid {
  width: 175% !important;
} */
.pacingStyle
{
    font-size: 18px!important;
    width: 150px !important;
    height: 40px !important;
    font-family: roboto!important;
    background-color: white !important;
    border: 1.4px solid rgb(20, 76, 155) !important;
}
.agencyNameStyle
{
  font-size: 18px!important;
  width: 150px !important;
  height: 40px !important;
  font-family: roboto!important;
  background-color: white !important;
  border: 1.4px solid rgb(20, 76, 155) !important;
}
.rowStyle{margin-left: 19%!important}
 /* sunita-task-3008-added css for filterimg */
 .filterImg{
  margin-top: -17px!important;
}
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) and (min-width: 601px)
{
.pacingStyle
{
  font-size: 14px!important;
  width: 116px !important;
  height: 31px !important;
  font-family: roboto!important;
  background-color: white !important;
  border: 1.4px solid rgb(20, 76, 155) !important;

}
.agencyNameStyle
{
  font-size: 14px!important;
  width: 116px !important;
  height: 31px !important;
  font-family: roboto!important;
  background-color: white !important;
  border: 1.4px solid rgb(20, 76, 155) !important;
}
.rowStyle{margin-left: -17%!important}
#reactgooglegraph-1{
  margin-left: 111px !important;
}
#reactgooglegraph-2{
  margin-left: 111px !important;
}
#reactgooglegraph-3{
  margin-left: 111px !important;
}
#reactgooglegraph-4{
  margin-left: 111px !important;
}
#reactgooglegraph-5{
  margin-left: 111px !important;
}
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (min-width: 769px)
{
.pacingStyle
{
  font-size: 14px!important;
  width: 132px !important;
  height: 32px !important;
  font-family: roboto!important;
  background-color: white !important;
  border: 1.4px solid rgb(20, 76, 155) !important;
}
.agencyNameStyle
{
  font-size: 14px!important;
  width: 132px !important;
  height: 32px !important;
  font-family: roboto!important;
  background-color: white !important;
  border: 1.4px solid rgb(20, 76, 155) !important;
}
.rowStyle{margin-left: 19%!important}
.piechart1{
  width: 289px !important;
  margin-left: 0px !important;
  margin-top: 15px !important;
} 
.barchart1{
  width: 310px !important;
  /* display: block; */
  margin-left: 33px !important;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (min-width: 993px)
{
.pacingStyle
{
  font-size: 14px!important;
  width: 132px !important;
  height: 32px !important;
  font-family: roboto!important;
  background-color: white !important;
  border: 1.4px solid rgb(20, 76, 155) !important;

}
.agencyNameStyle
{
  font-size: 14px!important;
  width: 132px !important;
  height: 32px !important;
  font-family: roboto!important;
  background-color: white !important;
  border: 1.4px solid rgb(20, 76, 155) !important;
}
.rowStyle{margin-left: 19%!important}
/* .piechart1{
  width: 350px !important;
  margin-left: 29px !important;
  margin-top: 15px !important;
} 
.barchart1{
  width: 369px !important;
  margin-left: 91px !important;
  margin-top: 10px !important;
  } */
}
@media only screen and (min-width: 1024px)
{
.pacingStyle
{
  font-size: 14px!important;
  width: 132px !important;
  height: 32px !important;
  font-family: roboto!important;
  background-color: white !important;
  border: 1.4px solid rgb(20, 76, 155) !important;
}
.agencyNameStyle
{
  font-size: 14px!important;
  width: 132px !important;
  height: 32px !important;
  font-family: roboto!important;
  background-color: white !important;
  border: 1.4px solid rgb(20, 76, 155) !important;
}
.rowStyle{margin-left: 2%!important}
}
@media only screen and (min-width: 1440px)
{
.pacingStyle
{
  font-size: 14px!important;
  width: 132px !important;
  height: 32px !important;
  font-family: roboto!important;
  background-color: white !important;
  border: 1.4px solid rgb(20, 76, 155) !important;
}
.agencyNameStyle
{
  font-size: 14px!important;
  width: 132px !important;
  height: 32px !important;
  font-family: roboto!important;
  background-color: white !important;
  border: 1.4px solid rgb(20, 76, 155) !important;
}
.rowStyle{margin-left: 2%!important}
}
@media only screen and (min-width: 320px) and (max-width: 425px){
.head-one{
 font-weight: 500!important;
 font-size: 18px!important;
}

.barchart1{
  margin-left: 18px !important;
  width: 384px !important;
}
.piechart1{
  margin-left: -55px !important;
  width: 378px !important;
}
/* sunita-task-3008-added css for filterimg */
.filterImg{
  margin-top: 4px!important;
}
/* sunita-task-3008-added css for aligning progressreport */
.progressRport{
  margin-left: 0px !important;
}
}
@media only screen and (min-width: 768px) and (max-width: 1440px){
  .head-one{
   font-weight: 500!important;
   font-size: 22px!important;
  }
  }
 
    @media only screen and (max-width: 425px){
      #Cancel1{
        padding: 5px 21px!important;
      }
  /* sunita-task-3054- added the css for setting the alignment */
      .tabPause
      {
        margin-top: 38px !important;
        margin-left: -96px !important;
      }
    }
 @media only screen and (min-width: 320px){
        .topnav-tab{
          float: left;
          display: block;
          text-align: center;
          padding: 4px 3px;
          text-decoration: none;
          font-size: 10px;
          font-weight: 500;
          font-family: 'roboto';
          border-bottom: 3px solid transparent;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 1440px){
      .topnav-tab{
        float: left;
        display: block;
        text-align: center;
        padding: 6px 16px;
        text-decoration: none;
        font-size: 12px;
        font-weight: 550;
        font-family: 'roboto';
        border-bottom: 3px solid transparent;
    }
    }
    @media only screen and (min-width: 768px) and (max-width: 1440px){
      .topnav-tab{
        float: left;
        display: block;
        text-align: center;
        padding: 8px 35px;
        text-decoration: none;
        font-size: 14px;
        font-weight: 550px;
        font-family: 'roboto';
        border-bottom: 3px solid transparent;
    } 
     /* sunita-task-3008-added css for aligning filterimg */
     .filterImg{
      margin-top: 4px !important;
    }
    }
    @media only screen and (min-width: 320px){
      #myText-one{
        margin-left: 30px!important;
      }
    }
    @media only screen and (min-width: 375px){
      #myText-one{
        margin-left: 30px!important;
      }
      /* sunita-task-3008-added css for aligning completed tab */
      .tabComplete
      {
        margin-top: 2px !important;
      }
    }
    @media only screen and (min-width: 425px){
      #myText-one{
        margin-left: 30px!important;
      }
       /* sunita-task-3008-added css for aligning completed tab */
      .tabComplete {
        margin-top: 1px !important;
    }
      .barchart1{
        margin-left: 22px !important;
        width: 335px !important;
        margin-top: 25px !important;
      }
      .piechart1{
        margin-left: 23px !important;
        width: 328px !important;
        margin-top: 21px !important;
      }
    }
    @media only screen and (min-width: 768px){
      #myText-one{
        margin-left: 30px!important;
      }
      .piechart1{
        margin-left: 278px !important;
        width: 367px !important;
      }
      .barchart1{
        margin-left: 174px !important;
        width: 361px !important;
      }
     /* sunita-task-3008-added css for aligning progressreport */
    .progressRport
    {
      margin-left: 44px !important;
    }
    /* sunita-task-3008-added css for aligning canceltab */
    .tabCancel{
      margin-top: -19px !important;
    }
    }
   @media only screen and (min-width: 1024px){
      #myText-one{
        margin-left: 100px!important;
        margin-top: 15px!important;
      }
      .dropdown1-one{
        margin-top: 5px!important;
        margin-right: 30px!important;
        margin-left: 105px!important;
      }
      .barchart1{
        margin-left: -6px !important;
        width: 298px !important;
        margin-top: 36px !important;
      }
      .piechart1{
        margin-left: 6px !important;
        width: 334px !important;
      }
       /* sunita-task-3008-added css for filterimg */
    .filterImg{
      margin-top: 4px !important;
    }
    /* sunita-task-3008-added css for aligning progressreport */
    .progressRport{
      margin-left: 42px !important;
    }
    /* sunita-task-3008-added css for aligning canceltab */
    .tabCancel{
      margin-top: 1px !important;
    }
    }
    @media only screen and (min-width: 1440px){
      #myText-one{
        margin-left: 150px!important;
        margin-top: 15px!important;
      }
      .dropdown1-one{
        margin-left: 155px!important;
      }
      .barchart1{
        margin-left: 47px !important;
        width: 334px !important;
      }
      .piechart1{
        margin-left: 89px !important;
        width: 354px !important;
      }
      /* sunita-task-3008-added css for filterimg */
      .filterImg{
        margin-top: 4px!important;
      }
      /* sunita-task-3008-added css for aligning progressreport */
    .progressRport
    {
      margin-left: 65px !important;
    }
    }
    @media only screen and (min-width: 1200px){
      .barchart1{
        margin-left: 43px !important;
        width: 332px !important;
      }
      .piechart1{
        margin-left: 77px !important;
        width: 354px !important;
      }
    }
/* sandeep-task-2998 added code to handle vertical line */
    .vl {
      border-left: 0.1px solid lightgrey !important;
      height: 700px !important;
      position: sticky;
      margin-left: 330px !important;
    }
    /*sunita-task-3008- added css for alignment*/
   .filterDiv{
     padding-right: 0px !important;
   }
   /*sunita-task-3008- added css for alignment*/
   .progressRportDiv{
    padding-left: 13px !important;
   }
   /* sunita-task-3008-added css for setting the tab alignment */
.tabDiv{
  overflow: visible !important;
}

/*Chaitanya-4292-R29-Prod issue-Publisher login-dashboard vertical and horizontal lines issue*/
@media only screen and (min-width: 768px)
{
  .breakline
  {
    margin-Top: 6px !important
  }
}
@media only screen and (min-width: 1440px)
{
  .breakline
  {
    margin-Top: 8px !important
  }
}
/* kiran-4149-added media queries */
@media only screen and (max-width: 320px) {
   #cardmonthPub {
    min-width: 640px !important;
  }
  #cardclientPub {
    min-width: 640px !important;
  }  
  .demoPub {
    min-width: 632px !important;
  }
   .biddingDivPub
{
min-width: 580px !important;

}
.biddingDiv
{
  min-width: 580px !important;
} 
.unreadalertsPub {
  width: 580px !important;
  width: max-content;
}
}

@media only screen and (min-width: 321px) and (max-width: 375px){
   #cardmonthPub {
    min-width: 578px !important;
  }
  #cardclientPub {
    min-width: 578px !important;
  }  
  /* .demoPub {
    min-width: 570px !important;
  } */
 .biddingDiv
{
  min-width: 555px !important;
}
.biddingDivPub
{
min-width: 555px !important;
} 
}

@media only screen and (min-width: 1024px) {
  .unreadalertsPub {
    /* position: absolute !important; */
    width: 300px !important;
  }
}
@media only screen and (min-width: 768px) {
  .unreadalertsPub {
    /* position: absolute !important; */
    width: 300px !important;
  }
}
@media only screen and (min-width: 375px) and (max-width: 424px){
  #cardmonthPub {
    min-width: 640px !important;
  }
  #cardclientPub {
    min-width: 640px !important;
  } 
  .biddingDiv
  {
    min-width: 640px !important;
  }
  .biddingDivPub
  {
    min-width: 640px !important;
  }
  .demoPub
  {
    min-width: 640px !important;
  }
}
@media only screen and (min-width: 321px) and (max-width: 374px){
  .demoPub
  {
    min-width: 640px !important;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px){
  #cardmonthPub {
    min-width: 640px !important;
  }
  #cardclientPub {
    min-width: 640px !important;
  } 
  .demoPub
  {
    min-width: 640px !important;
  }
  .biddingDiv
  {
    min-width: 640px !important;
  }
  .biddingDivPub
  {
    min-width: 640px !important;
  }
}
/* Chaitanya-4402-Ref-(4389)-PC/PNC/PQA Login-Alerts page alignment issue */
@media only screen and (min-width: 1024px){
  .demoPub
  {
  margin-top: -92px !important;
  }
}