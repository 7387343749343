/* Copyright(c) 2020 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Sanjana Godbole
@filename:index.css
@desc:css changes done
@Creation Date:29-05-2019
*/

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html,
body,
#root {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
}

.App > div {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
}
