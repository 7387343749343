/* Copyright(c) 2012 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author: Nilesh Zinzurao
@Creation Date:04-01-2023
@Description:Used for labeles in reviewLinkAgency.js page
*/

/* 4154 -nilesh */
#visibleContentReviewLinkingAgency{
    font-weight: 400;
    font-family: -apple-system, "Segoe UI";
    color: #000080;
    fontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 3px;
}
