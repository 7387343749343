/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : publisherEmailConfig.css
 *Desc: 
 */
.selectAllPub {
  margin-right: -6rem;
  float: right;
}
.emailformPUB {
  margin-left: 90px;
  margin-right: 292px;
}

#labelDIPUB {
  font-family: roboto !important;
  font-size: 28px !important;
  font-weight: 500 !important;
  text-align: center !important;
  color: #193d8f;
}
.can-btn-email-1 {
  border: 2px solid #193d8f;
  background-color: transparent;
  border-radius: 2px;
  color: #193d8f;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  width: 74px;
  height: 33px;
  border-radius: 2px;
  margin-right: 12px;
}

.hFourP {
  margin-bottom: 20px;
}
@media only screen and (min-width: 371px) and (max-width: 375) {
  .hFourP {
    margin-bottom: 33px;
  }
}
@media only screen and (max-width: 348px) {
  .selectAllPub {
    margin-right: 15px;
    width: 112px;
  }

  .hFour {
    margin-bottom: 53px;
  }
}

@media only screen and (min-width: 348px) and (max-width: 375px) {
  .emailformPUB {
    margin-left: 0;
    margin-right: 36rem;
  }
  .selectAllPub {
    float: right;
    margin-right: -18rem;
    margin-top: 0rem;
  }
  .can-btn-email-1 {
    margin-right: -23rem;
  }
  #submitBtn-1 {
    margin-right: -33rem;
  }
  .hFourP {
    width: 18rem;
  }
}
@media only screen and (max-width: 347px) {
  .container-PUB {
    margin-left: -9rem;
  }
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  .emailformPUB {
    margin-left: 0rem;
  }
  .selectAllPub {
    margin-top: -2rem;
    float: right;
    margin-right: -22rem;
  }
  .can-btn-email-1 {
    margin-right: -18rem;
  }
  #submitBtn-1 {
    margin-right: -27rem;
  }
  .hFour {
    width: 15rem;
  }
}
@media only screen and (min-width: 425px) and (max-width: 426px) {
  .selectAllPub {
    margin-right: 4rem;
    float: right;
  }
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
  .background {
    margin-left: -23px;
    width: 40rem;
  }
  .emailformPUB {
    margin-left: 11px;
    margin-right: 13px;
  }
  .hFourP {
    margin-bottom: 20px;
    width: 31rem;
  }
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
  .emailformPUB {
    margin-left: 10% !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 976px) {
  #submitBtn-1 {
    margin-right: -27rem;
  }
  .can-btn-email-1 {
    margin-right: -173px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .emailformPUB {
    margin-left: 187px !important;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1496px) {
  .emailformPUB {
    margin-right: 18%;
    margin-left: 7%;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 2560px) {
  .emailformPUB {
    margin: 0px 40rem 2px 8%;
  }
  .container-PUB {
    margin-left: 143px;
  }
}

@media only screen and (min-width: 2561px) {
  .emailformPUB {
    margin: 10% !important;
  }
}

@media only screen and(min-width : 100px) and (max-width: 334px) {
  #submitBtn-1 {
    float: right;
    margin-right: 0px;
  }

  .can-btn-email-1 {
    float: right;
    margin-right: -135px;
  }
}
@media only screen and (min-width: 334px) and (max-width: 349px) {
  .can-btn-email-1 {
    margin-left: -21px;
  }
}
@media only screen and (min-width: 350px) and (max-width: 376px) {
  .background {
    width: 392px;
  }
}
@media only screen and (max-width: 348px) {
  .selectAllPub {
    margin-right: -27rem;
    margin-top: -5rem;
  }
}
.can-btn-email-1:hover {
  background: #193d8f;
  color: white;
}
@media only screen and (max-width: 320px) {
  .can-btn-email-1 {
    margin-right: -11rem;
  }
  #submitBtn-1 {
    margin-right: -22rem;
  }
}
@media only screen and (min-width: 321px) and (max-width: 348px) {
  .hFourP {
    margin-bottom: 53px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 769px) {
  .selectAllPub {
    margin-right: -12rem;
  }
  .container-PUB {
    margin-left: 11rem;
  }
}
@media only screen and (min-width: 769px) and (max-width: 991px) {
  .emailformPUB {
    margin-left: 100px;
    margin-right: 226px;
  }
  .selectAllPub {
    float: right;
    margin-right: -16rem;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1024px) {
  .emailformPUB {
    margin-right: 134px;
    margin-top: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .selectAllPub {
    margin-right: 87px;
  }
  @media only screen and (max-width: 768px) {
    .selectAllPub {
      margin-right: 62px;
    }
  }
}
@media only screen and (min-width: 992px) and (max-width: 1440px) {
  .emailformPUB {
    margin-right: 14%;
    margin-left: 15%;
  }
}
@media only screen and (min-width: 1224px) and (max-width: 1280px) {
  .container-PUB {
    margin-top: -107px;
    margin-left: 132px;
  }
  #labelDIPUB {
    margin-top: -151px;
  }
  #Container-PUB {
    margin-top: 140px;
  }
}
@media only screen and (min-width: 1441px) {
  .selectAllPub {
    float: flo;
    float: revert;
    width: 21rem;
    margin-left: 76rem;
  }
}

@media only screen and (min-width: 1379px) and (max-width: 1440px) {
  .emailformPUB {
    margin-left: 25%;
  }
}
@media only screen and (min-width: 1800px) {
  .emailformPUB {
    margin-right: 33%;
    margin-left: 25%;
  }
}
@media only screen and (min-width: 1500px) and (max-width: 2560px) {
  #submitBtn-1 {
    margin-right: -32rem;
  }
  .can-btn-email-1 {
    margin-right: -22rem;
  }
}

/*
@author:Chaitanya
@Description:Responsive issue bug- 4291
File-agencyEmailConfig.css
Title-4291-R29-Prod issue-Publisher-Setting option-Email Notifications-submit button responsive issue 
*/
@media only screen and (min-width: 320px) and (max-width: 375px) {
  #submitBtn-1 {
    margin-right: -30rem;
  }
  .can-btn-email-1 {
    margin-right: -22rem;
  }
  .emailformPUB {
    margin-left: 84px;
}
#other_pc_back{
  height: 180px !important;
}

}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  #submitBtn-1 {
    margin-right: -26rem;
  }
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
  #submitBtn-1 {
    margin-right: 0rem;
  }
  .emailformPUB
  {
    margin-left: 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #submitBtn-1 {
    margin-right: 2rem;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  #submitBtn-1 {
    margin-right: 1rem;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  #submitBtn-1 {
    margin-right: 5rem;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 2560px)  {
  #submitBtn-1 {
    margin-right: -30rem;
  }
  #other_ac_back {
    height: 132px !important;
  }
  /* kiran-task 4293-added height property for responsiveness */
}