/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*
@author:Sunita Landge/Sanjana Godbole
*@fileName : agencyReviewLead.css
@Description:added css for proper alignment of agencyReviewLead page /css change done 
*/

/*sunita-task-3270-added below all css for page alignement and setting the responsiveness as per new UI changes*/
/* Mufiz-Task-4033- Adding Media query For Responsiveness*/
.leadinfo {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   text-align: center;
}


@media only screen and (min-width: 320px) and (max-width: 425px) {
   #lead_button1 {
      margin-top: 1px !important;
   }

   #lead_button2 {
      margin-top: 1px !important;
   }


   #buttonone {
      margin-left: 0px !important;
   }

   #buttonTwo {
      margin-left: 35rem !important;
   }

   #labelleadreviewSecondTab {
      margin-left: 0rem !important;
      font-size: 28px !important;
   }


   #labelleadreview {
      margin-left: 16rem !important;
      font-size: 28px !important;
   }

   .campaignslists {
      margin-bottom: 500px !important;
   }

   /*sunita-task-3727-added below css as per requirement*/
   .campspecification {
      text-align: left !important;
      margin-bottom: 20px !important;
      margin-left: 4px !important;
   }

   /* Reff(3819)---agency side---review lead page responsive issue */

   /* Reff(3819)---agency side---review lead page responsive issue */
   .Multi_touch {
      margin-left: 0px !important;
   }

   #tabsContainer {
      width: 60rem;
   }

   .agencyLeadRvw {
      margin-top: 10rem;
   }

   .camp_specification {
      margin-left: 45rem;
   }

      {
      margin-top: 9rem;
   }

}

@media only screen and (min-width: 320px) {
   .submitBtn {
      margin-left: 40rem !important;
   }

   .campLeadHeading {
      margin-right: 25rem;
      width: 23rem;

   }

   .inputstyle4 {
      margin-left: 20px !important;
      width: 245px !important;
   }

   .campaignData1 {
      margin-top: 500px !important;
   }

}

@media only screen and (min-width: 375px) {

   

   .inputstyle4 {
      margin-left: 50px !important;

   }

   .campaignData1 {
      margin-top: 0px !important;
   }

}
@media only screen and (min-width: 425px) and (max-width: 575px){
   .submitBtn  {
      margin-left: 78rem !important;

   }
}
@media only screen and (min-width: 425px) and (max-width:768px){
 
   #buttonTwo {
      margin-left: 51rem !important;
  }

}
@media only screen and (min-width: 425px) and (max-width:1200px){


   .inputstyle4 {
      margin-left: 70px !important;

   }

   .campaignData1 {
      margin-top: 500px !important;
   }

   .add-button {
      margin-bottom: 2rem;
   }

   #Camp_revw_lab1 {

      width: 28rem;
      margin-right: 69rem;
   }

   .agencyLeadRvw {
      margin-top: 8rem;
   }


}

@media only screen and (min-width: 425px) and (max-width: 768px) {

   #tabsContainer {
      padding-top: 85px;
      width: 100rem;
      font-size: 16px;
   }

   .camp_specification {
      margin-left: 80rem;
      font-size: 18px;
      margin-top: -2rem;
   }

   #labelinternallead {
      font-size: 28px;
      font-family: roboto;
      font-weight: 500;
      color: rgb(25, 61, 143);
      text-align: center;
      margin-right: 0;
   }

   #labelleadreview {
      margin-left: 34rem;
   }

   .add-button {
      margin-bottom: 3rem;
   }
}


@media only screen and (min-width: 768px) {

   .submitBtn {
      margin-left: -48px !important;
   }

   /* #buttonone {
      margin-left: 65px !important;
   } */

   #buttonTwo {
      margin-left: -20px !important;
   }


   .campLeadHeading {
      margin-left: 0px !important;
   }

   #labelleadreview {
      margin-left: -2px !important;

   }

   .inputstyle4 {
      margin-left: 5px !important;
      width: 165px !important;
   }

   /*sunita-task-3727-added below css as per requirement*/
   .campspecification {
      margin-left: -12px !important;
   }

   /* Reff(3819)---agency side---review lead page responsive issue */
   .camp_specification {
      margin-top: -6px !important;
   }

   /* Reff(3819)---agency side---review lead page responsive issue */
   .Multi_touch {
      margin-left: -25px !important;
   }

   .add-button {
      margin-bottom: 3rem;
   }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
   #tabsContainer {
      width: 100rem;
      font-size: 15px;
   }

   .camp_specification {
      padding-right: 7rem;
      margin-left: 83rem;
   }

   #labelinternallead {
      margin-left: 15rem;
   }
}

@media only screen and (min-width: 1024px) and (max-width: 1024px) {
   .submitBtn {
      margin-left: -43px !important;
   }

   /* #buttonone {
      margin-left: 46px !important;
   } */

   #buttonTwo {
      margin-left: -12px !important;
   }

   .campLeadHeading {
      
      margin-left: 0px !important;
   }

   #labelinternallead {
      margin-left: 12rem;
   }

   #labelleadreview {
      margin-left: 90px !important;
      /*sunita-task-3727-added  css as per requirement*/

   }

   #lead_button2 {
      padding-left: 27px !important;
   }

   .inputstyle4 {
      margin-left: 10px !important;
      width: 223px !important;
   }

   /*sunita-task-3727-added below css as per requirement*/
   .campspecification {

      margin-left: 0px !important;
   }

   /* Reff(3819)---agency side---review lead page responsive issue */
   .Multi_touch {
      margin-left: -33px !important;
   }
}

@media only screen and (min-width: 1024px) and (max-width:1440px) {

   .agencyLeadRvw {
      margin-top: 0;
   }

}

@media only screen and (min-width: 1200px) {
   .submitBtn {
      margin-left: -3px !important;
   }

   #buttonone {
      /* margin-left: 100px !important;sunita-task-3181-added css for alignment */
      margin-left: 46px !important;

   }

   #buttonTwo {
      /* margin-left: 18px !important; sunita -task- 3270-added css for alignment*/
      margin-left: -38px !important;
   }

   .inputstyle4 {
      margin-left: 22px !important;
      width: 280px !important;
   }

   #labelleadreviewSecondTab {
      margin-left: 15rem;
   }


   #labelinternallead {
      margin-left: 21rem !important;
   }



   /*sunita-task-3727-added below css as per requirement*/
   .campspecification {

      margin-left: 0px !important;
   }
}


/* //shivani-task 3326 - css for responsiveness , for search bar , for button , for label and to truncate campaignName*/
.btn-defaulttab:hover {
   background: #193D8F !important;
   color: white !important;
}

@media only screen and (max-width: 320px) {
   #searchLeadId3 {
      margin-left: 20px !important;
      width: 245px !important;
   }

   #for-sm-mb1 {
      margin-top: 9rem;
   }

   #Camp_revw_lab1 {
      margin-right: 28rem;
   }

   .TabOne {
      margin-bottom: 1rem;

   }

   #tabsContainer {
      width: 60rem;
      font-size: 2rem;
   }

   .agencyLeadRvw {
      margin-top: 10rem;
   }

   .camp_specification {
      margin-top: 0;
      margin-left: 44rem;
   }

   .add-button {
      margin-bottom: 3rem;
   }
   #buttonTwo {
      margin-left: 35rem !important;
  }
}

@media only screen and (min-width: 375px) and (max-width: 375px) {
   #searchLeadId3 {
      /* margin-left:50px!important; */
      /* width: 285px !important; */
   }

   #for-sm-mb1 {
      margin-top: 9rem;
   }

   #Camp_revw_lab1 {
      margin-left: 0px !important;
   }

   .camp_specification {
      margin-top: 0;
      margin-left: 44rem;
   }

   #tabsContainer {
      width: 60rem;
      font-size: 2rem;
   }
}

@media only screen and (min-width: 425px) and (max-width: 425px) {
   #searchLeadId3 {
      margin-left: 45px !important;

   }

   #for-sm-mb1 {
      margin-top: 9rem;
   }

   #Camp_revw_lab1 {
      margin-left: 0px !important;
   }

   .camp_specification {
      margin-top: 0;
      margin-left: 44rem;
   }

   #tabsContainer {
      width: 60rem;
      font-size: 2rem;
   }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
   #searchLeadId3 {
      margin-left: 5px !important;
      width: 165px !important;
   }

   #Camp_revw_lab1 {
      margin-left: 0px !important;
   }

}

@media only screen and (min-width: 1024px) and (max-width: 1024px) {
   #searchLeadId3 {
      margin-left: 10px !important;
      width: 223px !important;
   }

   #Camp_revw_lab1 {
      margin-left: 0px !important;
   }
}

@media only screen and (min-width: 1440px) {
   #searchLeadId {
      margin-left: 22px !important;
      width: 280px !important;
   }
}

#nameTruncate {
   overflow: hidden !important;
   max-width: 30% !important;
   text-overflow: ellipsis !important;
   white-space: nowrap !important;
   display: inline-block !important;
   margin: 0 !important;
   vertical-align: top !important;
}

#uploadNameTruncate {
   overflow: hidden !important;
   max-width: 110px !important;
   text-overflow: ellipsis !important;
   white-space: nowrap !important;
   display: inline-block !important;
   margin: 0 !important;
   vertical-align: top !important;
}

/* //sunita's code */
.reviewcampdata {
   overflow: hidden !important;
   max-width: 300px !important;
   /* Saurabh-task-3762-campaign name display issue */
   text-overflow: ellipsis !important;
   white-space: nowrap !important;
   display: inline-block !important;
   margin: 0 !important;
   vertical-align: top !important;
}

.reviewcampdata1 {
   overflow: hidden !important;
   max-width: 150px !important;
   text-overflow: ellipsis !important;
   white-space: nowrap !important;
   display: inline-block !important;
   margin: 0 !important;
   vertical-align: top !important;
}

/* Sandeep-task-3441-added code for loader */
/* #loader_review{
   position: absolute;
   width: 25%;
   height: 20vh;
   background: white url('/../new_loader.gif') no-repeat center;
   z-index: 999;
   margin-left: 300px !important;
   margin-top: 80px !important;
}  */
#loader_review {
   position: absolute;
   width: 25%;
   height: 20vh !important;
   /* sandeep-task-3566-added code for loader issue */
   background: white url('/../new_loader.gif') no-repeat center;
   z-index: 999;
   margin-left: 300px !important;
   margin-top: 70px !important;
   /* sandeep-task-3566-added code for loader issue */
}

/* Sandeep-task-3651-Alignment and color variation issue--in AQA lead review section */
th.emailcolor {
   background-color: #193D8F !important;
}
/* Chaitanya-task-4342-Prod issue-Agency Login-lead review-Multitouch alignment issue*/
@media only screen and (min-width: 320px) and (max-width: 375px) {
.leadReviewCampSpecs
{
   margin-top: 23%;
}
.campLeadHeading
{
   margin-right: 30rem;
}
.campLeadHeading1 
{
   margin-right: 54%;
}
.inputstyle2
{
   margin-left: 3%;
   width: 40% !important;
}
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
.leadReviewCampSpecs
{
   margin-top: 16%;
}
.campLeadHeading
{
   margin-right: 30rem;
}
.campLeadHeading1 
{
   margin-right: 54%;
}
.inputstyle2
{
   margin-left: 3%;
   width: 41% !important;
}
}
@media only screen and (min-width: 425px) and (max-width: 767px) {
.leadReviewCampSpecs
{
   margin-top: 12%;
}
.campLeadHeading1 
{
   margin-right: 73%;
}
.campLeadHeading
{
   margin-right: 73%;
}
.inputstyle2
{
   margin-left: 2%;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
.inputstyle2
{
   width: 23rem !important ;
}
.campLeadHeading1 
{
   margin-right: 0%;
}
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
.campLeadHeading1 
{
   margin-right: 28%;
}
}
@media only screen and (min-width: 1440px){
.campLeadHeading1 
{
   margin-right: 35%;
}
}
