/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author :kiran mate
 *@fileName : supportingDocumentNew.css
 *Desc: css code changes
 */
.ant-upload.ant-upload-drag {
  margin-left: 29% !important;
  width: 42% !important;
  height: 69% !important;
}
.ant-upload-list.ant-upload-list-text {
  width: 507px !important;
  overflow-y: scroll !important;
  height: 200px !important ;
  display: none;
}
.upload-btn-wrapper {
  overflow: visible;
}
.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 156px 0px 85px 46%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}
.files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}
.files {
  position: relative;
}
.files:after {
  pointer-events: none;
  position: absolute;
  top: 70px;
  left: 0;
  width: 45px;
  right: 0;
  height: 45px;
  content: "";
  /* background-image: url(https://qph.fs.quoracdn.net/main-qimg-ba5b717fbcf5043e4ac32d5669417dff); */
  background-image: url("/file-upload_new.png");
  color: black;
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
.color input {
  background-color: #f1f1f1;
}
.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: " Drag and Drop Or Browse your files to upload";
  display: block;
  margin: 0 auto;
  color: #989daa;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}
.ant-tooltip-inner {
  padding: 6px 27px;
}
/* snehal-changes for format and customize mapping */
.customMapFormatDiv {
  padding-left: 244px !important;
}
.pointer {
  cursor: pointer !important;
}
@media only screen and (max-width: 768px) {
  .form-control .inputStyle .frmt {
    margin-left: 62px !important;
    margin-top: -26px !important;
  }
  .cont {
    margin-top: 50px !important;
  }
  .format {
    width: 60px !important;
    margin-left: 14px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .format-cust {
    margin-left: 59px !important;
  }
  /* Sandeep-task-3195-added code for handle checkbox issue */
  #label {
    white-space: nowrap;
    width: 155px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .format {
    width: 70px !important;
  }
}
/* snehal-changes for format and customize mapping */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .customMapFormatDiv {
    padding-left: 132px !important;
  }
  .formatCss {
    margin-left: 36px !important;
    margin-right: -96px !important;
  }
}
/* snehal-changes for format and customize mapping */
@media only screen and (max-width: 425px) {
  .customMapFormatDiv {
    padding-left: 70px !important;
  }
  .formatCss {
    margin-left: 62px !important;
    margin-right: -95px !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .format-cust {
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
  .custMap {
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 425px) {
  #toolpop {
    margin-left: -10px !important;
    margin-top: 0px !important;
  }
  .format-cust {
    margin-right: 50px !important;
  }
  #toolpop1 {
    margin-left: -31px !important;
    margin-top: 0px !important;
  }
  .customizeMapping {
    margin-left: 0px !important;
  }
  .format {
    margin-left: 10px !important;
  }
  .asset1 {
    width: 110px !important;
  }
  /* Sandeep-task-3195-added font size for Quality Assurance */
  #quality {
    font-size: 14px !important;
  }
  /* Sandeep-task-3195-added margin top for Delivery format */
  #deliveryfor {
    margin-top: 10px !important;
  }
}
/* Sandeep-task-3195-added margin right for  mutlitouch checkbox */
@media only screen and (min-width: 0px) and (max-width: 320px) {
  #multi {
    margin-right: 72px !important;
  }
  /* //snehal-task3620-Add exclude domains(for exclude) */
  #excludeText {
    width: 217px !important;
  }
  #excludeTextcard {
    width: 250px !important;
    margin-left: -15px !important;
  }
}
@media only screen and (min-width: 321px) and (max-width: 375px) {
  #multi {
    margin-right: 122px !important;
  }
  /* //snehal-task3620-Add exclude domains(for exclude) */
  #excludeText {
    width: 269px !important;
  }
  #excludeTextcard {
    width: 303px !important;
    margin-left: -15px !important;
  }
}
@media only screen and (min-width: 376px) and (max-width: 425px) {
  #multi {
    margin-right: 172px !important;
  }
  /* //snehal-task3620-Add exclude domains(for exclude) */
  #excludeText {
    width: 316px !important;
  }
  #excludeTextcard {
    width: 349px !important;
    margin-left: -13px !important;
  }
}
@media only screen and (min-width: 426px) and (max-width: 767px) {
  #excludeText {
    width: 209px !important;
  }
  #excludeTextcard {
    width: 240px !important;
    margin-left: 4px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #toolpop {
    margin-left: -110px !important;
    margin-top: 0px !important;
  }
  #toolpop1 {
    margin-left: -110px !important;
    margin-top: 0px !important;
  }
  /* //snehal-task3620-Add exclude domains(for exclude) */
  #excludeText {
    width: 259px !important;
  }
  #excludeTextcard {
    width: 290px !important;
    margin-left: 9px !important;
  }
}
/* Sandeep-task-3195-added margin left for customizeMapping div */
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .customizeMapping {
    margin-left: 270px !important;
    margin-right: -230px !important;
  }
  /* //snehal-task3620-Add exclude domains(for exclude) */
  #excludeText {
    width: 400px !important;
  }
  #excludeTextcard {
    width: 435px !important;
    margin-left: 16px !important;
  }
}
@media only screen and (min-width: 1441px) {
  #excludeText {
    width: 770px !important;
  }
  #excludeTextcard {
    width: 802px !important;
    margin-left: 47px !important;
  }
}
/* Sandeep-task-3691-STC---agency side---Edit camp---exclude domain issue */
@media only screen and (min-width: 1080px) and (max-width: 1920px) {
  #excludeTextcard {
    width: 435px !important;
    margin-left: 16px !important;
  }
  #excludeText {
    width: 400px !important;
  }
}
/* Chaitanya-5264-added below loader */
#loader_campaign_list {
  position: absolute;
  width: 25%;
  height: 21vh;
  background: white url("/../new_loader.gif") no-repeat center;
  z-index: 999;
  margin-left: 500px;
  margin-top: 110px;
}


/*Aman-5271-added disabled highlights content*/
#no-select {
  user-select: none;
}

/*Aman-5271-added css for the item being dragged */
#dragging {
  background-color: #0558a3;
  transform: scale(1.05);
  transition: background-color 0.3s ease, transform 0.3s ease;
  opacity: 0.5;
  z-index: 10;
}

/*Aman-5271-added Css for the item being dragged over */
#drag-over {
  background-color: #0558a3 !important;
}
/* Aman-5271-added to chnage image for small view */
@media (max-width: 991px) {
  #arrow-image {
    content: url('/down-arrow.png');
  }
}

