/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author:Sunita Landge/kiran mate
@fileName : advertiserDashboard.css 
@Description:added css for proper alignment of advertiserDashboard page 
*/

/* sunita-task-3012-below all css are used as per new UI changes for setting properly as per devices*/
.topnav a.active {
  border-bottom: 3px solid #144c9b;
  opacity: 1;
}

.topnav .tabcountClass {
  font-size: 16px !important;
  color: black;
  opacity: 0.4;
}

/* kiran bug 4255-added css properties for responsiveness */
.dropdownAdv {
  position: relative;
}

.dropdown-contentAdv {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100000;
}

.dropdown-contentAdv a {
  color: black;
  padding: 6px 8px;
  text-decoration: none;
  display: block;
}

.dropdown-contentAdv a:hover {
  background-image: linear-gradient(to right, #28166f, #007cc3);
  color: white
}

.dropdownAdv:hover .dropdown-contentAdv {
  display: block;
}

@media only screen and (max-width: 320px) {
  #leads {
    margin-left: -6px !important;
    margin-bottom: 0px !important;
  }

  #others {
    margin-left: -6px !important;
  }
}

@media only screen and (max-width: 375px) and (min-width: 321px) {
  #leads {
    margin-left: 4px !important;
    margin-bottom: 0px !important;
  }

  #others {
    margin-left: 3px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .ankerTagForVAC {
    margin-top: -5rem;
  }

  .ankerTagForLead {
    margin-top: -5rem;

  }
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .filterImg1 {
    margin-top: 4px !important;
  }

  .progressRport1 {
    margin-left: 0px !important;
  }

  .ankerTagForVAC {
    margin-top: -8rem;

  }

  .ankerTagForLead {
    margin-top: -8rem;
  }

}


@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .filterImg1 {
    margin-top: -17px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .filterImg1 {
    margin-top: -17px !important;
  }

  .progressRport1 {
    margin-left: 42px !important;
  }

  .downloadImage {
    margin-top: -20px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .downloadImage {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 1440px) {
  .filterImg1 {
    margin-top: 4px !important;
  }

  .progressRport1 {
    margin-left: 65px !important;
  }

  .downloadImage {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 768px) {
  .progressRport1 {
    margin-left: 40px !important;
  }
}

.adv_dot {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

/* Mufiz khan
   Bug: 4266
   Title:- Mufiz-Task-4266-R29-Advertiser login-dashboard -view all creatives alignment issue 
    */


@media only screen and (min-width: 320px) and (max-width: 425px) {
  .filterImg1 {
    margin-top: 4px !important;
  }

  .progressRport1 {
    margin-left: 0px !important;

  }


  /* @Mufiz Khan
           task-4266 
        // mufiz-task-4266R29-Advertiser login-dashboard -view all creatives alignment issue*/
  .AdvDashHomePage {
    width: 60rem;
  }

  .imgforNoOfList {
    height: 1rem;
    width: 1rem;
    margin-left: 10rem;
    margin-top: -13px;
  }

  #RecordsPerPage {
    width: 20rem;
    border: gray;
    border-radius: 1rem;
  }

  .form-controlperPage {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  }

  .ankerTagForVAC {
    margin-top: -7rem;
  }

  .ankerTagForLead {
    margin-top: -7rem;
  }


}

@media only screen and (min-width: 425px) and (max-width: 500px) {
  .ankerTagForVAC {
    margin-top: -7rem;

  }

  .ankerTagForLead {
    margin-top: -6rem;
  }
}

@media only screen and (min-width:768px) and (max-width:800px) {
  .imgforNoOfListCreative {
    margin-top: -13px !important;
    margin-left: 90px !important;
    width: 13px !important;
    height: 13px !important;
  }
}

@media only screen and (min-width:425px) and (max-width:768px) {
  .advDashHomePage {
    width: 66rem;
  }

  .imgforNoOfList {
    margin-left: 10rem;
    margin-top: -13px;
  }

  .reviewDetailsData {
    width: 50rem;
  }




}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .advDashHomePage {
    width: 80rem;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .ankerTagForVAC {
      margin-top: -7rem;
      font-size: 11px;
    }

    .ankerTagForLead {
      margin-top: -61px;
    }

    .imgforNoOfList {
      margin-left: 9rem;
      margin-top: -13px;
      width: 1rem;
      height: 12px;
    }

    .reviewDetailsData {
      width: 48rem;
    }

  }
}

@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .filterImg1 {
    margin-top: 2px !important;
    /* margin-top: 4px !important; */
  }


}

@media only screen and (min-width: 1024px) and (max-width: 2000px) {

  .reviewDetailsData {
    width: 50rem;
  }

  .imgforNoOfList {
    width: 1rem;
    height: 12px;
    margin-top: -15x;
    margin-left: 11rem;
  }
}

@media only screen and (min-width: 1024px) {
  .filterImg1 {
    margin-top: -17px !important;
    /* margin-top: 4px !important; */
  }

  .progressRport1 {
    margin-left: 42px !important;

  }

  .downloadImage {
    margin-top: -20px !important;
  }

}

@media only screen and (min-width: 1200px) {
  .downloadImage {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 1440px) {
  .filterImg1 {
    margin-top: 4px !important;
  }

  .progressRport1 {
    margin-left: 65px !important;
  }

  .downloadImage {
    margin-top: 0px !important;
  }
}

@media only screen and (min-width: 768px) {
  .progressRport1 {
    margin-left: 40px !important;
  }
}

.adv_dot {
  white-space: nowrap;
  /* width: 75px; */
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

/* .imgforNoOfList{
    
      margin-left: 12rem;
      margin-top: -18px;
    } */

.ankerTagForVAC {
  margin-left: 1rem;
  float: left;
  /* margin-top: -4rem; */
  /* font-size: 12px; */
}

@media only screen and (max-width: 425px) {
  .advDashHomePage {

    width: 50rem;
  }

  .ankerTagForVAC {
    /* margin-top: -3rem; */
    font-size: 13px;
  }

}

.form-controlperPage {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

@media only screen and (min-width: 1440px) and (max-width:2560px) {
  .imgforNoOfList {
    margin-top: -18px;
    margin-left: 12rem;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1134px) {
  .ankerTagForVAC {
    margin-top: -75px
  }

  .ankerTagForLead {
    margin-top: -6rem;
  }

}

@media only screen and (min-width: 1134px) and (max-width: 2000px) {
  .ankerTagForVAC {
    margin-top: -75px !important;
  }

  .ankerTagForLead {
    margin-top: -6rem;
  }
}

@media only screen and (min-width: 2300px) and (max-width: 2560px) {
  .ankerTagForVAC {
    margin-top: -52px;
  }

  .ankerTagForLead {
    margin-top: -61px;
  }
}

@media only screen and (min-width: 910px) and (max-width: 912px) {

  .ankerTagForVAC {
    margin-top: -2rem;
  }

  .ankerTagForLead {
    margin-top: -2rem;
  }

  .imgforNoOfList {
    margin-left: 12rem;
    margin-top: -2rem;

  }
}

@media only screen and (min-width: 510px) and (max-width: 540px) {

  .imgforNoOfList {
    margin-left: 12rem;
    margin-top: -16px;
  }
}

@media only screen and (max-width: 280px) {
  .imgforNoOfList {
    margin-top: -15px;
    margin-left: 10rem;
  }
}

.ankerTagForVAC {
  float: left;
  margin-right: 19rem;

}

.ankerTagForLead {
  float: left;
}

.imgforNoOfList {
  margin-top: -17px;
  margin-left: 110px;
  width: 15px;
  height: 15px;
}

.imgforNoOfListCreative {
  margin-top: -16px;
  margin-left: 115px;
  width: 13px;
  height: 13px;
}
