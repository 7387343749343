/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Godbole
 *@fileName : dashboard.css
 *Desc: css code changes
 */


/* sandeep-task-2998-changes code to align barChart  properly */
#reactgooglegraph-1 {
  margin-top: 5px !important;
}
#reactgooglegraph-2 {
  margin-top: 5px !important;
}
#reactgooglegraph-4 {
  margin-top: 5px !important;
}
#reactgooglegraph-3 {
  margin-top: 5px !important;
}
#reactgooglegraph-5 {
  margin-top: 5px !important;
}
.camp-radio.ant-radio-group {
  font-size: 16px !important;
}
.lead-radio.ant-radio-group {
  font-size: 16px !important;
}

.ant-table-wrapper {
  border: 1px solid rgb(232, 232, 232);
  border-radius: 4px;
}

.ant-table-thead > tr > th {
  text-align: center !important;
}

.ant-table-small {
  border-radius: 0px;
}

.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 2px;
}
.ant-badge {
  z-index: 0 !important;
}
.ant-badge:not(.ant-badge-not-a-wrapper) {
  margin-right: 10px !important;
}
.head-exampleColor {
 
  background-image: linear-gradient(to right, #144c9b, #007cc3);
  border-radius: 40px;
  color: white;
}

.head-exampleGeneralAdv {
  background-image: linear-gradient(to right, #144c9b, #007cc3);
  border-radius: 40px;
  color: white;
}

.head-exampleGeneralPub {
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  border-radius: 40px;
  color: black !important;
}

.custom-class {
  background-color: #0000b3;
  color: white;
}
.cust-class {
  position: absolute;
  top: 58% !important;
  left: 28%;
  transform: translate(0, -50%) !important;
  -ms-transform: translate(0, -50%) !important;
  -webkit-transform: translate(0, -50%) !important;
  margin: auto 5%;
  width: 90%;
  height: 80%;
}
.cancel-button {
  background-color: rgba(20, 72, 152, 0);
}
.success-button {
  border-radius: 4px;
  background-color: #0000b3;
  color: white;
}
.button1 {
  background-color: #ff4500;
  color: white;
  border: none;
  width: 30px;
  border-radius: 20%;
}
.button2 {
  background-color: #0000b3;
  border: none;
  color: white;
  padding: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  height: 30px;
  width: 30px;
}
.button5 {
  border-radius: 50%;
}


.head-example1 {
  background-image: linear-gradient(to right, #28166f, #007cc3);
  border-radius: 40px;
  color: white;
}

.head-example2 {
  background-image: linear-gradient(to right, #28166f, #007cc3);
  border-radius: 40px;
  color: white;
}

.head-example3 {
  background-image: linear-gradient(to right, #28166f, #007cc3);
  border-radius: 40px;
  color: white;
}

.head-example5 {
  background-image: linear-gradient(to right, #28166f, #007cc3);
  border-radius: 40px;
  color: white;
}

.head-example4 {
  background-image: linear-gradient(to right, #28166f, #007cc3);
  border-radius: 40px;
  color: white;
}

.head-exampleGeneral {
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  border-radius: 40px;
  color: black !important;
}

.head-exampleGeneral1Color {
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  border-radius: 40px;
  color: black !important;
}

.head-exampleGeneral2 {
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  border-radius: 40px;
  color: black !important;
}

.head-exampleGeneral3 {
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  border-radius: 40px;
  color: black !important;
}

.head-exampleGeneral5 {
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  border-radius: 40px;

  color: black !important;
}

.head-exampleGeneral6 {
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  border-radius: 40px;

  color: black !important;
}

.head-exampleGeneral4 {
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  border-radius: 40px;
  color: black !important;
}

.head-exampleGeneral5 {
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  border-radius: 40px;
  color: black !important;
}

.head-exampleGeneral6 {
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  border-radius: 40px;
  color: black !important;
}

.topnav.color {
  overflow: hidden;
  background-color: #f6f6f5 !important;
  height: 35px;
}

/*  sunita -task-2996- added for increasing font size and opacity of tab name  */
.topnav .tabLink {
  font-size: 16px;
  color: black;
  opacity: 0.4;
}

.topnav.color a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 5px 14px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  font-family: "roboto";
  border-bottom: 3px solid transparent;
}

.topnav a:hover {
  /* border-bottom: 3px solid #144c9b; */
  /* font-size: 18px; */
  /* color:#00b3b3  sunita-task-2996- commented code it's giving bluish color to tab name */
  color: #000 !important;
}

.topnav a.active {
  border-bottom: 3px solid #144c9b;
  /* sunita-task-2996-opacity given to tabNames */
  opacity: 1;
}

.badgelink a:active {
  color: white;
}

.badgelink a:hover {
  color: white;
}

.byLead a {
  color: black;

  border-bottom: 3px solid transparent;
}
.byLead a.active {
  border-bottom: 3px solid #144c9b;
}

.ant-card-body {
  padding: 15px !important;
  zoom: 1;
}

.card {
  border-radius: 0.75rem !important;
}

div.dot {
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

div.dot1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

div.dot2 {
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

div.dotUploadSuppression {
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.anticon svg {
  background-color: white !important;
}


.ant-table-column-has-actions
  .ant-table-column-has-filters
  .ant-table-column-has-sorters {
  width: 200px;
}


.ant-scroll-number-only > p {
  height: 20px;
  margin: 0;
  color: white;
}
.ant-badge-count {
  height: 20px;
  line-height: 20px;
  min-width: 20px;
  width: 20px;
  padding: 0 0;
  font-size: 9px;
  color: "white";
  border-radius: 10px;
}

.ant-drawer-wrapper-body {
  overflow: hidden !important;
}

.modal-header {
  display: block !important;
}
.modal-title {
  float: left;
}
.modal-header .close {
  opacity: itial;
  float: right;
  color: #e8c7c7;
  opacity: unset;
  font-size: small;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.2;
}

/* shivani-2711-added to remove black reflecting background  */

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: transparent;
}

.modal-content {
  -webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0);
}

/* shivani-2711-added to remove black reflecting background  */


.ant-table-column-sorter {
  display: none !important ;
}

svg > g > g:last-child {
  pointer-events: none;
}

.panel {
  margin-bottom: 0px;
}

.panel-default > .panel-heading {
  background-image: linear-gradient(to right, #28166f, #007cc3) !important;
  border-color: #ddd;
}
.panel-title {
  color: white !important;
}
.chat-window {
  bottom: 20px;
  right: 0;
  position: fixed;
  float: right;
  z-index: 1000 !important;
}
.chat-window > div > .panel {
  border-radius: 5px 5px 0 0;
}
.icon_minim {
  padding: 2px 10px;
}
.msg_container_base {
  background: #e5e5e5;
  margin: 0;
  padding: 0 10px 10px;
  max-height: 300px;
  overflow-x: hidden;
}
.top-bar {
  background: #666;
  color: white;
  padding: 17px 0px 20px 17px;
  position: relative;
  overflow: hidden;
}
.msg_receive {
  padding-left: 0;
  margin-left: 0;
}
.msg_sent {
  padding-bottom: 20px !important;
  margin-right: 0;
}
.messages {
  background: white;
  padding: 10px 10px 13px 10px;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  max-width: 100%;
}
.messages > p {
  font-size: 13px;
  margin: 0 0 0.2rem 0;
}
.messages > time {
  font-size: 11px;
  color: #ccc;
}
.msg_container {
  padding: 10px;
  overflow: hidden;
  display: flex;
}
.chatimg {
  display: block;
  width: 120%;
  border-radius: 50%;
  height: 50%;
}
.avatar {
  position: relative;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.base_receive > .avatar:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-left-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
}

.base_sent {
  justify-content: flex-end;
  align-items: flex-end;
}
.base_sent > .avatar:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 5px solid white;
  border-right-color: transparent;
  border-top-color: transparent;
  box-shadow: 1px 1px 2px rgba(black, 0.2);
}

.msg_sent > time {
  float: right;
}

.msg_container_base::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.msg_container_base::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.msg_container_base::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.btn-group.dropup {
  position: fixed;
  left: 0px;
  bottom: 0;
}

.panel-footer {
  padding: 10px 15px;
  background-color: #ccace0;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.ant-btn.border {
  border-radius: 1px;
}


@media only screen and (max-width: 320px) {
  #cardmonth {
    min-width: 470px !important;
  }
  #cardclient {
    min-width: 470px !important;
  }
  .biddingClass {
    width: 144% !important;
  }
  .cardSetup {
    width: 144% !important;
  }
  .cardAllocate {
    width: 144% !important;
  }
  .cardRespond {
    width: 144% !important;
  }

  #leads {
    margin-bottom: 25px !important;
    margin-left: 12px !important ;
  }
  #others {
    margin-left: 50px !important ;
  }
  .unreadalerts {
    position: relative !important;
  }
  .demo {
    margin-top: 20px !important;
  }
  .msg_container_base {
    margin-right: -22px !important;
  }
  .panel-default > .panel-heading {
    margin-right: -22px !important;
  }

  .campaignOverview {
    font-size: large;
    margin-top: 8px !important;
  }

  .createCampLabel {
  }
  .dropdown-content1 {
    right: -80px !important;
  }

  .float-right {
    float: left !important;
  }
 
  .topnav {
    height: 104px !important;
  }

  .barchart {
    margin-left: 30px !important;
  }

  .piechart {
  }
}
@media only screen and (max-width: 375px) and (min-width: 321px) {
  #cardmonth {
    min-width: 470px !important;
  }
  #cardclient {
    min-width: 470px !important;
  }
  #leads {
    margin-bottom: 25px !important;
    margin-left: 17px !important ;
  }
  #others {
    margin-left: 67px !important ;
  }
  #invoice {
    margin-left: 11px !important;
  }

  .unreadalerts {
    position: relative !important;
  }
  .clientpublisher {
    margin-top: -30px !important;
  }
  .demo {
    margin-top: 20px !important;
  }
  .msg_container_base {
    margin-right: -22px !important;
  }
  .panel-default > .panel-heading {
    margin-right: -22px !important;
  }
  .campaignOverview {
    font-size: large;
  }

  .createCampLabel {
  }
  .dropdown-content1 {
    right: -80px !important;
  }
  .float-right {
    float: left !important;
  }
  #myText {
    margin-left: 30px !important;
  }
  .topnav {
    height: 87px !important;
  }

  .barchart {
    margin-left: 30px !important;
  }
}
@media only screen and (max-width: 425px) {
  #All1 {
    padding: 5px 25px !important;
    margin-top: 3px !important;
  }

  .barchart {
    margin-left: 22px !important;
    width: 335px !important;
  }

  .piechart {
    margin-left: 32px !important;
    width: 328px !important;
  }

  /* sunita-task-3054- added the css for setting the alignment */
  .pauseTab {
    margin-top: 38px !important;
    margin-left: -96px !important;
  }
}
@media only screen and (max-width: 425px) and (min-width: 320px) {
  #search-bar1 {
    width: 45% !important;
  }

  .unreadalerts {
    position: relative !important;
  }
  .demo {
    margin-top: 20px !important;
  }
  .msg_container_base {
    margin-right: -22px !important;
  }
  .panel-default > .panel-heading {
    margin-right: -22px !important;
  }
  .campaignOverview {
    font-size: large;
  }

  .createCampLabel {
  }
  .dropdown-content1 {
    right: -80px !important;
  }
  .float-right {
    float: left !important;
    /* margin-left: 16px;  sunita- task -3054 - commented the css for aligning filters dropdown according to devices*/
  }

  #myText {
    /* sunita-tas-2996-changed the margin for aligning the pacing names  */
    margin-left: -1px !important;
  }
  .topnav {
    height: 104px !important;
  }
  .endDateModal.modal-dialog {
    margin-left: -47px !important;
  }
  #endDate {
    /* width: 143px !important; */
    width: 143px; /* Musbir -Task-4152*/
  }

  .barchart {
    margin-left: 22px !important;
    width: 335px !important;
  }

  .piechart {
    margin-left: 27px !important;
    width: 330px !important;
  }

  /* sunita-task-2996- added css for campaign progress report */
  .progressResport {
    margin-left: 13px !important;
  }

  /* sunita-task-2996-added css for followupbutton  */
  .sendFollowupBtn {
    width: 165px !important;
  }
  /* Sandeep-task-3233-added css for messageBox  */
  .chat-window {
    bottom: -20px !important;
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and(max-width: 600px) and (min-width: 426px) {
  .chat-window {
    width: 343px !important;
    bottom: -21px !important;
  }

  .panelCampInfo {
    font-size: 14px !important;
  }
  .panelCampContent {
    font-size: 14px !important;
  }
  .panelCampDate {
    font-size: 14px !important;
  }
  .campaignOverview {
    font-size: large;
  }

  .topnav {
    height: 87px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .chat-window {
    width: 343px !important;
    bottom: -21px !important;
  }
  .campaignOverview {
    font-size: large;
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) and (min-width: 601px) {
  #search-bar1 {
    float: right !important;
    margin-top: -30px !important;
    width: 18% !important;
  }
  .cardSetup {
    width: 100% !important;
  }
  .cardAllocate {
    width: 100% !important;
  }
  .cardRespond {
    width: 100% !important;
  }

  .cardUnreadAlerts {
    height: 200% !important;
  }

  .steupImage {
    height: 30px !important;
    width: 30px !important;
  }

  .steupCount {
    font-size: 24px !important;
    font-weight: 400 !important;
  }

  .steupTitle {
    font-size: 12px !important;
  }

  .alllocateImage {
    height: 30px !important;
    width: 30px !important;
  }

  .allocateCount {
    font-size: 24px !important;
    font-weight: 400 !important;
  }

  .allocateTitle {
    font-size: 12px !important;
  }

  .respondImage {
    height: 30px !important;
    width: 30px !important;
  }

  .respondCount {
    font-size: 24px !important;
    font-weight: 400 !important;
  }

  .respondTitle {
    font-size: 12px !important;
  }

  .chat-window {
    width: 417px !important;
    bottom: -21px !important;
  }

  .unreadalerts {
    position: relative !important;
    width: 420px !important;
  }
  .demo {
    margin-top: 20px !important;
  }
  .campaignOverview {
    font-size: large;
  }
  .modal-endDate.modal-content {
    margin-left: -100px !important;
  }

  .createCampLabel {
  }
  #myText {
    /* sunita-tas-2996-changed the margin for aligning the pacing names  */
    margin-left: 0px !important;
  }

  .barchart {
    margin-left: 150px !important;
  }

  .piechart {
    margin-left: 150px !important;
  }

  .noAlert {
    margin-left: 10px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .cardSetup {
    width: 100% !important;
  }

  .cardAllocate {
    width: 100% !important;
  }
  .cardRespond {
    width: 100% !important;
  }

  .chat-window {
    width: 315px !important;
    bottom: -21px !important;
  }
  .campaignOverview {
    font-size: large;
  }

  .createCampLabel {
  }
  .modal-endDate.modal-content {
    margin-left: -100px !important;
  }

  .piechart {
    width: 289px !important;
    margin-left: 0px !important;
    margin-top: 15px !important;
  }

  .barchart {
    width: 310px !important;
    margin-left: 33px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .clientpublisher {
    margin-top: 1px !important;
  }

  /*sunita-task-3818-added below css as per requirement*/
  .steupImage {
    height: 60px !important;
    width: 60px !important;
  }

  .steupCount {
    font-size: 36px !important;
    font-weight: 500 !important;
  }

  .steupTitle {
    font-size: 16px !important;
  }

  .alllocateImage {
    height: 60px !important;
    width: 60px !important;
    margin-left: -1px; /*sunita-task-3818-added css as per requirement*/
  }

  .allocateCount {
    font-size: 36px !important;
    font-weight: 500 !important;
  }

  .allocateTitle {
    font-size: 16px !important;
  }

  .respondImage {
    height: 60px !important;
    width: 60px !important;
  }

  .respondCount {
    font-size: 36px !important;
    font-weight: 500 !important;
  }

  .respondTitle {
    font-size: 16px !important;
  }

  .chat-window {
    width: 366px !important;
    bottom: -21px !important;
  }

  .campaignOverview {
    font-size: large;
  }

  .createCampLabel {
  }

  .piechart {
    width: 350px !important;
    margin-left: 29px !important;
    margin-top: 15px !important;
  }

  .barchart {
    width: 369px !important;
    /* display: block; */
    margin-left: 91px !important;
    margin-top: 10px !important;
  }

  /* sunita-task-2996-added css for followupbutton */
  .sendFollowupBtn {
    width: 145px !important;
  }

  /*sunita-task-3054-added css for download image for setting the extra large device*/
  .progressResport {
    padding-right: 0px !important;
  }
}

hr {
  margin-top: 12px !important;
  margin-bottom: 11px !important;
}

@media only screen and (min-width: 1024px) {
  #invoice {
    margin-left: -6px !important;
  }
  .unreadalerts {
    position: absolute !important;
    width: 300px !important;
  }

  /* sunita-task-2996-added css for followupbutton  */
  .sendFollowupBtn {
    width: 136px !important;
  }

  /* sandeep-task-2998-changes code to align image  properly */
  .steupImage {
    display: block;
    /* margin-left: -3px; {/*sunita-task-3818-commented this css as per requirement */
    margin-left: -1px; /*sunita-task-3818-added below css as per requirement*/
    margin-right: 0px;
  }

  .respondImage {
    display: block;
    margin-left: -3px;
    margin-right: 0px;
  }
  .alertImg {
    display: block;
    margin-left: 110px;
    margin-right: 0px;
  }

  .clientpublisher {
    margin-top: 0px !important;
  }
  .demo {
    margin-top: -92px !important;
  }
  .campaignOverview {
    font-size: large;
  }

  
}

.clients {
  background-color: "#8080806b" !important;
}

.publishers {
  background-color: "#e088506b" !important;
}




/* sandeep-task-2998 added code to handle vertical line */
.vl {
  border-left: 0.2px solid lightgrey !important;
  height: 700px !important;
  position: sticky;
  margin-left: 330px !important;
}

/* sunita - task- 2996- added for aligning the create campaign button */
@media only screen and (min-width: 577px) {
  .createCampButton {
    float: right;
    width: 152px;
  }
}

/* sunita-task-2996-added css for sendFollowupBtn
 */
.sendFollowupBtn {
  color: #193d8f;
  border-color: #193d8f;
  background-color: transparent;
  background-image: none;
  padding: 3px 10px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 3px;
  font-weight: inherit;
}

/* sunita-task-2996- added css for sendFollowupBtn */
@media only screen and (min-width: 768px) {
  .sendFollowupBtn {
    width: 133px !important;
  }
}

/* sunita-task-2996-added css for sendFollowupBtn changed the color when hover on the button  */
.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #193d8f;
  border-color: #193d8f;
}

/* sunita-task-2996-added color to pacing value as per tanuja*/
#myText {
  color: #414141;
}

/* sunita-task-3054-added css for setting the tab alignment */
.divTab {
  overflow: visible !important;
}

/*sunita-task-3054-added for aligning tab*/
.topnav a {
  padding: 5px 14px !important;
}

.createCampLabel /*sunita-task-3096- added css bcz getting alignement issue on pat*/ {
  max-width: none !important;
}

/* kiran-bug 4255-added properties for responsiveness */
.dropdownAgency {
  position: relative;
  }
  .dropdown-contentAgency {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 100000;
  }
  .dropdown-contentAgency a {
  color: black;
  padding: 6px 8px;
  text-decoration: none;
  display: block;
  }

 .dropdown-contentAgency a:hover { background-image: linear-gradient(to right,#28166f,#007cc3);color:white}
.dropdownAgency:hover .dropdown-contentAgency {display: block;}

/*Musbir Shaikh Task-4388 */
  @media only screen and  (max-width: 1024px) and (min-width: 320px) {
  
    .clientName_l{
      margin-right: -10px;
    }
  }

  