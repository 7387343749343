/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : domainName.css
 *Desc: 
 */
 /* //shivani -3245 - added loader . */
#abm_loader{
    position: absolute;
    width: 25%;
    height: 25vh;
    background: white url('/../new_loader.gif') no-repeat center;
    z-index: 999;
    margin-left: 500px;
    margin-top: 90px;
} 