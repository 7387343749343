/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :kiran mate
 *@fileName : adminDashboard.css
 *Desc: css code changes
 */
.card1 {
  background-color: #f29441;
  width: 300px;
  border-radius: 10px;
  justify-content: space-around;
}

.card2 {
  background-color: #3fbfb2;
  width: 300px;
  border-radius: 10px;
  justify-content: space-around;
}
.card3 {
  background-color: #78961d;
  width: 300px;
  border-radius: 10px;
  justify-content: space-around;
}
.card-title {
  padding-top: 15px;
}
.review {
  display: block;
}

.btnlink1 {
  background-color: #f29441;
}
.btnlink2 {
  background-color: #78961d;
}
.link {
  min-width: max-content;
}
.div1 {
  margin-right: 80px !important;
}

.btn1.ant-btn {
  background-color: #f8f9fa !important;
  background-image: none !important;
  /*color: #F29441;*/
  font-weight: bold;
  font-size: 15px;
  border-radius: 15px;
  border: 2px solid white;
  box-shadow: 2px 2px #f8f9fa;
}
.btn2.ant-btn {
  background-color: #f8f9fa !important;
  /*color:#3FBFB2;*/
  font-weight: bold;
  font-size: 15px;
  border-radius: 15px;
  border: 2px solid white;
  box-shadow: 2px 2px #f8f9fa;
}
.btn3.ant-btn {
  background-color: #f8f9fa !important;
  /*color:#78961D;*/
  font-weight: bold;
  font-size: 15px;
  border-radius: 15px;
  border: 2px solid white;
  box-shadow: 2px 2px #f8f9fa;
}
.btn3.ant-btn:hover,
.btn3.ant-btn:focus {
  color: #78961d;
  font-weight: bold;
  font-size: 15px;
  border-radius: 15px;
  border: 2px solid white;
}
.btn2.ant-btn:hover,
.btn2.ant-btn:focus {
  color: #3fbfb2;
  font-weight: bold;
  font-size: 15px;
  border-radius: 15px;
  border: 2px solid white;
}
.btn1.ant-btn:hover,
.btn1.ant-btn:focus {
  color: #f29441;
  font-weight: bold;
  font-size: 15px;
  border-radius: 15px;
  border: 2px solid white;
}
/* .ant-btn > i, .ant-btn > span {
 padding-top:20px; 
} */
.ant-badge-count {
  height: 20px !important;
  width: 40px !important;
  border-radius: 120px;
  margin-right: 10px;
  padding: 0px 0px;
}
.adv {
  padding-top: 20px;
  color: #f29441 !important ;
}
.agency {
  padding-top: 20px;
  color: #3fbfb2 !important;
}
.publisher {
  padding-top: 20px;
  color: #78961d !important;
}
