/* Copyright(c) 2024 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
 * @file unauthorized.css
 * @author   Aman Shaikh - 5084
 * @version 1.0
 * @section DESCRIPTION Added to style unauthorized page
 */


@import url('https://fonts.googleapis.com/css?family=Lato');

 /* @animationTime: 2s; */
.unauthorized {
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(to bottom right, #EEE, #AAA);
}

.unauthorized-action-btn{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.unauthorized-action-btn .unauthorized-back-btn, .unauthorized-login-btn{
 /* background-image: linear-gradient(#1f143a, #101f65, #093394); */
 background-image: linear-gradient(to right,#28166f,#007cc3); 
 outline: none;
 color: white;
 font-size: 15px;
 border-radius: 40px;
 padding: 10px 40px;
 margin-right: 10px;
}
.unauthorized-lock-img{
  height: 100px;
  width: 100px;
}

.unauthorized-404-img{
  height: 300px;
  width: 300px;
}
