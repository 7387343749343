/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : listAllCampaign.css
 *Desc: CSS code changes
 */       
 /* to increase height of table  */
 /* to increase height of table  */
 .table-wrapper-scroll-y {
    display: block;
    max-height:450px !important;
    overflow-y: auto;
  }
  