/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author:Mufiz Khan
@Creation Date:31-03-2022
file Name :- campaignSpecificationDashboard.css
created file under 4243 Bug
Title -R-28.8-Prod Issue-Agency-Campaign specification not opening -Shows blank page
*/

@media only screen and (max-width: 320px) {
  #editBtnInCSD {
    margin-top: -47px;
    font-size: 12px;
    width: 10rem;
    margin-left: 16rem;
  }
  #campaignSpecDownloadBtn {
    width: 10rem;
    font-size: 12px;
  }
  #idOfCampSpecDash {
    padding-left: 35px;
  }

  .CampSpecDashBord {
    font-size: 12px;
  }
  .tblofcuttumizecolumn{
    font-size: 1rem;
  }
 
}
@media only screen and (min-width: 320px) and (max-width: 375px) {
  #editBtnInCSD {
    margin-top: -86px;
    margin-left: 20rem;
  }
  #campaignSpecDownloadBtn {
    width: 10rem;
    margin-left: 3rem;
  }
  #idOfCampSpecDash {
    padding-left: 60px;
  }
  div.scrollmenu {

    overflow: auto;
    white-space: nowrap;
  }
  
  div.scrollmenu a {
    display: inline-block;

    text-align: center;
    padding: 14px;
    text-decoration: none;
  }
  .CampSpecDashDetailforrespon{
    font-size: 1rem;
  }
  
  
  
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  #editBtnInCSD {
    margin-top: -53px;
    margin-left: 22rem;
  }
  #campaignSpecDownloadBtn {
    width: 10rem;
    margin-left: 5rem;
  }
  #idOfCampSpecDash {
    padding-left: 9rem;
  }
  div.scrollmenu {
    overflow: auto;
    white-space: nowrap;
  }
  
  div.scrollmenu a {
    display: inline-block;
    text-align: center;
    padding: 14px;
    text-decoration: none;
  }

  
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
  #editBtnInCSD {
    margin-top: -88px;
    margin-left: 22rem;
  }
  #campaignSpecDownloadBtn {
    width: 10rem;
    margin-left: 5rem;
  }
  #idOfCampSpecDash {
    margin-left: 8rem;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  #editBtnInCSD {
    margin-top: 2px;
    margin-left: 4rem;
  }
  #campaignSpecDownloadBtn {
    width: 10rem;
    margin-left: 17rem;
  }
  #idOfCampSpecDash {
    width: 35rem;
    margin-left: 25rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #editBtnInCSD {
    margin-left: -7rem;
  }
  #campaignSpecDownloadBtn {
    margin-left: -12rem;
  }
  .scrollmenu{
    width: 100rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  #editBtnInCSD {
    margin-left: 8rem;
  }
  #campaignSpecDownloadBtn {
    margin-left: 14rem;
  }
  #idOfCampSpecDash {
    margin-left: 27rem;
    width: 33rem;
    margin-top: 4px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  #editBtnInCSD {
    margin-left: -6rem;
  }
  #campaignSpecDownloadBtn {
    margin-left: -12rem;
  }
  .scrollmenu{
    width: 100%;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 2000px) {
  #idOfCampSpecDash {
    margin-right: -10rem;
    margin-left: 7rem;
  }
  .scrollmenu{
    width: 100%;
  }
}
@media only screen and (min-width: 2000px) and (max-width: 2560px) {
  #idOfCampSpecDash {
    margin-left: 26rem;
    margin-right: -41rem;
  }

    .scrollmenu{
      width: 100%;
    }
  }
