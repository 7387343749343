/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : agencyCompanyInformation.css
 *Desc: css code changes
 */
@media only screen and (min-width: 768px) {
	.captcha1 {
		padding-left: 0px !important;
	}
}
/* media query for captcha at tablet  size*/
@media only screen and (min-width: 992px) {
	.captcha1 {
		padding-left: 0px !important;
	}
}
/* media query for captcha at tablet  size*/

@media only screen and (min-width: 1200px) {
	.captcha1 {
		padding-left: 0px !important;
	}
}
/* media query for captcha at laptop large size and above*/
.ant-select-dropdown-hidden {
	z-index: auto;
	position: absolute;
}
.ant-select-dropdown {
	z-index: auto !important;
	position: absolute !important;
}
.ant-select-dropdown--single {
	z-index: auto !important;
	position: absolute !important;
}
.ant-select-dropdown-placement-bottomLeft {
	z-index: auto !important;
	position: absolute !important;
}

.ant-select-dropdown-hidden {
	z-index: auto !important;
	position: absolute !important;
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
	.logo-1 {
		margin-left: 165px !important;
	}
	/* Sandeep-task-3264-added padding top property for .subContracting */
	.subContracting {
		padding-Top:102px !important;
	}
}
@media only screen and (min-width: 320px) and (max-width: 375px) {
	.logo-1 {
		margin-left: 140px !important;
	}
	/* Sandeep-task-3264-added padding top property for .subContracting */
	.subContracting {
		padding-Top:102px !important;
	}
}
@media only screen and (max-width: 320px) {
	.logo-1 {
		margin-left: 135px !important;
	}
	/* Sandeep-task-3264-added padding top property for .subContracting */
	.subContracting {
		padding-Top:102px !important;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.logo-1 {
		/* padding-right: -150px!important; */
		width: 150% !important;
	}
}

.disabled {
	background-image: linear-gradient(to left, #0777cb, #103784) !important;
	color: white !important;
	opacity: 0.54;
}