/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Godbole
 *@fileName : addAdvertiser.css
 *Desc: css code changes
 */

@media only screen and (min-width: 320px) and (max-width: 1440px) {
  .first-img {
    margin-left: 15px !important;
  }
}
