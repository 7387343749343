/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author : Chaitanya Shinde
 *@fileName : addCompliance.css
 *Desc:Task-1330-Add Compliance Question Screen design
 */

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .AddComplianceTitle {
    margin-left: 16% !important;
  }
  .CompName {
    width: 55%;/*vrushabh 4142 width increse*/
  }
  .CompRole {
    width: 60%;
    margin-top: 2%;
  }
  .ComplianceDetailSubmit {
    margin-top: 12%;
    margin-left: 3%;
  }
  .CompDetailTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
  }
  .CompDetailTableBody {
    height: 19rem;
    overflow: auto;
    display: block;
  }
  .CompQuesContent {
    height: 47rem !important;
    margin-top: 28% !important;
    width: 100% !important;
  }
  .CompQuesTitle {
    padding-right: 4% !important;
  }
  .CompQuesBody {
    overflow: auto;
  }
  .AddNewQues {
    margin-top: 0%;
    width: 45%;
  }
  .AddQuesText {
    width: 128%;
  }
  .ComQueAns {
    margin-left: 12%;
  }
  .SuppDocRequired {
    margin-left: 0%;
    width: 69%;
  }
  .MinusSign {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 10%;
    margin-top: 8%;
  }
  .CompQueTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
    overflow: auto;
  }
  .CompQueTableBody {
    height: 20rem;
    overflow: auto;
  }
}
@media only screen and (min-width: 375px) and (max-width: 424px) {
  .AddComplianceTitle {
    margin-left: 16% !important;
  }
  .CompName {
    width: 45%;
  }
  .CompRole {
    width: 56%;
    margin-top: 2%;
  }
  .ComplianceDetailSubmit {
    margin-top: 10%;
  }
  .CompDetailTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
  }
  .CompDetailTableBody {
    height: 19rem;
    overflow: auto;
    display: block;
  }
  .CompQuesContent {
    height: 47rem !important;
    margin-top: 24% !important;
    width: 100% !important;
  }
  .CompQuesTitle {
    padding-right: 20% !important;
  }
  .CompQuesBody {
    overflow: auto;
  }
  .AddNewQues {
    margin-top: 0%;
    width: 39%;
  }
  .AddQuesText {
    width: 169%;/*vrushabh*4438*/
  }
  .ComQueAns {
    margin-left: 22%;
  }
  .SuppDocRequired {
    margin-left: 0%;
    width: 59%;
  }
  .MinusSign {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 20%;
    margin-top: 8%;
  }
  .CompQueTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
    overflow: auto;
  }
  .CompQueTableBody {
    height: 20rem;
    overflow: auto;
  }
}
@media only screen and (min-width: 425px) and (max-width: 767px) {
  .AddComplianceTitle {
    margin-left: 16% !important;
  }
  .AddQuesText {
    width: 182%;/*vrushabh4438*/
  }
  .CompName {
    width: 45%;
    /*vrushabh patil4142*/
  }
  .CompRole {
    width: 56%;/*vrushabh width increse 4142*/
  }
  .ComplianceDetailSubmit {
    width: 10%;
    margin-top: 9%;/*vrushabh increse 4142*/
  }
  .CompDetailTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
  }
  .CompDetailTableBody {
    height: 22rem;
    overflow: auto;
    display: block;
  }
  .CompQuesContent {
    height: 48rem !important;
    margin-top: 20% !important;
    width: 100% !important;
  }
  .CompQuesTitle {
    padding-right: 30% !important;
  }
  .CompQuesBody {
    overflow: auto;
  }
  .AddNewQues {
    margin-top: 0%;
    width: 34%;
  }
  .ComQueAns {
    margin-left: 23%;{/*vrushabh*/}
  }
  .SuppDocRequired {
    margin-left: 0%;
    width: 52%;
  }
  .MinusSign {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 20%;
    margin-top: 8%;
  }
  .CompQueTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
    overflow: auto;
  }
  .CompQueTableBody {
    height: 20rem;
    overflow: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 768px) {
  .AddComplianceTitle {
    margin-left: 16% !important;
  }
  .ComplianceDetailSubmit {
    margin-top: 0%;
  }
  .CompDetailTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
  }
  .CompDetailTableBody {
    height: 23rem;
    overflow: auto;
    display: block;
  }
  .CompQuesContent {
    height: 49rem !important;
    margin-top: 25% !important;
    width: 100% !important;
  }
  .CompQuesTitle {
    padding-right: 30% !important;
  }
  .CompQuesBody {
    overflow: auto;
  }
  .AddNewQues {
    margin-top: 0%;
  }
  .ComQueAns {
    margin-left: 33%;
    margin-top: 0% !important;
  }
  .SuppDocRequired {
    margin-left: 0%;
  }
  .MinusSign {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 40%;
    margin-top: 12%;
  }
  .CompQueTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
    overflow: auto;
  }
  .CompQueTableBody {
    height: 18rem;
    overflow: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .CompName {
    width: 46%;
    margin-top: -28px;
}
  .AddComplianceTitle {
    margin-left: 16% !important;
  }
  .CompDetailTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
  }
  .CompDetailTableBody {
    height: 26rem;
    overflow: auto;
    display: block;
  }
  .CompQuesContent {
    height: 48rem !important;
    margin-top: 16% !important;
    width: 116% !important;
    margin-left: -3% !important;
  }
  .CompQuesTitle {
    padding-right: 52% !important;
  }
  .CompQuesBody {
    overflow: auto;
  }
  .ComQueAns {
    margin-left: 12%;
  }
  .SuppDocRequired {
    margin-left: 4%;
  }
  .MinusSign {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 19%;
    margin-top: -7%;
  }
  .CompQueTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
    overflow: auto;
  }
  .CompQueTableBody {
    height: 21rem;
    overflow: auto;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .asterisk {
    color: red;
    margin-left: -5px /*vrushabh 4142*add margin*/
  }
  .AddComplianceTitle {
    margin-left: 25% !important;
  }
  .CompName {
    width: 50%;
    margin-top: 0px;
    
    margin-top: -27px;
    /*vrushabh patil4142*/
  }
  
  .CompDetailTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
    overflow: auto;
  }
  .CompDetailTableBody {
    height: 26rem;
    overflow: auto;
    display: block;
  }
  .CompQuesContent {
    height: 48rem !important;
    margin-top: 10% !important;
    width: 90% !important;
    margin-left: 6% !important;
  }
  .CompQuesTitle {
    padding-right: 62% !important;
  }
  .CompQuesBody {
    overflow: auto;
  }
  .ComQueAns {
    margin-left: 6%;
  }
  .MinusSign {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 19%;
    margin-top: -2%;
  }
  .CompQueTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
    overflow: auto;
  }
  .CompQueTableBody {
    height: 21rem;
    overflow: auto;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .CompName {
    width: 50%;
    margin-top: -30px;}
  .CompDetailTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
    overflow: auto;
  }
  .CompDetailTableBody {
    height: 30rem;
    overflow: auto;
    display: block;
  }
  .CompQuesContent {
    height: 48rem !important;
    margin-top: 10% !important;
    width: 100% !important;
    margin-left: 0% !important;
  }
  .CompQuesTitle {
    padding-right: 66% !important;
  }
  .CompQuesBody {
    overflow: auto;
  }
  .ComQueAns {
    margin-left: 3%;
  }
  .MinusSign {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 19%;
    margin-top: -2%;
  }
  .CompQueTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
    overflow: auto;
  }
  .CompQueTableBody {
    display: block;
    height: 24rem;
    overflow: auto;
  }
}
@media only screen and (min-width: 1441px) {
  .CompDetailTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
    overflow: auto;
  }
  .CompDetailTableBody {
    height: 38rem;
    overflow: overlay;
    display: block;
  }
  .CompQuesContent {
    height: 62rem;
    margin-top: 11% !important;
  }
  .CompQuesTitle {
    padding-right: 66% !important;
  }
  .ComQueAns {
    margin-left: 3%;
  }
  .MinusSign {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 20%;
    margin-top: -2%;
  }
  .CompQueTableHead {
    width: 100%;
    box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
    table-layout: fixed;
    display: table;
    overflow: auto;
  }
  .CompQueTableBody {
    display: block;
    height: 27rem;
    overflow: overlay;
  }
}
