/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :kiran mate
 *@fileName : agencyInvoicesList.css
 *Desc: css code changes
 */
/* .ant-table-thead {
  background-color:blue;
  color:white;
  font-size: 200px;
 } */
.ant-table-wrapper {
  border: 1px solid rgb(232, 232, 232);
  border-radius: 4px;
}
.ant-table-thead > tr > th {
  text-align: center !important;
}
.ant-table-small {
  border-radius: 0px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 2px;
}
.ant-badge {
  z-index: 0 !important;
}
.ant-badge:not(.ant-badge-not-a-wrapper) {
  margin-right: 20px;
}
.head-example {
  /* width: 145px;
  height: 30px;
  padding-top: 8px;
  border-radius: 20px; */
  background-image: linear-gradient(to right, #144c9b, #007cc3);
  /* display: inline-block;
  vertical-align: middle;
  text-align: center; */
  color: white;
  /* font-size:12px; */
}

.custom-class {
  background-color: #0000b3;
  color: white;
}
.cust-class {
  position: absolute;
  top: 58% !important;
  left: 28%;
  transform: translate(0, -50%) !important;
  -ms-transform: translate(0, -50%) !important;
  -webkit-transform: translate(0, -50%) !important;
  margin: auto 5%;
  width: 90%;
  height: 80%;
}
.cancel-button {
  background-color: rgba(20, 72, 152, 0);
}
.success-button {
  border-radius: 4px;
  background-color: #0000b3;
  color: white;
}
.button1 {
  background-color: #ff4500;
  color: white;
  border: none;
  width: 30px;
  border-radius: 20%;
}
.button2 {
  background-color: #0000b3;
  border: none;
  color: white;
  padding: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  height: 30px;
  width: 30px;
}
.button5 {
  border-radius: 50%;
}

/* .modal-footer {
      border-top: 3px solid #0000b3;
    } */

.head-example1 {
  /* width: 125px;
  height: 30px;
  border-radius: 20px;
  padding-top: 8px; */
  background-image: linear-gradient(to right, #28166f, #007cc3);
  /* display: inline-block;
  vertical-align: middle;
  text-align: center; */
  color: white;
  /* font-size:12px; */
}
.head-example2 {
  /* width: 100px;
  height: 30px;
  padding-top: 8px;
  border-radius: 20px; */
  background-image: linear-gradient(to right, #28166f, #007cc3);
  /* display: inline-block;
  vertical-align: middle;
  text-align: center; */
  color: white;
  /* font-size:12px; */
}
.head-example3 {
  /* width: 100px;
  height: 30px;
  padding-top: 8px;
  border-radius: 20px; */
  background-image: linear-gradient(to right, #28166f, #007cc3);
  /* display: inline-block;
  vertical-align: middle;
  text-align: center; */
  color: white;
  /* font-size:12px; */
}

.head-example5 {
  background-image: linear-gradient(to right, #28166f, #007cc3);
  color: white;
}

.head-example4 {
  /* width: 100px;
  height: 30px;
  padding-top: 8px;
  border-radius: 20px; */
  background-image: linear-gradient(to right, #28166f, #007cc3);
  /* display: inline-block;
  vertical-align: middle;
  text-align: center; */
  color: white;
  /* font-size:12px; */
}

.head-exampleGeneral {
  /* width: 145px;
  height: 30px;
  padding-top: 8px;
  border-radius: 20px; */
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  /* display: inline-block;
  vertical-align: middle;
  text-align: center; */
  color: black !important;
  /* font-size:12px; */
}

.head-exampleGeneral1 {
  /* width: 125px;
  height: 30px;
  padding-top: 8px;
  border-radius: 20px; */
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  /*display: inline-block;
   vertical-align: middle;
  text-align: center;
  font-size:12px; */
  color: black !important;
}

.head-exampleGeneral2 {
  /* width: 100px;
  height: 30px;
  border-radius: 20px;
  padding-top: 8px; */
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  /*display: inline-block;
  vertical-align: middle;
  text-align: center; */
  color: black !important;
  /* font-size:12px; */
}

.head-exampleGeneral3 {
  /* width: 100px;
  height: 30px;
  border-radius: 20px;
  padding-top: 8px; */
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  /* display: inline-block;
  vertical-align: middle;
  text-align: center; */
  color: black !important;
  /* font-size:12px; */
}

.head-exampleGeneral5 {
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;

  color: black !important;
}

.head-exampleGeneral6 {
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;

  color: black !important;
}

.head-exampleGeneral4 {
  /* width: 100px;
  height: 30px;
  border-radius: 20px;
  padding-top: 8px; */
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  /* display: inline-block;
  vertical-align: middle;
  text-align: center; */
  color: black !important;
  /* font-size:12px; */
}

.head-exampleGeneral5 {
  /* width: 100px;
  height: 30px;
  border-radius: 20px;
  padding-top: 8px; */
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  /* display: inline-block;
  vertical-align: middle;
  text-align: center; */
  color: black !important;
  /* font-size:12px; */
}

.head-exampleGeneral6 {
  /* width: 100px;
  height: 30px;
  border-radius: 20px;
  padding-top: 8px; */
  background-image: linear-gradient(to right, #ffffff, #ffffff) !important;
  /* display: inline-block;
  vertical-align: middle;
  text-align: center; */
  color: black !important;
  /* font-size:12px; */
}
.topnav {
  overflow: hidden;
  background-color: #f1f1f1;
  height: 35px;
}
.topnav a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 5px 16px;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  font-family: "roboto";
  border-bottom: 3px solid transparent;
}

.topnav a:hover {
  /* border-bottom: 3px solid #144c9b; */
  /* font-size: 18px; */
  color: #00b3b3;
}

.topnav a.active {
  border-bottom: 3px solid #144c9b;
}

.badgelink a:active {
  color: white;
}

.badgelink a:hover {
  color: white;
}

div.dot {
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

div.dot1 {
  white-space: nowrap;
  /* width:100px; */
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

div.dot2 {
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

div.dotUploadSuppression {
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.anticon svg {
  background-color: white !important;
  /* color:#b3afaf!important; */
}

.ant-table-column-has-actions
  .ant-table-column-has-filters
  .ant-table-column-has-sorters {
  width: 200px;
}

/* ::placeholder {
  color: white;
  opacity: 1; 
}

:-ms-input-placeholder { 
 color: white;
}

::-ms-input-placeholder { 
 color: white;
} */

.ant-scroll-number-only > p {
  height: 20px;
  margin: 0;
  color: white;
}
.ant-badge-count {
  height: 20px;
  line-height: 20px;
  min-width: 20px;
  width: 20px;
  padding: 0 0;
  font-size: 9px;
  color: "white";
  border-radius: 10px;
}

/* .tooltip-top > 
.ant-tooltip-inner {
  background-color:red;
  color:red;
  border: 1px solid #062e56;
}

.tooltip-top >  
.ant-tooltip-arrow {
  border-top: 5px solid #062e56;
}

#tooltip-top > .ant-tooltip-inner {
  background-color:red;
  color:red;
  border: 1px solid #062e56;
}

#tooltip-top >  .ant-tooltip-arrow {
  border-top: 5px solid #062e56;
} */

.ant-drawer-wrapper-body {
  overflow: hidden !important;
}

.modal-header {
  display: block !important;
}
.modal-title {
  float: left;
}
.modal-header .close {
  opacity: itial;
  float: right;
  color: #e8c7c7;
  opacity: unset;
  font-size: small;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.2;
}

/* .modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
} */
.ant-table-column-sorter {
  display: none !important ;
}
@media only screen and (max-width: 768px) {
  .button-1 {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  .button-2 {
    margin-left: 8px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 425px) {
  .dot-1 {
    margin-left: 15px;
  }
  #invoice-His {
    float: right !important;
  }
}

/* @media only screen and (max-width: 768px){
  .one{
    margin-left: 15px !important;
  }
} */
@media only screen and (max-width: 1024px) {
  .dot-2 {
    width: 250px !important;
  }
}
@media only screen and (max-width: 320px) {
  .dot-3 {
    width: 100px !important;
  }
}
@media only screen and (max-width: 768px) {
  .dot-3 {
    width: 100px !important;
  }
}
@media only screen and (max-width: 1024px) {
  .dot-3 {
    width: 100px !important;
  }
}
