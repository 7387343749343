/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : updateInvoice.css
 *Desc: 
 */
 @media only screen and (max-width: 320px) {
    /* For mobile phones: */
    .table-table-bordered {
     width:100%;
    }
    
  }
@media only screen and (max-width: 320px) {
    /* For mobile phones: */
    .btn-add-button {
      margin-right:100px;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 768px) {
    .btn-add-button {
      /* margin-left:500px; */
      float: right;
    }
  }
 
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .search_bar{
   width: 200px;
   margin-right:100px;
     }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1440px) {
    .search_bar{
   
  margin-left: 20px;
     }
  }
 