/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : rfpProgress.css
 *Desc: CSS code changes
 */
@media screen and (max-width: 375px) {
	.page-heading-rfp-progress {
		font-size: 18px !important;
	}
}
@media screen and (max-width: 767.98px) {
	.search-box-responsive-rfp-progress {
		margin-top: 5px;
		padding-left: 0px;
	}
}
.page-heading-rfp-progress {
	font-family: Roboto;
	font-size: 26px;
	font-weight: 500;
	color: black;
}
.non-active-rfp-progress-tab {
	display: flex;
	flex-wrap: nowrap;
	font-size: 16px;
	color: black;
	opacity: 0.4;
	font-weight: 500;
	padding: 5px;
	margin-right: 10px;
	cursor: pointer;
}
.active-rfp-progress-tab {
	opacity: 1;
	border-bottom: 3px solid #144c9b;
}
.fix-padding-row {
	padding-left: 0px !important;
	padding-right: 0px !important;
}
.action_context_menu_icon {
	transform: rotate(90deg);
	display: inherit;
	font-weight: bold;
	position: relative;
	left: 23px;
	cursor: pointer;
}
@keyframes fadeIn {
	0% {
		transform: translateY(-25%);
	}
	50% {
		transform: translateY(4%);
	}
	65% {
		transform: translateY(-2%);
	}
	80% {
		transform: translateY(2%);
	}
	95% {
		transform: translateY(-1%);
	}
	100% {
		transform: translateY(0%);
	}
}
.popup-rfp-progress {
	animation-name: fadeIn;
	animation-duration: 0.4s;
	background-clip: padding-box;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	right: 0px;
	list-style-type: none;
	margin: 0;
	outline: none;
	padding: 0;
	position: absolute;
	text-align: left;
	top: 0px;
	overflow: hidden;
	-webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.popup-rfp-progress div {
	clear: both;
	color: rgba(0, 0, 0, 0.65);
	cursor: pointer;
	font-size: 14px;
	font-weight: normal;
	line-height: 22px;
	margin: 0;
	padding: 5px 12px;
	transition: all 0.3s;
	white-space: nowrap;
	-webkit-transition: all 0.3s;
	display: flex;
	align-items: center;
}
.popup-rfp-progress div:hover {
	background-color: #e6f7ff;
}
.popup-rfp-progress div > img {
	margin-right: 8px;
}
#loader_campaign_list_rfp_progress {
	position: absolute;
	width: 25%;
	height: 21vh;
	background: white url("/../new_loader.gif") no-repeat center;
	z-index: 999;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	margin-top: 55px !important;
}
@media only screen and (max-width: 320px) {
	.responsive-model{
		width: 93% !important;
	}
	.responsive-model-1{
		max-width: 50%;
		margin-left: 20px !important;
		margin: 10px;
	}
	#loader_campaign_list_rfp_progress {
		margin-top: 55px !important;
		width: 70% !important;
	}
}
@media only screen and (min-width: 321px) and (max-width: 375px) {
	.responsive-model{
		width: 93% !important;
	}
	.responsive-model-1{
		max-width: 50%;
		margin-left: 20px !important;
		margin: 10px;
	}
	#loader_campaign_list_rfp_progress {
		margin-top: 55px !important;
		width: 70% !important;
	}
}
@media only screen and (min-width: 376px) and (max-width: 425px) {
	.responsive-model{
		width: 93% !important;
	}
	.responsive-model-1{
		max-width: 50%;
		margin-left: 20px !important;
		margin: 10px;
	}
	#loader_campaign_list_rfp_progress {
		margin-top: 55px !important;
		width: 70% !important;
	}
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
	.responsive-model{
		width: 93% !important;
	}
	.responsive-model-1{
		max-width: 50%;
		margin-left: 20px !important;
		margin: 10px;
	}
	#loader_campaign_list_rfp_progress {
		width: 40% !important;
		margin-top: 55px;
	}
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
	.reportType_myreport {
		float: right !important;
	}
}
/* kiran- bug 4255- added css properties for resolved conflicts for responsiveness */
.dropdownAgencyRfp {
	position: relative;
	}
	.dropdown-contentAgencyRfp {
	display: none;
	position: absolute;
	right: 0;
	background-color: #f9f9f9;
	min-width: 160px;
	box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	z-index: 100000;
	}
	.dropdown-contentAgencyRfp a {
	color: black;
	padding: 6px 8px;
	text-decoration: none;
	display: block;
	}
  
   .dropdown-contentAgencyRfp a:hover { background-image: linear-gradient(to right,#28166f,#007cc3);color:white}
  .dropdownAgencyRfp:hover .dropdown-contentAgencyRfp {display: block;}
