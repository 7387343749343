/* Copyright(c) 2020 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Sanjana Godbole/kiran mate
*@fileName : createCampaign.css
 *Desc: css code changes
*/

@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}
.drpdisable {
  opacity: 0.3;
  pointer-events: none;
}
.picky__input {
  width: 100%;
  background-color: white;
  border: 0;
  border-width: 1px;
  border-color: #eee;
  border-style: solid;
  border-radius: 2px;
  padding: 5px;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  height: 37px;
}
.custom-classPopup {
  background-image: linear-gradient(to right, #144999, #144999);
  color: white;
}

.sc-launcher {
  width: 60px;
  height: 60px;
  background-color: #144999 !important;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  right: 25px;
  bottom: 50px !important;
  border-radius: 50%;
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
}

.sc-launcher .sc-open-icon,
.sc-launcher .sc-closed-icon {
  width: 60px;
  height: 60px;
  position: fixed;
  right: 25px;
  bottom: 50px !important;
  transition: opacity 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out,
    -webkit-transform 100ms ease-in-out;
}

.sc-chat-window {
  width: 300px !important;
  height: 450px !important;
  height: calc(100% - 50px);
  max-height: 590px;
  position: fixed;
  right: 25px;
  bottom: 100px;
  box-sizing: border-box;
  box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.3);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sc-header {
  background: #144999 !important;
  min-height: 75px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  color: white;
  padding: 10px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  box-sizing: border-box;
  display: flex;
}

.sc-header--img {
  border-radius: 50%;
  align-self: center;
  padding: 10px;

  width: 25% !important;
}

.sc-message--avatar {
  background-image: url(https://d13yacurqjgara.cloudfront.net/assets/avatar-default-aa2eab7….gif);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  align-self: center;
  margin-right: 15px;
}

.fab-container {
  bottom: 6vh !important;
  position: fixed;
  margin: 1em;
  right: 1vw !important;
}

/* .fab-item {
  background-color: transparent !important;
 box-shadow: 0 0px 0px rgba(0,0,0,0.16), 0 0px 0px rgba(0,0,0,0.23) !important;
} */

/* .ant-comment
{
  height:140px!important;
} */

.ant-comment-inner {
  display: flex;
  padding: 0px 0 !important;
}
