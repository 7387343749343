/* Copyright(c) 2020 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Sanjana Godbole
@filename:requestForProposalPublisher.css
@Creation Date:26-08-2020
@Description: CSS I  for Request For Proposal For Publisher /css code changes

*/
.tabFontSize {
  font-size: 12px;
}
.columnSize {
  width: 13% !important;
}
/* Small devices (mobile 375 and down) */
@media only screen and (max-width: 320px) {
  .rfpTitle {
    margin-left: 70% !important;
    margin-top: -12% !important;
  }

  .columnSize {
    width: 5%;
  }
  .tableSize {
    width: 676px !important;
  }
  .mobileLProblem {
    left: 16px;
  }
  .ack {
    margin-left: 200% !important;
  }
}
@media only screen and (max-width: 375px) {
  .columnSize {
    width: 6% !important;
  }
  .tableSize {
    width: 676px !important;
  }
  .mobileLProblem {
    left: 16px;
  }
}
@media only screen and (min-width: 321px) and (max-width: 424px) {
  .rfpTitle {
    margin-left: 35% !important;
    margin-top: -12% !important;
  }

  .ack {
    margin-left: 147% !important;
  }

  .mobileLProblem {
    left: 16px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .rfpTitle {
    margin-left: 18% !important;
    margin-top: -4% !important;
  }

  .ack {
    margin-left: 147% !important;
  }
  .columnSize {
    width: 5%;
  }
  .tableSize {
    width: 676px !important;
  }
  .mobileLProblem {
    left: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .rfpTitle {
    margin-top: -2% !important;
  }
  .tabFontSize {
    font-size: 9px;
  }
  .tableSize {
    width: 773px !important;
  }
  .mobileLProblem {
    left: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  .rfpTitle {
    margin-top: -2% !important;
  }
}

.countryLead1 {
  font-weight: unset !important; /*sunita-task-3744-added css as per requirement*/
}

/*Chaitanya-4337-CountryViz Lead Allocation Popup-Rename button & msg needed if blank.*/
@media only screen and (min-width: 320px) and (max-width: 375px){ 
  .CountryVizLeadPub
  {
  margin-top: 12%;
  width: 70%;
  margin-left: 17%;
  }
 }
@media only screen and (min-width: 375px) and (max-width: 425px){ 
  .CountryVizLeadPub
  {
    margin-top: 12%;
    width: 70%;
    margin-left: 17%;
  }
  }
@media only screen and (min-width: 425px) and (max-width: 768px){ 
  .CountryVizLeadPub
  {
    margin-top: 12%;
    width: 70%;
    margin-left: 17%;
  }
  }
@media only screen and (min-width: 768px) and (max-width: 1024px){ 
  .CountryVizLeadPub
  {
      margin-top: 12%;
  }
  }
@media only screen and (min-width: 1024px) and (max-width: 1440px){ 
  .CountryVizLeadPub
  {
    margin-top: 9%;
  }
  } 
@media only screen and (min-width: 1440px) and (max-width: 1440px){ 
  .CountryVizLeadPub
  {
      margin-top: 6%;
  }
 }    
@media only screen and (min-width: 1441px){ 
  .CountryVizLeadPub
  {
      margin-top: 6%;
  }
}       