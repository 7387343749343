/* Copyright(c) 2020 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Sanjana Godbole/kiran mate
*@fileName : incompleteCampaign.css
 *Desc: css code changes
*/

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .thead1 {
    width: calc(
      100% - 1.5em
    ); /* scrollbar is average 1em/16px width, remove it from thead width*/
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    table-layout: fixed; /* even columns width , fix width of table too*/
    display: table;
  }

  .tbody1 {
    display: block;
    height: 380px;
    overflow: auto;
  }
  .tbody1 .tr1 {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .thead1 {
    width: calc(
      100% - 1.5em
    ); /* scrollbar is average 1em/16px width, remove it from thead width*/
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    table-layout: fixed; /* even columns width , fix width of table too*/
    display: table;
  }

  .tbody1 {
    display: block;
    height: 380px;
    overflow: auto;
  }
  .tbody1 .tr1 {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .thead1 {
    width: calc(
      100% - 1.5em
    ); /* scrollbar is average 1em/16px width, remove it from thead width*/
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    table-layout: fixed; /* even columns width , fix width of table too*/
    display: table;
  }

  .tbody1 {
    display: block;
    height: 100px;
    overflow: auto;
  }
  .tbody1 .tr1 {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
    text-align: center !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .thead1 {
    width: calc(
      100% - 1.5em
    ); /* scrollbar is average 1em/16px width, remove it from thead width*/
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    table-layout: fixed; /* even columns width , fix width of table too*/
    display: table;
    overflow: auto;
  }

  .tbody1 {
    display: block;
    height: 380px;
    overflow: auto;
  }
  .tbody1 .tr1 {
    display: table;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
    text-align: center !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .thead1 {
    width: 100%; /* calc( 100% - 1.5em ); scrollbar is average 1em/16px width, remove it from thead width*/
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    table-layout: fixed; /* even columns width , fix width of table too*/
    display: table;
  }

  .tbody1 {
    display: block;
    height: 380px;
    overflow: auto;
  }
  .tbody1 .tr1 {
    display: table;
    width: 100%;
    box-shadow: 0 0.9rem 1rem 0 rgba(0, 0, 0, 0.1);
    table-layout: fixed; /* even columns width , fix width of table too*/
    text-align: center !important;
  }
}

.incompleteCampaignFontSize {
  font-size: 12px !important;
}
.modal-title {
  font-size: 16px !important;
  font-weight: 600 !important;
}
.modal-content {
  font-size: 12px !important;
}
.tip {
  position: relative !important;
  display: inline-block !important;
  /* border-bottom: 1px dotted black !important; */
}

.tip .tooltiptext4 {
  visibility: hidden;
  width: 100px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #555;
  color: black;
  font-size: 1vw;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1031;
  bottom: 125%;
  left: 325%;
  margin-left: -200px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tip .tooltiptext4::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tip:hover .tooltiptext4 {
  visibility: visible;
  opacity: 1;
}
/* @author : Karan Jagtap
@description : Bug 3306 - fixing the table header blur effect */
.table-header {
  font-weight: 500 !important;
}
