/* Copyright(c) 2021 APSS Media Pvt. Ltd.
*  All Rights Reserved
*
/*
@author:Sandeep Dhawale/Sanjana Godbole
@Creation Date:06-05-2021
@Description:css file for addUserAdvertiser Page/csss code changes
*/

/* Umesh-4254-R28.8-Prod issue-Advertiser login-setting option-Add User main Page issue */
.cardBodyClass {
  height: auto !important;
  width: 100% !important;
  padding-top: 10px;
  padding-bottom: 10px;
}


@media only screen and (min-width: 0px) and (max-width: 320px) {
  /*Sanjana-task-4118-added below css as per requirement*/
  #PuserName {
    /* width:200% !important */
    /*sunita-task-3782-added below css as per requirement*/
    width: 100% !important;
    /* height: 70% !important; */
    height: auto !important;
  }

  /*sunita-task-3782-added below css as per requirement*/
  /*Sanjana-task-4118-added below css as per requirement*/
  .Proleinputbox {
    padding-top: 18px !important;
  }

  .cardBodyClass .advcardBodyClass {
    width: 294px !important;
    padding: 12px !important;
    margin: -2px !important;
    /* height: 385px !important; */
  }

  .btn.buttonSave {
    margin-top: auto !important;
  }
}

/*sunita-task-3782-added below css as per requirement*/
@media only screen and (min-width: 320px) and (max-width: 375px) {
}

@media screen and (min-width: 375px) {
  .cardBodyClass {
    /* width: 357px !important; */
    padding: 12px !important;
    margin: -2px !important;
    /* height: 375px !important; */
  }

  .btn.buttonSave {
    margin-top: auto !important;
  }

  #PuserName {
    width: 100% !important;
    /* height: 70% !important; */
    height: auto !important;

  }

  .Proleinputbox {
    padding-top: 18px !important;
  }
}

/*sunita-task-3782-added below css as per requirement*/
@media only screen and (min-width: 376px) and (max-width: 425px) {
  /*Sanjana-task-4118-added below css as per requirement*/
  .roleinputbox {
    padding-top: 12px !important;
  }

  #userName {
    height: 67% !important;
  }

  .cardBodyClass {
    /* width: 750px !important; */
    padding: 12px !important;
    margin: -2px !important;
    /* height: 244px !important; */
  }

  .btn.buttonSave {
    margin-top: auto !important;
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  /*Sanjana-task-4118-added below css as per requirement*/
  #PuserName {
    width: 100% !important;
    /* height: 60% !important; */
    height: auto !important;

  }

  .Proleinputbox {
    padding-top: 0px !important;
  }

  .cardBodyClass {
    /* width: 750px !important; */
    padding: 12px !important;
    margin: -2px !important;
    /* height: 244px !important; */
  }

  .btn.buttonSave {
    margin-top: auto !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  /*Sanjana-task-4118-added below css as per requirement*/
  #PuserName {
    width: 60% !important;
    /* height: 60% !important; */
    height: auto !important;

  }

  .Proleinputbox {
    padding-top: 0px !important;
  }

  .cardBodyClass {
    /* width: 1000px !important; */
    padding: 12px !important;
    margin: -2px !important;
    /* height: 244px !important; */
  }

  .btn.buttonSave {
    margin-top: auto !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  /*Sanjana-task-4118-added below css as per requirement*/

  #PuserName {
    width: 60% !important;
    /* height: 60% !important; */
    height: auto !important;

  }

  .Proleinputbox {
    padding-top: 0px !important;
  }

  .cardBodyClass {
    /* width: 100% !important; */
    padding: 12px !important;
    margin: -2px !important;
    /* height: 219px !important; */
  }

  .btn.buttonSave {
    margin-top: auto !important;
  }
}

@media only screen and (min-width: 1441px) and (max-width: 2560px) {
  /*Sanjana-task-4118-added below css as per requirement*/
  #PuserName {
    width: 60% !important;
    /* height: 60% !important; */
    height: auto !important;

  }

  .Proleinputbox {
    padding-top: 0px !important;
  }

  .cardBodyClass {
    /* width: 100% !important; */
    padding: 12px !important;
    margin: -2px !important;
    /* height: 219px !important; */
  }

  .btn.buttonSave {
    margin-top: auto !important;
  }
}

#loader_report1 {
  position: absolute;
  width: 25%;
  height: 19vh;
  background: white url("/../new_loader.gif") no-repeat center;
  z-index: 999;
  margin-left: 500px;
  margin-top: 70px;
}
