/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : admin.css
 *Desc: css code changes
 */
a.dropdown-item:hover{
    background:#2196f3;
    color: #ffffff !important;
  } 
  
  .dropdown-item:hover{
    color:white;
   font-weight:500;
  }
 /*
@author:Sanjana Godbole
@Creation Date:29-05-2019
*/

/* Copyright(c) 2019 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
.agencyLiveCampaignFontSize
{
    font-size: 12px !important;
}
.tip {
  position: relative !important;
  display: inline-block !important;
  /* border-bottom: 1px dotted black !important; */
}
.tip .tooltiptext2 {
  visibility: visible;
  width: 65px;
  background-color:white;
  border-style: solid;
  border-width: 1px;
  border-color: #555;
  color:black;
  font-size:small;
  text-align: center;
  border-radius: 3px;
  padding: 5px 0;
  position: absolute;
  z-index: 1031;
  bottom: 125%;
  left: 50%;
  margin-left:-20px;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow:" 0 4px 8px 0  grey";
}
.tip .tooltiptext2::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  /* border-style: solid; */
  /* border-color: #555 transparent transparent transparent; */
  box-shadow:" 0 4px 8px 0 grey";
}
.tip:hover .tooltiptext2 {
  visibility: auto;
  opacity: 1;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) { 
.thead {
    width: calc( 100% - 1.5em ); /* scrollbar is average 1em/16px width, remove it from thead width*/
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    table-layout:fixed;/* even columns width , fix width of table too*/
    display:table;
    width: '1298px'
}
.tbodyy {
    display:block;
    height:'418px';
    overflow:auto;
    width: 1298px;
}
 .tbody .tr {
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    display:table;
    width:100%;
    table-layout:fixed;/* even columns width , fix width of table too*/
}
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
.thead {
    width: calc( 100% - 1.5em ); /* scrollbar is average 1em/16px width, remove it from thead width*/
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    table-layout:fixed;/* even columns width , fix width of table too*/
    display:table; 
}
.tbodyy {
    display:block;
    height:100px;
    overflow:auto;  
}
 .tbody .tr {
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    display:table;
    width:100%;
    table-layout:fixed;/* even columns width , fix width of table too*/
}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
.thead {
    width: calc( 100% - 1.5em ); /* scrollbar is average 1em/16px width, remove it from thead width*/
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    table-layout:fixed;/* even columns width , fix width of table too*/
    display:table;
    width:1322px;
}
.tbodyy {
    display:block;
    height:394px;
    overflow:auto;
}
 .tbodyy .tr {
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    display:table;
    width:100%;
    table-layout:fixed;/* even columns width , fix width of table too*/
    text-align:center !important;
}
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
.thead {
    width: calc( 100% - 1.5em ); /* scrollbar is average 1em/16px width, remove it from thead width*/
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    table-layout:fixed;/* even columns width , fix width of table too*/
    display:table;
    overflow:auto;
}
.tbodyy {
    display:block;
    height:380px;
    overflow:auto;
}
 .tbodyy .tr {
    display:table;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    width:100%;
    table-layout:fixed;/* even columns width , fix width of table too*/
    text-align:center !important;
}
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
.thead 
{
    width:100% ;/* calc( 100% - 1.5em ); scrollbar is average 1em/16px width, remove it from thead width*/
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    table-layout:fixed;/* even columns width , fix width of table too*/
    display:table;
}
.tbodyy{
    display:block;
    height:380px;
    overflow:auto;
}
 .tbodyy .tr {
    display:table;
    width:100%;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    table-layout:fixed;/* even columns width , fix width of table too*/
    text-align:center !important;
}
}
.disabledCursor { 
opacity: 0.6;
cursor: not-allowed  !important;
pointer-events: none !important;
}
.tip {
position: relative !important;
display: inline-block !important;
/* border-bottom: 1px dotted black !important; */
}
.tip .tooltiptext8 {
visibility: visible;
width: 100px;
background-color:white;
border-style: solid;
border-width: 1px;
border-color: #555;
color:black;
font-size:small;
text-align: center;
border-radius: 3px;
padding: 5px 0;
position: absolute;
z-index: 1031;
bottom: 125%;
left: 50%;
margin-left:-20px;
opacity: 0;
transition: opacity 0.3s;
box-shadow:" 0 4px 8px 0  grey";
}
.tip .tooltiptext8::after {
content: "";
position: absolute;
top: 100%;
left: 50%;
margin-left: -5px;
border-width: 5px;
/* border-style: solid; */
/* border-color: #555 transparent transparent transparent; */
box-shadow:" 0 4px 8px 0 grey";
}
.tip:hover .tooltiptext8 {
visibility: auto;
opacity: 1;
}