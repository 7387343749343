/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 * All Rights Reserved
 */

/*
@author:Priyanka APtil
@Description:Responsive for Email Configuration under task 3874
Date: 27 January 2022


*/
.selectAllAGC {
  float: right;
}
#labelDIAg {
  font-family: roboto !important;
  font-size: 28px !important;
  font-weight: 500 !important;
  text-align: center !important;
  color: #193d8f;
}
.hFour {
  margin-bottom: 20px;
}
@media only screen and (max-width: 320px) {
  #non_single,
  #single {
    width: 206px;
    display: grid;
    grid-row-gap: 8px;
    grid-template-columns: 240px;
  }

  .background {
    width: 250px !important;
    margin-top: 25px;
  }

  #pub_pnc_back,
  #pub_pqa_back,
  #lead_adv_back,
  #pub_aqa_back,
  #lead_aqa_back {
    height: 50px !important;
  }
  #other_pnc_back,
  #lead_pqa_back {
    height: 80px !important;
  }
  #dead_pnc_back,
  #lead_anc_back {
    height: 110px !important;
  }
  #lead_pc_back,
  #camp_pnc_back,
  #lead_ac_back {
    height: 130px !important;
  }
  #pub_pc_back,
  #dead_pc_back,
  #other_pc_back,
  #adv_adv_back,
  #pub_anc_back {
    height: 160px !important;
  }
  #camp_anc_back {
    height: 190px !important;
  }
  #pub_ac_back {
    height: 220px !important;
  }
  #other_anc_back {
    height: 280px !important;
  }
  #other_ac_back {
    height: 390px !important;
  }
  #camp_pc_back {
    height: 420px !important;
  }
  #camp_ac_back {
    height: 480px !important;
  }
  #submitBtn {
    margin-right: -7rem;
    margin-left: 1px;
  }
}
/*
@author:Mufiz Khan
@Description:Responsive issu bug- 4117,4111
File-agencyEmailConfig.css
Title-RESPONSIVE -Prod issue-Ref-(3910)-Agency-setting option-Responsiveness issue(On priority) 
*/

@media only screen and (min-width: 130px) and (max-width: 334px) {
  #submitBtn {
    float: right;
    margin-right: -90px;
  }

  .can-btn-email-Ag {
    float: right;
    margin-right: -148px;
  }
}

@media only screen and(min-width : 100px) and (max-width: 334px) {
  #submitBtn {
    float: right;
    margin-right: -80px;
  }

  .can-btn-email-Ag {
    float: right;
    margin-right: 0px;
  }
}
@media only screen and (min-width: 334px) and (max-width: 348px) {
  .can-btn-email-Ag {
    margin-left: -21px;
  }
}
@media only screen and (min-width: 371px) and (max-width: 375) {
  .hFour {
    margin-bottom: 33px;
  }
}
@media only screen and (max-width: 348px) {
  .selectAllAGC {
    margin-right: 26px;
    width: 112px;
  }
  .hFour {
    margin-bottom: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 999px) {
  .emailformAGC {
    margin-top: 0;
  }
}
@media only screen and (min-width: 992px) {
  .emailformAGC {
    margin-left: 10%;
    margin-top: -98px;
  }
  .selectAllAGC {
    margin-right: 162px;
  }
}
@media only screen and (min-width: 1224px) and (max-width: 1280px) {
  .container-AG {
    margin-top: -107px;
    margin-left: 132px;
  }
  #labelDIAg {
    margin-top: -151px;
  }
  #Container-AG {
    margin-top: 140px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1024px) {
  /* .container {
    margin-top: -107px;
    margin-left: 80px;
  } */
  #Container-AG {
    margin-top: 14%;
  }
  #labelDIAg {
    margin-top: -144px;
  }
}

@media only screen and (min-width: 321) and (max-width: 348) {
  .hFour {
    margin-bottom: 53px;
    background-color: #193d8f;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1024) {
}

@media only screen and (min-width: 320px) {
  #non_single {
    width: 55%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 376px) {
  .selectAllAGC {
    width: 10rem;
    margin-left: 19rem;
    float: left;
  }
}
@media only screen and (min-width: 377px) and (max-width: 429px) {
  .selectAllAGC {
    float: left;
    margin-top: 0rem;
    width: 10rem;
    margin-left: 18rem;
  }
  .hFour {
    width: 30rem;
    margin-bottom: 3px;
  }
  .emailformAGC {
    margin-left: 6rem;
  }
}

@media only screen and (min-width: 321px) and (max-width: 350px) {
  #non_single,
  #single {
    display: grid;
    grid-row-gap: 8px;
    grid-template-columns: 240px;
  }

  .background {
    width: 300px !important;
    margin-top: 25px;
  }

  #pub_pnc_back,
  #pub_pqa_back,
  #lead_adv_back,
  #pub_aqa_back,
  #lead_aqa_back {
    height: 50px !important;
  }
  #other_pnc_back,
  #lead_pqa_back {
    height: 80px !important;
  }
  #dead_pnc_back,
  #lead_anc_back {
    height: 110px !important;
  }
  #lead_pc_back,
  #camp_pnc_back,
  #lead_ac_back {
    height: 130px !important;
  }
  #pub_pc_back,
  #dead_pc_back,
  #other_pc_back,
  #adv_adv_back,
  #pub_anc_back {
    height: 160px !important;
  }
  #camp_anc_back {
    height: 190px !important;
  }
  #pub_ac_back {
    height: 220px !important;
  }
  #other_anc_back {
    height: 280px !important;
  }
  #other_ac_back {
    height: 390px !important;
  }
  #camp_pc_back {
    height: 420px !important;
  }
  #camp_ac_back {
    height: 480px !important;
  }
}
@media only screen and (min-width: 425px) and (max-width: 425px) {
  #other_ac_back {
    width: 60%;
  }
}

#non_single,
#single {
  display: grid;
  grid-row-gap: 8px;
  grid-template-columns: 240px;
}

.background {
  width: 300px !important;
  margin-top: 25px;
}

#pub_pnc_back,
#pub_pqa_back,
#lead_adv_back,
#pub_aqa_back,
#lead_aqa_back {
  height: 50px !important;
}
#other_pnc_back,
#lead_pqa_back {
  height: 80px !important;
}
#dead_pnc_back,
#lead_anc_back {
  height: 110px !important;
}
#lead_pc_back,
#camp_pnc_back,
#lead_ac_back {
  height: 130px !important;
}
#pub_pc_back,
#dead_pc_back,
#other_pc_back,
#adv_adv_back,
#pub_anc_back {
  height: 160px !important;
}
#camp_anc_back {
  height: 190px !important;
}
#pub_ac_back {
  height: 220px !important;
}
#other_anc_back {
  height: 280px !important;
}
#other_ac_back {
  height: 390px !important;
}
#camp_pc_back {
  height: 420px !important;
}
#camp_ac_back {
  height: 480px !important;
}

@media only screen and (min-width: 349px) {
  #submitBtn {
    margin-right: -2rem;
    margin-left: 2rem;
  }
  .can-btn-email-Ag {
    float: right;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 643px) and (max-width: 667px) {
  .selectAllAGC {
    margin-right: 71px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 769px) {
  .selectAllAGC {
    margin-right: 81px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 767px) {
  .selectAllAGC {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 769px) {
  .emailformAGC {
    margin-left: 21%;
    margin-right: 11%;
  }

  #non_single,
  #single {
    display: grid;
    grid-gap: 8px 8px;
    grid-template-columns: 240px 225px;
  }

  .background {
    width: 460px !important;
    margin-top: 25px;
  }

  #pub_pnc_back,
  #other_pnc_back,
  #pub_pqa_back,
  #lead_adv_back,
  #pub_aqa_back,
  #lead_aqa_back {
    height: 50px !important;
  }
  #lead_pqa_back,
  #lead_ac_back,
  #camp_pnc_back,
  #lead_anc_back,
  #dead_pnc_back {
    height: 80px !important;
  }
  #pub_pc_back,
  #dead_pc_back,
  #other_pc_back,
  #adv_adv_back,
  #camp_pub_back,
  #pub_anc_back {
    height: 110px !important;
  }
  #lead_pc_back,
  #pub_ac_back {
    height: 130px !important;
  }
  #other_anc_back {
    height: 160px !important;
  }
  #other_ac_back {
    height: 210px !important;
  }
  #camp_pc_back {
    height: 230px !important;
  }
  #camp_ac_back {
    height: 250px !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  #non_single {
    display: grid !important;
    grid-gap: 8px 8px !important;
    grid-template-columns: 215px 240px 225px !important;
    width: 700px !important;
  }

  #single {
    display: grid !important;
    grid-row-gap: 8px !important ;
    width: 700px !important;
  }

  .background {
    width: 700px !important;
    margin-top: 25px;
  }

  .selectAllAGC {
    margin-right: 319px !important;
  }

  #pub_pnc_back,
  #pub_pqa_back,
  #lead_adv_back,
  #pub_aqa_back,
  #lead_aqa_back {
    height: 50px !important;
  }
  #pub_pc_back,
  #dead_pc_back,
  #other_pc_back,
  #lead_pqa_back,
  #other_pnc_back,
  #adv_adv_back,
  #camp_anc_back,
  #pub_anc_back {
    height: 80px !important;
  }
  #dead_pnc_back,
  #pub_ac_back,
  #lead_anc_back,
  #other_anc_back {
    height: 110px !important;
  }
  #lead_pc_back,
  #camp_pnc_back,
  #lead_ac_back,
  #other_ac_back {
    height: 160px !important;
  }
  /* kiran-4293-added css changes for responsiveness */
  #camp_pc_back {
    height: 165px !important;
  }
  #camp_ac_back {
    height: 200px !important;
  }
}
@media only screen and (min-width: 1224px) and (max-width: 1280px) {
  .container-AG {
    margin-left: 35px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .emailformAGC {
    margin-right: -3rem;
    margin-left: 33rem;
    margin-top: 2px;
  }
  #submitBtn {
    margin-right: 10rem;
  }
  #non_single {
    display: grid !important;
    grid-gap: 8px 8px !important;
    grid-template-columns: 215px 240px 225px !important;
    width: 700px !important;
  }

  #single {
    display: grid !important;
    grid-row-gap: 8px !important ;
    width: 700px !important;
  }

  .background {
    width: 700px !important;
    margin-top: 25px;
  }
  .selectAllAGC {
    margin-right: 15rem;
  }

  #pub_pnc_back,
  #pub_pqa_back,
  #lead_adv_back,
  #pub_aqa_back,
  #lead_aqa_back {
    height: 50px !important;
  }
  #pub_pc_back,
  #dead_pc_back,
  #other_pc_back,
  #lead_pqa_back,
  #other_pnc_back,
  #adv_adv_back,
  #camp_anc_back,
  #pub_anc_back {
    height: 80px !important;
  }
  #dead_pnc_back,
  #pub_ac_back,
  #lead_anc_back,
  #other_anc_back {
    height: 110px !important;
  }
  #lead_pc_back,
  #camp_pnc_back,
  #lead_ac_back,
  #other_ac_back {
    height: 200px !important;
    /* kiran-4293-added css changes for responsiveness */
  }
  #camp_pc_back {
    height: 165px !important;
  }
  #camp_ac_back {
    height: 200px !important;
  }
}
@media only screen and(max-width: 448px) {
  .selectAllAGC {
    margin-right: 19px;
  }
}
@media only screen and(max-width: 390px) {
  .selectAllAGC {
    width: 10rem;
    float: left;
    margin-left: 21rem;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 2000px) {
  #submitBtn {
    float: right;
    margin-left: 25px;
    margin-right: 28px;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 2560px) {
  .emailformAGC {
    margin: 0px 2px 2px 17%;
  }

  #non_single {
    display: grid !important;
    grid-gap: 8px 8px !important;
    grid-template-columns: 240px 240px 240px 240px !important;
    width: 900px !important;
  }

  #single {
    display: grid !important;
    grid-row-gap: 8px !important ;
    width: 900px !important;
  }

  .background {
    width: 1000px !important;
    margin-top: 25px;
  }

  .selectAllAGC {
    margin-right: -5%;
  }

  #pub_pnc_back,
  #pub_pqa_back,
  #lead_adv_back,
  #pub_aqa_back,
  #lead_aqa_back {
    height: 50px !important;
  }
  #pub_pc_back,
  #dead_pc_back,
  #other_pc_back,
  #lead_pqa_back,
  #other_pnc_back,
  #adv_adv_back,
  #camp_anc_back,
  #pub_anc_back {
    height: 80px !important;
  }
  #dead_pnc_back,
  #pub_ac_back,
  #lead_anc_back,
  #other_anc_back,
  #other_ac_back {
    height: 110px !important;
  }
  #lead_pc_back,
  #camp_pnc_back {
    height: 130px !important;
  }
  #camp_ac_back {
    height: 140px !important;
  }
  #lead_ac_back {
    height: 150px !important;
  }
  #camp_pc_back {
    height: 165px !important;
  }
}
@media only screen and (min-width: 1193px) and (max-width: 1200px) {
  .selectAllAGC {
    margin-right: 40%;
  }
}
@media only screen and (min-width: 1117px) and (max-width: 1192px) {
  .selectAllAGC {
    margin-right: 41%;
  }
}
@media only screen and (min-width: 1032px) and (max-width: 1116px) {
  .selectAllAGC {
    margin-right: 35%;
  }
}

@media only screen and (min-width: 1892px) {
  .selectAllAGC {
    margin-right: 17%;
  }
  .emailformAGC {
    margin-left: 30%;
  }
}

@media only screen and (min-width: 2561px) {
  .emailformAGC {
    margin-top: 0px !important;
  }
  #submitBtn {
    float: right;
    margin-right: 234px;
    margin-left: 20px;
  }
  #non_single {
    display: grid !important;
    grid-gap: 8px 8px !important;
    grid-template-columns: 240px 240px 240px 240px !important;
    width: 900px !important;
  }

  #single {
    display: grid !important;
    grid-row-gap: 8px !important ;
    width: 900px !important;
  }

  .background {
    width: 1000px !important;
    margin-top: 25px;
  }
  .selectAllAGC {
    margin-right: 200px !important;
  }

  #pub_pnc_back,
  #pub_pqa_back,
  #lead_adv_back,
  #pub_aqa_back,
  #lead_aqa_back {
    height: 50px !important;
  }
  #pub_pc_back,
  #dead_pc_back,
  #other_pc_back,
  #lead_pqa_back,
  #other_pnc_back,
  #adv_adv_back,
  #camp_anc_back,
  #pub_anc_back {
    height: 80px !important;
  }
  #dead_pnc_back,
  #pub_ac_back,
  #lead_anc_back,
  #other_anc_back,
  #other_ac_back {
    height: 110px !important;
  }
  #lead_pc_back,
  #camp_pnc_back {
    height: 130px !important;
  }
  #camp_ac_back {
    height: 140px !important;
  }
  #lead_ac_back {
    height: 150px !important;
  }
  #camp_pc_back {
    height: 165px !important;
  }
}
@media only screen and (min-width: 2570px) and (max-width: 27620px) {
  .emailformAGC {
    margin-top: 20px;
    margin-right: 16%;
  }
}

#single {
  display: grid !important;
  grid-row-gap: 8px !important ;
}

.background {
  background: #fff;
  padding: 10px 5px 10px 15px;
  border: 1px solid #e9e9e9;
}

.can-btn-email-Ag {
  border: 2px solid #193d8f;
  background-color: transparent;
  border-radius: 2px;
  color: #193d8f;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 400;
  width: 74px;
  height: 33px;
  border-radius: 2px;
}

.can-btn-email-Ag:hover {
  background: #193d8f;
  color: white;
}
@media only screen and(max-width:350px) {
  #submitBtn {
    float: right;
    margin-right: -101px;
  }
  .hFour {
    margin-bottom: 35px;
  }
}

@media only screen and (min-width: 2000px) and (max-width: 2560px) {
  #submitBtn {
    margin-right: 27rem;
    margin-left: 2rem;
  }
  .selectAllAGC {
    margin-right: 28%;
  }
}

/*
@author:Chaitanya
@Description:Responsive issue bug- 4291
File-agencyEmailConfig.css
Title-4291-R29-Prod issue-Agency-Setting option-Email Notifications-submit button responsive issue 
*/
@media only screen and (min-width: 320px) and (max-width: 375px) {
  #submitBtn {
    margin-right: -5rem;
    margin-left: 1rem;
  }
  .emailformAGC{
    margin-left: -1rem;
  }
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  #submitBtn {
    margin-right: -3rem;
  }
  .emailformAGC{
    margin-left: 2rem;
  }
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
  #submitBtn {
    margin-right: 0rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #submitBtn {
    margin-right: -2rem;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  #submitBtn {
    margin-right: 4rem;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  #submitBtn {
    margin-right: 22rem;
  }
}