/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : publisherMyReport.css
 *Desc: CSS code changes
 */

/* //snehal-task-3460-publisher side--my report--responsiveness issue(Added new file for responssivness) */
#loader{
	position: absolute;
	width: 25%;
	height: 25vh;
    background: white url('/../new_loader.gif') no-repeat center;
	z-index: 999;
    margin-left: 500px;
    margin-top: 90px;
}
#to_truncate_new{
    text-overflow: ellipsis!important;
    overflow: hidden !important;
    white-space: nowrap!important;  
    width: 100%!important;
    /*chaitanya-4187-Report name displays full name in Report listing page*/
 }
#to_truncate1{
    text-overflow: ellipsis!important;
    overflow: hidden !important;
    white-space: nowrap!important;  
    width:120px!important;
   
 }
/* snehal-task-3357-Report Engine- Publisher View- My Reports and Agency Delivery Tracking Report- Front End(Added media queries) */
#loader_report{
	position: absolute;
	width: 25%;
	height: 20vh;
    background: white url('/../new_loader.gif') no-repeat center;
	z-index: 999;
    margin-left: 500px;
    margin-top: 80px;
} 
#gen_loader{
    position: absolute;
	width: 25%;
	height: 25vh;
    background: white url('/../generate_loader.gif') no-repeat center;
	z-index: 999;
    margin-left: 500px;
    margin-top: 90px;
}
/* snehal-3417-Report Engine-Publisher View-Lead Tracking Report-FE */
.pickyStatuscss {
    border: 1px solid #d2cccc !important;
    border-radius: 4px !important;

}
/* snehal-task-publisher side----agency lead tracking report---drop down and table header issue */
select#leadStatus{
    height:calc(2.25rem + 12px);
    border-radius: 4px;
}
/* snehal-task-3357-Report Engine- Publisher View- My Reports and Agency Delivery Tracking Report- Front End(Added media queries) */
@media only screen and (max-width: 320px){
    #gen_loader, #loader{
        margin-top: 120px !important;
        width: 70% !important;
        margin-left: 30px !important;
    }
    #loader_report{
        margin-top: 80px !important;
        width: 70% !important;
        margin-left: 30px !important;
    }
    .reportType_myreport {
        float: left !important;
        margin-left: -15px !important;
    }
    .search_pub_myreport{
        width: 108% !important;
        margin-left: -15px !important;
    }
     /* //snehal-task-3460-publisher side--my report--responsiveness issue (UI changes)*/
    .createReportModal,.editReportModal{
        margin-left: 33px !important;
    }
    /* snehal-task-3568-pub side---my reaport---publisher lead tracking issue( manage columns css) */
    /* snehal-task-3552-lead tracking report---responsive issue */
    div#manageColumnsModal_css {
        left: -2% !important;
        width: 308px !important;
        margin-top: 90px !important;
    }
    div.manage_col_footer
    {
        margin-left: -38px !important;
        margin-right: 20px !important;
    }
 }
@media only screen and (min-width: 321px) and (max-width: 375px){
    #gen_loader, #loader{
        margin-top: 120px !important;
        width: 70% !important;
        margin-left: 30px !important;
    }
    #loader_report{
        margin-top: 80px !important;
        width: 70% !important;
        margin-left: 30px !important;
    }
    .reportType_myreport {
        float: left !important;
        margin-left: -15px !important;
    }
    .search_pub_myreport{
        width: 89% !important;
        margin-left: -15px !important;
    }
     /* //snehal-task-3460-publisher side--my report--responsiveness issue (UI changes)*/
    .createReportModal,.editReportModal{
        margin-left: 36px !important;
    }
    /* snehal-task-3568-pub side---my reaport---publisher lead tracking issue( manage columns css) */
      /* snehal-task-3552-lead tracking report---responsive issue */
    div#manageColumnsModal_css {
        left: -2% !important;
        width: 365px !important;
        margin-top: 91px !important;
    }
    div.manage_col_footer
    {
        margin-left: -38px !important;
        margin-right: 20px !important;
    }
 }
 @media only screen and (min-width: 376px) and (max-width: 425px){
    #gen_loader,#loader{
        margin-top: 110px !important;
        width: 70% !important;
        margin-left: 50px !important;
    }
    #loader_report{
        margin-top: 80px !important;
        width: 70% !important;
        margin-left: 50px !important;
    }
    .reportType_myreport {
        float: left !important;
        margin-left: -15px !important;
    }
    .search_pub_myreport{
        width: 76% !important;
        margin-left: -15px !important;
    }
     /* //snehal-task-3460-publisher side--my report--responsiveness issue (UI changes)*/
    .createReportModal,.editReportModal{
        margin-left: 41px !important;
    }
    /* snehal-task-3568-pub side---my reaport---publisher lead tracking issue( manage columns css) */
      /* snehal-task-3552-lead tracking report---responsive issue */
    div#manageColumnsModal_css {
        left: 5% !important;
        width: 371px !important;
        margin-top: 90px !important;
    }
    div.manage_col_footer
    {
        margin-left: -38px !important;
        margin-right: 20px !important;
    }
}
@media only screen and (min-width: 426px) and (max-width: 768px){
    #gen_loader,#loader{
        margin-top: 110px !important;
        width: 40% !important;
        margin-left: 220px !important;
    }
    #loader_report{
        width: 40% !important;
        margin-left: 220px !important;
    }
    .reportType_myreport {
        float: left !important;
        margin-left: -10px !important;
    }
    .search_pub_myreport{
        width: 100% !important;
        margin-left: -98px !important;
    }
     /* //snehal-task-3460-publisher side--my report--responsiveness issue (UI changes)*/
    .createReportModal,.editReportModal{
        margin-left: 234px !important;
    }
    /* snehal-task-3568-pub side---my reaport---publisher lead tracking issue( manage columns css) */
      /* snehal-task-3552-lead tracking report---responsive issue */
    div#manageColumnsModal_css {
        width: 365px !important;
        left: 51% !important;
        margin-top: 91px !important;
    }
}
@media only screen and (min-width: 769px) and (max-width: 1024px){
    #gen_loader,#loader{
        margin-top: 110px !important;
        margin-left: 350px !important;
    }
    #loader_report{
        margin-left: 350px !important;
    }
    .reportType_myreport {
        float: right !important;
    }
    .search_pub_myreport{
        width: 123% !important;
        margin-left: -22px !important;
    }
     /* //snehal-task-3460-publisher side--my report--responsiveness issue (UI changes)*/
    .createReportModal,.editReportModal{
        margin-left: 366px !important;
    }
    /* snehal-3417-Report Engine-Publisher View-Lead Tracking Report-FE */
    .camp_block1,.camp_block2,.camp_block3,.camp_block4 {
        margin-left: 30px !important;
    }
    /* snehal-task-3568-pub side---my reaport---publisher lead tracking issue( manage columns css) */
    /* snehal-task-3552-lead tracking report---responsive issue */
    div#manageColumnsModal_css {
        left: 51% !important;
        width: 365px !important;
        margin-left: 131px !important;
        margin-top: 91px !important;
    }
    .camp_block_dis{
        margin-right: 20px !important;
    }
    .camp_block_dis_lead{
        margin-left: 20px !important;
    }
}
@media only screen and (min-width: 1025px) and (max-width: 1440px){
    #gen_loader,#loader,#loader_report{
        margin-left: 500px !important;
    }
    .reportType_myreport {
        float: right !important;
        margin-right: -21px !important;
    }
    .search_pub_myreport{
        width: 100% !important;
    }
     /* //snehal-task-3460-publisher side--my report--responsiveness issue (UI changes)*/
    .createReportModal,.editReportModal{
        margin-left: 560px !important;
    }
    /* snehal-3417-Report Engine-Publisher View-Lead Tracking Report-FE */
    .camp_block1 {
        margin-right: 39px !important;
    }
    /* snehal-task-3568-pub side---my reaport---publisher lead tracking issue( manage columns css) */
      /* snehal-task-3552-lead tracking report---responsive issue */
    div#manageColumnsModal_css {
        left: 82% !important;
        width: 382px !important;
        margin-top: 176px !important;
        margin-left: 132px !important;
    }
    .camp_block_dis{
        margin-right: 50px !important;
    }
}
@media only screen and (min-width: 1441px){
    #gen_loader,#loader,#loader_report{
        margin-left: 1000px !important;
    }
     /* //snehal-task-3460-publisher side--my report--responsiveness issue (UI changes)*/
    .createReportModal,.editReportModal{
        margin-left: 1112px !important;
    }
}

/*Chaitanya-task-4187-publisher side-My reports- Report title not showing full name on report list page*/
@media only screen and (min-width: 320px) and (max-width:375px)
{   
    .reportTypePublisher
    {
        width: 107% !important;
        margin-left: -4% !important;
        float: left !important;
    }
    .reportTypeEditReportPublisher
    {
        width: 107% !important;
        margin-left: -4% !important;
        float: left !important;
    }
    .reportTypeMyreportPublisher
    {
        width: 108% !important;
        margin-bottom: 2% !important;
    }
    .reportNamePublisher
    {
        width: 107% !important;
        margin-left: -4% !important;
        float: left !important;
    }
    #editButton_pub
    {
        margin-Left: 10% !important;
    }
   .search_pub_myreport
   {
    width: 108% !important;
    margin-left: 0% !important;
    margin-bottom: 2% !important;
   }
   .modal-content_publisher
   {
    margin-left: -5% !important;
    float: left !important;
    width: 111% !important;
   } 
   .RecordsPublisher
   {
    margin-left: 20% !important;
    width: 58% !important;
   }
}
/*Chaitanya-task-4187-publisher side-My reports- Report title not showing full name on report list page*/
@media only screen and (min-width: 375px) and (max-width:425px)
{   
    .reportTypePublisher
    {
        width: 100% !important;
    }
    .reportTypeEditReportPublisher
    {
        width: 100% !important;
    }
    .reportTypeMyreportPublisher
    {
        width: 100% !important;
        margin-bottom: 2% !important;
    }
    .reportNamePublisher
    {
        width: 100% !important;
    }
    .search_pub_myreport
   {
    width: 100% !important;
    margin-left: 0% !important;
    margin-bottom: 2% !important;
   }
   .modal-content_publisher
   {
    float: left !important;
    margin-left: 10% !important;  
   }
   .RecordsPublisher
   {
    margin-left: 20% !important;
    width: 50% !important;
   } 
}
/*Chaitanya-task-4187-publisher side-My reports- Report title not showing full name on report list page*/
@media only screen and (min-width: 425px) and (max-width:768px)
{   
    .reportTypePublisher
    {
        width: 100% !important;
    }
    .reportTypeEditReportPublisher
    {
        width: 100% !important;
    }
    .reportTypeMyreportPublisher
    {
        width: 100% !important;
        margin-bottom: 2% !important;
    }
    .reportNamePublisher
    {
        width: 100% !important;
    }
    #editButton_pub
    {
        margin-Left: 16% !important;
    }
    .search_pub_myreport
    {
        width: 100% !important;
        margin-left: 0% !important;
        margin-bottom: 2% !important;
    }
    .modal-content_publisher
    {
        float: left !important;
        margin-left: 15% !important; 
        width: 90% !important;
    }
    .RecordsPublisher
    {
        margin-left: 15% !important;
        width: 40% !important;
    }  
}
/*Chaitanya-task-4187-publisher side-My reports- Report title not showing full name on report list page*/
@media only screen and (min-width: 768px) and (max-width:1024px)
{
    .reportTypeMyreportPublisher 
    {
        width: 82% !important;
        margin-bottom: 2% !important;
    }
    .search_pub_myreport
    {
        width: 100% !important;
        margin-left: 26% !important;
    } 
    .modal-content_publisher
    {
        float: right !important;
        margin-right: 0% !important; 
        width: 100% !important;
    }
    #editButton_pub
    {
        margin-Left: 30% !important;
    }
    #cancleButton_pub
    {
        margin-Left: 10% !important;
    }
    #createButton_pub
    {
        margin-Left: 30% !important;
    }
    .RecordsPublisher
    {
        margin-left: 0% !important;
        width: 22% !important;
    }
}
/*Chaitanya-task-4187-publisher side-My reports- Report title not showing full name on report list page*/
@media only screen and (min-width: 1024px) and (max-width:1440px)
{   
    .reportTypeMyreportPublisher
    {
        width: 125% !important;
        float: right;
        margin-right: 2% !important;
    }
    .search_pub_myreport
    {
        width: 122% !important;
        float: right !important;
        margin-right: 0% !important;
    } 
    .modal-content_publisher
    {
        margin-right: 0% !important;  
    }
    #editButton_pub
    {
        margin-Left: 14% !important;
    }
    #createButton_pub
    {
        margin-Left: 15% !important;
    }
    .RecordsPublisher
    {
        width: 15% !important;
    }
}
/*Chaitanya-task-4187-publisher side-My reports- Report title not showing full name on report list page*/
@media only screen and (min-width: 1440px)
{
    .reportTypeMyreportPublisher
    {
        width: 85% !important;
        float: right;
        margin-right: 8% !important;
    }
    #createButton_pub
    {
        margin-Left: 15% !important;
    }
    .RecordsPublisher
    {    
        width: 11% !important;
    }
}
/*4314-R-29-Prod issue-My Report-create new report-cancel button-Publisher-(Removed add.button)*/
@media only screen and (min-width: 320px) and (max-width:375px)
{
    .modal-content_publisher {
        margin-left: -6% !important;
        float: left !important;
        width: 103% !important;
    }
}
@media only screen and (min-width: 375px) and (max-width:425px)
{
    .modal-content_publisher {
        float: left !important;
        margin-left: 7% !important;
        width: 86% !important;
    }
}
@media only screen and (min-width: 425px) and (max-width:768px)
{
    .modal-content_publisher {
        float: left !important;
        margin-left: 15% !important;
        width: 75% !important;
    }
}
@media only screen and (min-width: 768px) and (max-width:768px)
{
    .modal-content_publisher {
        float: left !important;
        margin-left: 0% !important;
        width: 101% !important;
    }
}