/* Copyright(c) 2021 APSS Media Pvt. Ltd.
*  All Rights Reserved
*
/*
@author:Sandeep Dhawale/Sanjana Godbole
@Creation Date:06-05-2021
@Description:css file for addUserPublisher Page/css code changes
*/
@media only screen and (min-width: 0px) and (max-width: 320px) {
  /*Sanjana-task-4118-added below css as per requirement*/
  #PubuserName {
    /* width:200% !important */
    /*sunita-task-3782-added below css as per requirement*/
    width: 100% !important;
    height: 70% !important;
  }
  /*sunita-task-3782-added below css as per requirement*/
  /*Sanjana-task-4118-added below css as per requirement*/
  .Proleinputbox {
    padding-top: 18px !important;
  }
  /*Sanjana-task-4118-added below css as per requirement*/
  .cardBodyClass.pubcardBodyClass {
    width: 294px !important;
    padding: 12px !important;
    margin: -2px !important;
    height: 385px !important;
  }
  .btn.buttonSave {
    margin-top: auto !important;
  }
}
/*sunita-task-3782-added below css as per requirement*/
@media only screen and (min-width: 321px) and (max-width: 375px) {
}
@media screen and (min-width: 375px) {
  /*Sanjana-task-4118-added below css as per requirement*/
  .cardBodyClass {
    width: 357px !important;
    padding: 12px !important;
    margin: -2px !important;
    height: 375px !important;
  }
  .btn.buttonSave {
    margin-top: auto !important;
  }
  #PubuserName {
    width: 100% !important;
    height: 70% !important;
  }
  .Proleinputbox {
    padding-top: 18px !important;
  }
}
/*sunita-task-3782-added below css as per requirement*/
@media only screen and (min-width: 376px) and (max-width: 425px) {
  /*Sanjana-task-4118-added below css as per requirement*/
  .roleinputbox {
    padding-top: 12px !important;
  }
  #userName {
    height: 67% !important;
  }
  .cardBodyClass {
    width: 294px !important;
    padding: 12px !important;
    margin: -2px !important;
    height: 375px !important;
  }
  .btn.buttonSave {
    margin-top: auto !important;
  }
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
  /*Sanjana-task-4118-added below css as per requirement*/
  #PubuserName {
    width: 100% !important;
    height: 60% !important;
  }
  .Proleinputbox {
    padding-top: 0px !important;
  }
  .cardBodyClass {
    width: 750px !important;
    padding: 12px !important;
    margin: -2px !important;
    height: 244px !important;
  }
  .btn.buttonSave {
    margin-top: auto !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  /*Sanjana-task-4118-added below css as per requirement*/
  #PubuserName {
    width: 60% !important;
    height: 60% !important;
  }
  .Proleinputbox {
    padding-top: 0px !important;
  }
  .cardBodyClass {
    width: 1000px !important;
    padding: 12px !important;
    margin: -2px !important;
    height: 244px !important;
  }
  .btn.buttonSave {
    margin-top: auto !important;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  /*Sanjana-task-4118-added below css as per requirement*/
  #PubuserName {
    width: 60% !important;
    height: 60% !important;
  }
  .Proleinputbox {
    padding-top: 0px !important;
  }
  .cardBodyClass {
    width: 100% !important;
    padding: 12px !important;
    margin: -2px !important;
    height: 219px !important;
  }
  .btn.buttonSave {
    margin-top: auto !important;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 2560px) {
  /*Sanjana-task-4118-added below css as per requirement*/
  #PubuserName {
    width: 60% !important;
    height: 60% !important;
  }
  .Proleinputbox {
    padding-top: 0px !important;
  }
  .cardBodyClass {
    width: 100% !important;
    padding: 12px !important;
    margin: -2px !important;
    height: 219px !important;
  }
  .btn.buttonSave {
    margin-top: auto !important;
  }
}
#loader_report1 {
  position: absolute;
  width: 25%;
  height: 19vh;
  background: white url("/../new_loader.gif") no-repeat center;
  z-index: 999;
  margin-left: 500px;
  margin-top: 70px;
}
/*Chaitanya-4286-R-28.9- Prod issue-Publisher side-setting-add user*/
@media only screen and (min-width: 320px) and (max-width: 375px) {
  .cardBodyClass.pubcardBodyClass {
    height: auto !important;
    padding-bottom: 40px !important;
  }
}
/*Chaitanya-4286-R-28.9- Prod issue-Publisher side-setting-add user*/
@media only screen and (min-width: 375px) and (max-width: 425px) {
  .cardBodyClass.pubcardBodyClass {
    padding-bottom: 40px !important;
  }
}
/*Chaitanya-4286-R-28.9- Prod issue-Publisher side-setting-add user*/
@media only screen and (min-width: 425px) and (max-width: 768px) {
  .cardBodyClass.pubcardBodyClass {
    padding-bottom: 40px !important;
  }
}
/*Chaitanya-4286-R-28.9- Prod issue-Publisher side-setting-add user*/
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .cardBodyClass.pubcardBodyClass {
    padding-bottom: 40px !important;
  }
}
/*Chaitanya-4286-R-28.9- Prod issue-Publisher side-setting-add user*/
@media only screen and (min-width: 1441px) {
  .cardBodyClass.pubcardBodyClass {
    padding-bottom: 40px !important;
  }
}
