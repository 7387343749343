/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Godbole
 *@fileName : agencyCompanyInformationEdit.css
 *Desc: css code changes
 */

/*Akash-Task- 4126
*/
@media only screen and (max-width: 425px) and (min-width: 320px) {
  #file {
    margin-left: 0px !important;
  }
  #logoMsg {
    padding-left: 0px !important;
    padding-top: 30px !important;
  }
  #invalid_file {
    line-height: 15px !important;
    padding-left: 0px !important;
    margin-top: 20px !important;
  }
  #upload_logo {
    padding-left: 0px !important;
    text-justify: auto;
  }
  #succ_Msg {
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
  #adj_file {
    margin-left: 30px !important;
  }

  .picky__input {
    width: 100% !important;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  #file {
    margin-left: 110px !important;
  }
  #logoMsg {
    padding-left: 165px !important;
  }
  #invalid_file {
    line-height: 15px !important;
    padding-left: 125px !important;
  }
  #adj_file {
    margin-left: 233px !important;
  }
  #upload_logo {
    padding-left: 130px !important;
  }
  #succ_Msg {
    padding-left: 100px !important;
  }
  .picky__input {
    width: 100% !important;
  }
}

/* kiran- bug 4201- make captcha resposive */
@media screen and (min-width: 320px) {
  .captcha_Agency {
    margin-left: -37px !important;
    margin-top: 30px !important;
    width: 100% !important;
  }
}

@media screen and (min-width: 375px) {
  .captcha_Agency {
    margin-left: -17px !important;
    margin-top: 30px !important;
    width: 80% !important;
  }
}

@media screen and (min-width: 425px) {
  .captcha_Agency {
    margin-left: 14px !important;
    margin-top: 30px !important;
  }
}

@media screen and (min-width: 1024px) {
  .captcha_Agency {
    margin-left: 31% !important;
    margin-top: 30px !important;
  }
}
