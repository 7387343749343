/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : advertiserLeadTracking.css
 *Desc: CSS code changes
 */
 
 @media only screen and (min-width: 320px) and (max-width: 425px){
   
        #gnrt-btn{
            padding-left:40px!important;
        }
        #manage_popup_cont{
            width: 300px !important;
            float: right!important;
            display: grid!important;
            grid-template-columns: auto!important;
         overflow-x: scroll!important;
        }
    }    
    @media only screen and (min-width: 768px) and (max-width: 768px){
      
                    #gnrt-btn{
                       padding-left:45px!important;
                    }
                      
    }
    @media only screen and (min-width: 1024px) and (max-width: 1024px){           
                #gnrt-btn{
                    padding-left:115px!important;
                }
              #for_res{
                  width: 130px !important;
              }
    }    
    @media only screen and (min-width:320px) and (max-width:320px){
      
        #selectMr{
            width: 195px!important;
         
        }
        #input_Mr{
            width:195px!important;
        }
    }
    .drpdisable_report{
        opacity: 0.5!important;
    }
    .btnMr {
        border: 2px solid #193D8F;
        background-color:transparent;
        border-radius: 2px;
        color: #193D8F;
        padding: 2px 8px;
        font-size: 14px;
        cursor: pointer;
      }
      .infoMr {
        border-color:#193D8F;
        color:#193D8F;
      }  
    .infoMr:hover {
        background:#193D8F;
        color: white;
      } 
     #to_truncate{
        text-overflow: ellipsis!important;
        overflow: hidden !important;
        white-space: nowrap!important;  
        width:108px!important;       
     }
    #to_truncate1{
        text-overflow: ellipsis!important;
        overflow: hidden !important;
        white-space: nowrap!important;  
        width:120px!important;      
     }
     #loader{
        position: absolute;
        width: 25%;
        height: 25vh;
        background: white url('/../new_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 500px;
        margin-top: 90px;
    } 
    #lead_loader{
        position: absolute;
        width: 25%;
        height: 25vh;
        background: white url('/../new_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 500px;
        margin-top: 57px;
    } 
    #campaignLoader{
        position: absolute;
        width: 25%;
        height: 25vh;
        background: white url('/../Campaign_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 500px;
        margin-top: 54px;
    }
    #loader_report{
        position: absolute;
        width: 25%;
        height: 20vh;
        background: white url('/../new_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 500px;
        margin-top: 80px;
    } 
    #loader_report1{
        position: absolute;
        width: 25%;
        height: 19vh;
        background: white url('/../new_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 500px;
        margin-top: 70px;
    } 
    #loader_reportadv
    {
        position: absolute;
        width: 25%;
        height: 19vh;
        background: white url('/../new_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 500px;
        margin-top: 70px;
    }
     #gen_loader{
        position: absolute;
        width: 25%;
        height: 25vh;
        background: white url('/../generate_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 500px;
     margin-top: 90px;
    }
     #for_popUp_generate1{
      
        color:#F28C0F;
        font-weight:500;
        font-size:17px;
     }
     #for_popUp_generate{
        color:#056FB9;
        font-weight:500;
        font-size:17px;
     }
     #success_popUp{
        color:#14254A;
        font-weight:500;
        font-size:17px;
     }
      @media only screen and (min-width: 320px) and (max-width: 375px){
        #gen_loader, #loader,#campaignLoader,#lead_loader{
            margin-top: 117px !important;
            width: 70% !important;
            margin-left: 30px !important;
        }
        #loader_report{
            margin-top: 80px !important;
            width: 70% !important;
            margin-left: 30px !important;
        }       
        #loader_report1{
            margin-top: 80px !important;
            width: 70% !important;
            margin-left: 30px !important;
            height: 20vh!important;
        }
        #loader_reportadv
        {
            margin-top: 80px !important;
            width: 70% !important;
            margin-left: 30px !important;
            height: 20vh!important;
        }
     }
     @media only screen and (min-width: 376px) and (max-width: 425px){
        #gen_loader,#loader,#campaignLoader, #lead_loader{
            margin-top: 110px !important;
            width: 70% !important;
            margin-left: 50px !important;
        }
        #loader_report{
            margin-top: 80px !important;
            width: 70% !important;
            margin-left: 50px !important;
        }
        #loader_report1{
            margin-top: 80px !important;
            width: 70% !important;
            margin-left: 50px !important;
            height: 20vh!important;
        }     
        #loader_reportadv
        {
            margin-top: 80px !important;
            width: 70% !important;
            margin-left: 50px !important;
            height: 20vh!important;
        }      
    }
    @media only screen and (min-width: 426px) and (max-width: 768px){
        #gen_loader,#loader,#campaignLoader,#lead_loader{
            margin-top: 110px !important;
            width: 40% !important;
            margin-left: 220px !important;
        }
        #loader_report{
            width: 40% !important;
            margin-left: 220px !important;
        }
        #loader_report1{
            width: 40% !important;
            margin-left: 220px !important;
            height: 20vh!important;
        }    
        #loader_reportadv
        {
            width: 40% !important;
            margin-left: 220px !important;
            height: 20vh!important;          
        }
    }
    @media only screen and (min-width: 769px) and (max-width: 1024px){
        #gen_loader,#loader,#campaignLoader,#lead_loader{
            margin-top: 110px !important;
            margin-left: 350px !important;
        }
        #loader_report{
            margin-left: 350px !important;
        }
        #loader_report1{
            margin-left: 350px !important;
            height: 20vh!important;
                  }
        #loader_reportadv
        {
            margin-left: 350px !important;
            height: 20vh!important;
          
        }   
    }
    @media only screen and (min-width: 1025px) and (max-width: 1440px){
        #gen_loader,#loader,#loader_report,#campaignLoader, #lead_loader{
            margin-left: 500px !important;
        }
       #loader_report1{
            margin-left: 500px !important;
            height: 20vh!important;

        }
        #loader_reportadv
        {
            margin-left: 500px !important;
            height: 20vh!important;
        }
    }
    @media only screen and (min-width: 1441px){
        #gen_loader,#loader,#loader_report,#campaignLoader,#lead_loader{
            margin-left: 1000px !important;
        }
        #loader_report1{
            margin-left: 1000px !important;
            height: 20vh!important;
        }
        #loader_reportadv
        {
            margin-left: 1000px !important;
            height: 20vh!important;
        }
    }
    /*Chaitanya-4181-Advertiser- My report- advertiserLeadTrakingReport*/
    @media only screen and (min-width:320px) and (max-width:375px)
    {
        #leadTracking_leadStatus
        {
            margin-top: 15px!important;
            width: 64% !important;
        }
        #manageColumn_1
        {
            padding-top: 15% !important;
            padding-right: 0% !important;
            width: 100% ! important;
        }
        #manage_popup_cont_Adv 
        {
            width: 96% !important;
            float: left!important;
            margin-left: 5% !important;
            grid-template-columns: auto!important;
            overflow-x: scroll!important; 
        }
        #manageColumn_body
        {
            width: 100% !important;
            display: flex !important;
        }
        #manageColumn_Cancle
        {
            width: 31% !important;
        }
        #manageColumn_Save
        {
            width: 51% !important;
        }
    }
    /*Chaitanya-4181-Advertiser- My report- advertiserLeadTrakingReport*/
    @media only screen and (min-width: 375px)and (max-width: 425px)
    {
        #leadTracking_agency
        {
            margin-top: 10px !important;
            width: 73% !important;  
        }
        #leadTracking_campusStatus
        {
            margin-top: 10px !important;
            width: 73% !important; 
        }
        #leadTracking_campaign
        {
            margin-top: 10px !important;
            width: 73% !important; 
        }
        #leadTracking_Timeline
        {
            margin-top: 10px !important;
            width: 73% !important; 
        }
        #leadTracking_leadStatus
        {
            margin-top: 10px!important;
            width: 64% !important;
        }
        #manageColumn_1
        {
            padding-top: 13% !important;
            padding-right: 0% !important;
            width: 100% ! important;
        }
        #manage_popup_cont_Adv 
        {
            width: 97% !important;
            float: left!important;
            margin-left: 4% !important;
            display: flex!important;
            grid-template-columns: auto!important;
            overflow-x: scroll!important; 
        }
        #manageColumn_body
        {
            width: 100% !important;
            display: flex !important;
        }
        #manageColumn_Cancle
        {
            width: 40% !important;
        }
    }
    /*Chaitanya-4181-Advertiser- My report- advertiserLeadTrakingReport*/
    @media only screen and (min-width: 425px)and (max-width: 767px)
    {
        #leadTracking_agency
        {
            margin-top: 10px !important;
            width: 64% !important;  
        }
        #leadTracking_campusStatus
        {
            margin-top: 10px !important;
            width: 64% !important;
        }
        #leadTracking_campaign
        {
            margin-top: 10px !important;
            width: 64% !important;
        }
        #leadTracking_Timeline
        {
            margin-top: 10px !important;
            width: 64% !important;
        }
        #leadTracking_leadStatus
        {
            margin-top: 10px!important;
            width: 64% !important;
        }
        #manageColumn_1
        {
            padding-top: 12% !important;
            padding-right: 0% !important;
            width: 100% ! important;
        }
        #manage_popup_cont_Adv 
        {
            width: 90% !important;
            float: right!important;
            margin-right: 2% !important;
            display: flex!important;
            grid-template-columns: auto!important;
            overflow-x: scroll!important; 
        }
        #manageColumn_body
        {
            width: 100% !important;
            display: flex !important;
        }
    } 
    /*Chaitanya-4181-Advertiser- My report- advertiserLeadTrakingReport*/ 
    @media only screen and (min-width: 768px)and (max-width: 1023px)
    {
        #manageColumn_1
        {
            padding-top: 7% !important;
            padding-right: 0% !important;
            width: 66% ! important;
        }
        #manage_popup_cont_Adv 
        {
            width: 77% !important;
            float: right!important;
            margin-right: 37% !important;
            display: flex!important;
            grid-template-columns: auto!important;
            overflow-x: scroll!important; 
        }
        #manageColumn_body
        {
            width: 100% !important;
            display: flex !important;
        }
    }
    /*Chaitanya-4181-Advertiser- My report- advertiserLeadTrakingReport*/
    @media only screen and (min-width: 1024px)and (max-width: 1440px)
    {
        #manageColumn_1
        {
            padding-top: 5% !important;
            padding-right: 0% !important;
            width: 66% ! important;
        }
        #manage_popup_cont_Adv 
        {
            width: 77% !important;
            float: right!important;
            margin-right: 63% !important;
            display: flex!important;
            grid-template-columns: auto!important;
            overflow-x: scroll!important; 
        }
        #manageColumn_body
        {
            width: 100% !important;
            display: flex !important;
        }
    }
    /*Chaitanya-4181-Advertiser- My report- advertiserLeadTrakingReport*/
    @media only screen and (min-width: 1440px) and (max-width :1440px)
    {
        #manageColumn_1
        {
            padding-top: 3% !important;
            padding-right: 0% !important;
            width: 66% ! important;
        }
        #manage_popup_cont_Adv 
        {
            width: 77% !important;
            float: right!important;
            margin-right: 100% !important;
            display: flex!important;
            grid-template-columns: auto!important;
            overflow-x: scroll!important; 
        }
        #manageColumn_body
        {
            width: 100% !important;
            display: flex !important;
        }
    }