/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Godbole
 *@fileName :publisherInternalReview.css 
 *Desc: css code changes
 */

/* //shivani-task 3206 - as per requirements added css for each device size */
@media only screen and (max-width: 320px) {
  #searchLeadId {
    margin-left: 20px !important;
    width: 245px !important;
  }
  #btm-btn {
    margin-left: 115px !important;
  }
  #for-sm-mb {
    margin-top: 500px !important;
  }
  #Camp_revw_lab {
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 375px) and (max-width: 375px) {
  #searchLeadId {
    margin-left: 50px !important;
    /* width: 285px !important; */
  }
  #btm-btn {
    margin-left: 60px !important;
  }
  #for-sm-mb {
    margin-top: 500px !important;
  }
  #Camp_revw_lab {
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 425px) and (max-width: 425px) {
  #searchLeadId {
    margin-left: 70px !important;
    /* width: 300px !important; */
  }

  #btm-btn {
    margin-left: 10px !important;
  }
  #for-sm-mb {
    margin-top: 500px !important;
  }
  #Camp_revw_lab {
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 768px) {
  #searchLeadId {
    margin-left: 5px !important;
    width: 165px !important;
  }
  #btm-btn {
    margin-left: 444px !important;
  }
  #Camp_revw_lab {
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1024px) {
  #searchLeadId {
    margin-left: 10px !important;
    width: 223px !important;
  }
  #btm-btn {
    margin-left: 500px !important;
  }
  #Camp_revw_lab {
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 1440px) {
  #searchLeadId {
    margin-left: 22px !important;
    width: 280px !important;
  }
}
@media only screen and (max-width: 1024px) {
  #btn29 {
    margin-left: -15px !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
  #btn29 {
    margin-left: -147px !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 425px) {
  #btn_info29 {
    margin-left: 15px !important;
    margin-top: 7px !important;
  }
}
/* //shivani-task 3206- to truncate the campaign name */
/* kiran-4384-added visible value to overflow for responsiveness */
.campclickelli {
  overflow: visible !important;
  max-width: 140px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: inline-block !important;
  margin: 0 !important;
  vertical-align: top !important;
}
/* Sandeep-task-3394-added code for loader */
#loader_review {
  position: absolute;
  width: 25%;
  height: 20vh !important;
  /* sandeep-task-3566-added code for loader issue */
  background: white url("/../new_loader.gif") no-repeat center;
  z-index: 999;
  margin-left: 300px;
  margin-top: 70px !important;
  /* sandeep-task-3566-added code for loader issue */
}

/*Chaitanya-4246-R28.9-Prod issue-Publisher Login-Lead review issue*/
@media only screen and (min-width: 320px) and (max-width: 375px) 
{
  .PubReviewLead{
    margin-left: 20px !important
  }
  .RecordsPerPagePubReviewLead
  {
    width: 57%;
  }
}
/*Chaitanya-4246-R28.9-Prod issue-Publisher Login-Lead review issue*/
@media only screen and (min-width: 375px) and (max-width: 425px)
{
  .PubReviewLead{
    margin-left: 20px !important
  }
  .RecordsPerPagePubReviewLead
  {
    width: 47%;
  }
}
/*Chaitanya-4246-R28.9-Prod issue-Publisher Login-Lead review issue*/
@media only screen and (min-width: 425px) and (max-width: 767px)
{
  .PubReviewLead{
    margin-left: 20px !important
  }
  .RecordsPerPagePubReviewLead
  {
    width: 40%;
  }
}
/*Chaitanya-4246-R28.9-Prod issue-Publisher Login-Lead review issue*/
@media only screen and (min-width: 320px) and (max-width: 320px)
{
  #for-sm-mb {
    margin-top: 0px !important;
  } 
}
/*Chaitanya-4246-R28.9-Prod issue-Publisher Login-Lead review issue*/
@media only screen and (min-width: 321px) and (max-width: 424px)
{
  .specificationCamp{
    margin-bottom: 20px 
  }
}