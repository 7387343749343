/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Godbole
 *@fileName : publisherUnreadAlerts.css
 *Desc: css code changes
 */

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .first-div {
    width: 864px !important;
    margin-bottom: 15px !important;
    font-size: 20px !important;
  }
  .second-div {
    font-size: 20px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .first-div {
    width: 300px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #search {
    margin-left: 130px !important;
  }
}
