/* Copyright(c) 2020 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Sanjana Godbole/kiran mate
*@fileName : editCampaign.css
 *Desc: css code changes
*/
@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}
