/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : agencyMyReports.css
 *Desc: CSS code changes
 */
 
  /* Rutuja Kale-task-3721-below css are used for displaying correct form of function (according to create report and view report )   */
 .picky__placeholder {
    color: #000000 !important;
}
.picky__placeholder {
    color: #c4baba !important;
}
/* .picky__input{
    color: rgb(179 179 179) !important;
    background-color:#fafafa
} */
 /* sunita-task-3406-below css are used for setting responsiveness and aligning the myReport page(advertiser tab) and agencyAdvertiserDeliverytracking page   */
 @media only screen and (min-width: 320px) and (max-width: 425px){
    #searchID4th{
    margin-top: 10px!important;
    margin-left: -23px!important;
    }
    #searchID4thB{
        margin-top: 10px!important;
        margin-left: -103px!important;
        }
    #searchID4{
        margin-left: 25px !important;
        }
    #create_btn_report{
        margin-left: 0px!important;
        }
        .head_crediv{
      font-size:15px!important;
        }
        #fr_md0{
            margin-left: 0px!important;
            margin-inline-end: 24px;
        }
        #fr_mdl{    
            margin-left: 0px!important;
        }
        #fr_md2{
            margin-left: 0px!important;
            margin-inline-end: 35px;
        }
       #selectreport{
            margin-left: 0px!important;
        }   
        #gnrt-btn{
            padding-left:40px!important;
        }
        #manage_popup_cont{
            width: 300px !important;
            float: right!important;
            display: grid!important;
            grid-template-columns: auto!important;
         overflow-x: scroll!important;
        }
    }
    /* Chaitanya-task-4144-RESPONSIVE--R28.7--Ref(3873)--agency side---My report--responsiveness issue-agencyPublisherDeliverytracking */
    @media only screen and (min-width: 320px) and (max-width: 376px)
    {       
        #fr_md0{
            margin-left: 0px!important;
            margin-inline-end: 79px;
        }
        #fr_mdl{    
            margin-left: 0px!important;
            margin-inline-end: 37px;
        }
        #fr_md2{
            margin-left: 0px!important;
            margin-inline-end: 90px;
        }
    }
    /* Chaitanya-task-4144-RESPONSIVE--R28.7--Ref(3873)--agency side---My report--responsiveness issue-agencyPublisherDeliverytracking */
    @media only screen and (min-width: 376px) and (max-width: 427px)
    {       
        #fr_md0{
            margin-left: 0px!important;
            margin-inline-end: 130px;
        }
        #fr_mdl{    
            margin-left: 0px!important;
            margin-inline-end: 87px;
        }
        #fr_md2{
            margin-left: 0px!important;
            margin-inline-end: 141px;
        }
    }
/* Chaitanya-task-4144-RESPONSIVE--R28.7--Ref(3873)--agency side---My report--responsiveness issue-agencyAdvertiserDeliverytracking */
    @media only screen and (min-width: 320px) and (max-width: 426px)
    {       
        #Ad_1{
            margin-left: 0px!important;
            margin-inline-end: 128px;
        }
        #CS_1{    
            margin-top: 10px;
            margin-left: 0px!important;
            margin-inline-end: 91px;
        }
        #TL_1{
            margin-top: 10px;
            margin-left: 0px!important;
            margin-inline-end: 145px;
        }
        /* rutuja task-4654 */
        #AD_ALTD1
        {
            width: 370px;
            margin-bottom: 16px;
        }
        #search-bar11
        {
            width:400px;
        }
      }
    /*chaitanya-task-4144-Advertiser Lead tracking report changed css as per requirement*/
      @media only screen and (min-width: 374px) and (max-width: 426px)
    {
    .generateButton2 
        {
            text-align: left !important;
            margin-left: 26px  !important;  
        }   
    }
    /*chaitanya-task-4144-Publisher Lead tracking report changed css as per requirement*/
    @media only screen and (min-width: 374px) and (max-width: 376px)   
    {
    #PB_1
    {
        width: 258px;
        margin-bottom: 16px;
    }
    #CP_1
    {
        width: 258px;
        margin-bottom: 6px;
        margin-top: 13px;
        margin-left: -21px;
    }
    #TM_1
    {
        width: 258px;
        margin-bottom: 6px;
        margin-top: 13px;
        margin-left: 0px;
    }
    }
    /*chaitanya-task-4144-Publisher Lead tracking report changed css as per requirement*/
    @media only screen and (min-width: 424px) and (max-width: 426px)   
    {
    #PB_1
    {
        width: 258px;
        margin-bottom: 16px;
    }
    #CP_1
    {
        width: 258px;
        margin-bottom: 6px;
        margin-top: 13px;
        margin-left: -21px;
    }
    #TM_1
    {
        width: 258px;
        margin-bottom: 6px;
        margin-top: 13px;
        margin-left: 0px;
    }
 }
 /*chaitanya-task-4144-Advertiser Lead tracking report changed css as per requirement*/
 @media only screen and (min-width: 374px) and (max-width: 376px)   
 {
    #AD_ALTD
    {
        width: 258px;
        margin-bottom: 16px;
    }
    /* rutuja task-4654 */
    #AD_ALTD1
    {
        width: 370px;
        margin-bottom: 16px;
    }
    #CP_ALTD
    {
        width: 258px;
        margin-bottom: 6px;
        margin-top: 13px;
        margin-left: -7px;
    }
    #TM_ALTD
    {
        width: 258px;
        margin-bottom: 6px;
        margin-top: 13px;
        margin-left: 0px;
    }
 }
 /*chaitanya-task-4144-Advertiser Lead tracking report changed css as per requirement*/
 @media only screen and (min-width: 424px) and (max-width: 426px)   
 {
    #AD_ALTD
    {
        width: 258px;
        margin-bottom: 16px;
    }
    /* rutuja task-4654 */
    #AD_ALTD1
    {
        width: 370px;
        margin-bottom: 16px;
    }
    #CP_ALTD
    {
        width: 258px;
        margin-bottom: 6px;
        margin-top: 13px;
        margin-left: -7px;
    }
    #TM_ALTD
    {
        width: 258px;
        margin-bottom: 6px;
        margin-top: 13px;
        margin-left: 0px;
    }
 }
 @media only screen and (min-width: 767px) and (max-width: 769px)
 {
    #searchID4thB
    {                                                     
            margin-top: 0px!important;
            margin-left: -15px!important;
    }  
 }
/* Chaitanya-task-4144-RESPONSIVE--R28.7--Ref(3873)--agency side---My report--responsiveness issue   */
    @media only screen and (min-width: 425px) and (max-width: 767px)
    {
        #searchID4th{
        margin-top: 10px!important;
        margin-left: -23px!important;
        }
        #searchID4{
            margin-left: 25px !important;
            }
            
        #searchID4thB
        {                                                     
                margin-top: 10px!important;
                margin-left: -103px!important;
        }
        #create_btn_report{
            margin-left: 0px!important;
            }
            .head_crediv{
          font-size:15px!important;
            }
            #fr_mdl{
                margin-left: 0px!important;
        
            }
           #selectreport{
                margin-top: 5px;
                margin-left: 0px!important;
                padding-right: 0px;
            }   
            #gnrt-btn{
                padding-left:40px!important;
            }
            #manage_popup_cont{
                width: 300px !important;
                float: right!important;
                display: grid!important;
                grid-template-columns: auto!important;
             overflow-x: scroll!important;
            }  
            .picky__input  
            {
                padding-left: 8px;
                width: 100% !important;
            }      
        }

    @media only screen and (min-width: 768px) and (max-width: 768px){
        #searchID4th{
            margin-top:0px!important;
            }
            /* #selectreport{
                margin-left: 27px!important;
            } */
            #searchID4{
                width: 180px !important;
                }
                #create_btn_report{
                    margin-left: 0px!important;
                    }
                    #create_btnMr{
                        width: 115px !important;
                        height: 30px !important;
                        margin-top: -1px!important;
                    }
                    #fr_mdl{
                        margin-left: 0px!important;
                
                    } 
                    #gnrt-btn{
                       padding-left:45px!important;
                    }   
                    /* rutuja task-4654 */
                    #search-bar11
                        {
                            width:300px!important;
                        
                        }         
                        #AD_ALTD1
    {
        width: 57%!important;
    }     
    } 
    
    @media only screen and (min-width: 1024px) and (max-width: 1024px){
        #searchID4{
            margin-left:44px!important;
        width: 179px !important;
                }
            /* #selectreport{
                margin-bottom: -13!important;
                margin-left:-143px!important;
            } */
            #CampStatDrop{
                margin-left:2px!important;
                margin-inline-end: 17px;       
            }
            /* sunita-task-3406-added css for aligning the filters properly on tracking page */
            #CampStatDrop1  
            {
                margin-left:0px!important;
            }
            #create_btn_report{
                margin-left: -23px!important;
                }
                #create_btnMr{
                    width: 115px !important;
                    height: 30px !important;
                    margin-top: -1px!important;
                }
                #gnrt-btn{
                    padding-left:115px!important;
                }
              #for_res{
                width: 133px !important;
                padding-inline-start: 3px;
              }
    }  
    @media only screen and (min-width:320px) and (max-width:320px){
        #cancel-btnMr{
            margin-left: 0px!important;
        }
        #create_btnMr{
            width: 115px !important;
            height: 30px !important;
            margin-left: -20px!important;
            margin-top: 10px!important;
        }
        #selectMr{
            width: 195px!important;
        }
        #input_Mr{
            width:195px!important;
        }
        /* sunita-task-3406-added css for aligning the generate button properly on tracking page */
        .generateButton2  /*sunita-task-3484-changed classname as some css are reflecting*/  
        {
            text-align: left !important;
            margin-left: 25px  !important; /*sunita-task-3484- added css as per requirement*/
        }
        /* .form-control{
            width: 56% !important;
        }
        .picky__input {
            width: 100% !important;
        } */
    }
    .drpdisable_report{
       
        opacity: 0.5!important;
    }
    .btnMr {
        border: 2px solid #193D8F;
        background-color:transparent;
        border-radius: 2px;
        color: #193D8F;
        padding: 2px 8px;
        font-size: 14px;
        cursor: pointer;
      }  
      .infoMr {
        border-color:#193D8F;
        color:#193D8F;
      }      
    .infoMr:hover {
        background:#193D8F;
        color: white;
      }
      #to_truncate_new1{
        text-overflow: ellipsis!important;
        overflow: hidden !important;
        white-space: nowrap!important;  
        width:100%!important;
       /*chaitanya-4099-Report name displays full name in Report listing page*/
     } 
     #to_truncate{
        text-overflow: ellipsis!important;
        overflow: hidden !important;
        white-space: nowrap!important;  
        width:108px!important;     
     }
    #to_truncate1{
        text-overflow: ellipsis!important;
        overflow: hidden !important;
        white-space: nowrap!important;  
        width:120px!important;     
     }
     #to_truncate2{
        text-overflow: ellipsis!important;
        overflow: hidden !important;
        white-space: nowrap!important;  
        width:75px!important;      
     }
     #loader{
        position: absolute;
        width: 25%;
        height: 25vh;
        background: white url('/../new_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 500px;
        margin-top: 90px;
    } 
    #lead_loader{
        position: absolute;
        width: 25%;
        height: 25vh;
        background: white url('/../new_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 500px;
        margin-top: 57px;
    } 
/* shivani-task-3415-Report Engine- Publisher View- My Reports and Agency Lead Tracking Report- Front End*/
    #campaignLoader{
        position: absolute;
        width: 25%;
        height: 25vh;
        background: white url('/../Campaign_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 500px;
        margin-top: 54px;
    }
/* snehal-task-3357-Report Engine- Publisher View- My Reports and Agency Delivery Tracking Report- Front End(Added media queries) */
/* shivani-task-3429-Report Engine- Publisher View- My Reports and Agency Delivery Tracking Report- Front End(Added media queries)-(for loader_report1) */
/* shivani-task-3415-Report Engine- Publisher View- My Reports and Agency Lead Tracking Report- Front End(Added media queries)-(for loader_report1) */
/*sunita-task-3406-Report Engine- agency view advertiser tab- My Reports and advertiser Delivery Tracking Report- Front End(Added media queries)-(for loader_reportadv)*/
    #loader_report{
        position: absolute;
        width: 25%;
        height: 20vh;
        background: white url('/../new_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 500px;
        margin-top: 80px;
    } 
    #loader_report1{
        position: absolute;
        width: 25%;
        height: 19vh;
        background: white url('/../new_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 500px;
        margin-top: 70px;
    } 
    #loader_reportadv
    {
        position: absolute;
        width: 25%;
        height: 19vh;
        background: white url('/../new_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 500px;
        margin-top: 70px;
    }  
    #gen_loader{
        position: absolute;
        width: 25%;
        height: 25vh;
        background: white url('/../generate_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 500px;
     margin-top: 90px;
    }   
     #for_popUp_generate1{
      
        color:#F28C0F;
        font-weight:500;
        font-size:17px;
     }
     #for_popUp_generate{
        color:#056FB9;
        font-weight:500;
        font-size:17px;
     }
     #success_popUp{
        color:#14254A;
        font-weight:500;
        font-size:17px;
     }
     /* sunita-task-3579-added css for adjusting the column  */
         #to_truncatecolumnname1{
        text-overflow: ellipsis!important;
        overflow: hidden !important;
        white-space: nowrap!important;  
        width:120px!important; 
     }
     /* #to_truncatecolumnname2{
        text-overflow: ellipsis!important;
        overflow: hidden !important;
        white-space: nowrap!important;  
        width:75px!important;  
     } */
 /* snehal-task-3357-Report Engine- Publisher View- My Reports and Agency Delivery Tracking Report- Front End(Added media queries) */
/* shivani-task-3429-Report Engine- Publisher View- My Reports and Agency Delivery Tracking Report- Front End(Added media queries)-(for loader_report1) */
/* shivani-task-3415-Report Engine- Publisher View- My Reports and Agency Lead Tracking Report- Front End(Added media queries)-(for loader_report1) */
/*sunita-task-3406-Report Engine- agency view advertiser tab- My Reports and advertiser Delivery Tracking Report- Front End(Added media queries)-(for loader_reportadv)*/
     @media only screen and (min-width: 320px) and (max-width: 375px){
        #gen_loader, #loader,#campaignLoader,#lead_loader{
            margin-top: 117px !important;
            width: 70% !important;
            margin-left: 30px !important;
        }
        #loader_report{
            margin-top: 80px !important;
            width: 70% !important;
            margin-left: 30px !important;
        }        
        #loader_report1{
            margin-top: 80px !important;
            width: 70% !important;
            margin-left: 30px !important;
            height: 20vh!important;
        }
        #loader_reportadv
        {
            margin-top: 80px !important;
            width: 70% !important;
            margin-left: 30px !important;
            height: 20vh!important;
        }
        .generateButton2 /*sunita-task-3484-changed classname as some css are reflecting*/
        {
            text-align: left !important;
            margin-left: 26px  !important;  /*sunita-task-3484- added css as per requirement*/
        }
     }
     @media only screen and (min-width: 376px) and (max-width: 425px){
        #gen_loader,#loader,#campaignLoader, #lead_loader{
            margin-top: 110px !important;
            width: 70% !important;
            margin-left: 50px !important;
        }
        #loader_report{
            margin-top: 80px !important;
            width: 70% !important;
            margin-left: 50px !important;
        }
        #loader_report1{
            margin-top: 80px !important;
            width: 70% !important;
            margin-left: 50px !important;
            height: 20vh!important;
        }    
        #loader_reportadv
        {
            margin-top: 80px !important;
            width: 70% !important;
            margin-left: 50px !important;
            height: 20vh!important;
        }
        .generateButton2 /*sunita-task-3484-changed classname as some css are reflecting*/
        {
            text-align: left !important;
            margin-left: 25px  !important;  /*sunita-task-3484- added css as per requirement*/
        }
    }
    @media only screen and (min-width: 426px) and (max-width: 768px){
        #gen_loader,#loader,#campaignLoader,#lead_loader{
            margin-top: 110px !important;
            width: 40% !important;
            margin-left: 220px !important;
        }
        #loader_report{
            width: 40% !important;
            margin-left: 220px !important;
        }
        #loader_report1{
            width: 40% !important;
            margin-left: 220px !important;
            height: 20vh!important;
        }   
        #loader_reportadv
        {
            width: 40% !important;
            margin-left: 220px !important;
            height: 20vh!important;          
        }
    }
    @media only screen and (min-width: 769px) and (max-width: 1024px){
        #gen_loader,#loader,#campaignLoader,#lead_loader{
            margin-top: 110px !important;
            margin-left: 350px !important;
        }
        #loader_report{
            margin-left: 350px !important;
        }
        #loader_report1{
            margin-left: 350px !important;
            height: 20vh!important;
        }
        #loader_reportadv
        {
            margin-left: 350px !important;
            height: 20vh!important;   
        }
        .generateButton2 /*sunita-task-3484-changed classname as some css are reflecting*/
        {
            text-align: right !important;
            /* margin-left: 7px !important; */
        }
        /*chaitanya-task-4144-RESPONSIVE--R28.7--Ref(3873)--agency side---My report--responsiveness issue*/
        .picky__input 
        {
            padding-inline-start: 8px;
            height: 32px !important;
            width: 100% !important;
        }
    }
    @media only screen and (min-width: 1025px) and (max-width: 1440px){
        #gen_loader,#loader,#loader_report,#campaignLoader, #lead_loader{
            margin-left: 500px !important;
        }
       #loader_report1{
            margin-left: 500px !important;
            height: 20vh!important;
        }
        #loader_reportadv
        {
            margin-left: 500px !important;
            height: 20vh!important;
        }
        .generateButton2   /*sunita-task-3484-changed classname as some css are reflecting*/
        {
            text-align: right !important;
            /* margin-left: 7px !important; */
        }
    }
    @media only screen and (min-width: 1441px){
        #gen_loader,#loader,#loader_report,#campaignLoader,#lead_loader{
            margin-left: 1000px !important;
        }
        #loader_report1{
            margin-left: 1000px !important;
            height: 20vh!important;
        }
        #loader_reportadv
        {
            margin-left: 1000px !important;
            height: 20vh!important;
        }
    }
/* //shivani-3547-added color for checkbox labels */
/* .ant-checkbox-wrapper .checkbox_clr {
    color:#4F4F4F!important;
} */
/*sunita-task-3579-added css for industry column merged data*/
#to_truncate1_industry{
    text-overflow: ellipsis!important;
    overflow: hidden !important;
    white-space: nowrap!important;  
    width:80px!important;  
 }
 /*Chaitanya-task-4187-Agency side-My reports- Report title not showing full name on report list page*/
 @media only screen and (min-width: 320px) and (max-width: 375px)
 {
    .CreateReportAgency
    {
        margin-left: 2% !important;
        width: 113% !important;
    }
    .EditReportAgency
    {
        margin-left: 0% !important;
        width: 115% !important;
    }
    .selectreportAgencyPub
    {
        width: 100% !important;
    }
    .selectreportAgencyAdv
    {
        width: 100% !important; 
    }
    .searchbarAgency
    {
        width: 100% !important;
    }
    .searchbarAgencyAdv
    {
        width: 100% !important;
        margin-left: 36% !important;
    }
    .pacingAgency
    {
        width: 28% !important;
        float: right !important;
        margin-right: 65% !important;
    }
    #input_Ag
    {
        width: 102% !important;
        height: 0% !important;
        float: right !important;
        margin-right: 2% !important;
        padding-right: 0% !important;
    }
    #select_Ag
    {
        width: 103% !important;
        height: 0% !important;
        float: right !important;
        margin-right: 2% !important;
        padding-right: 0% !important;
    }
    #create_btnAgency
    {
        width: 45% !important;
        margin-left: -1rem!important;
        margin-top: -1%!important;
    }
    #Edit_btnAgency
    {
        width: 45% !important;
        margin-left: -1rem!important;
        margin-top: -1%!important;   
    }
 }
 /*Chaitanya-task-4187-Agency side-My reports- Report title not showing full name on report list page*/
 @media only screen and (min-width: 375px) and (max-width: 425px)
 {
    .CreateReportAgency
    {
        margin-left: 10% !important;
    }
    .EditReportAgency
    {
        margin-left: 10% !important;
    }
    .selectreportAgencyPub
    {
        width: 85% !important;
    }
    .selectreportAgencyAdv
    {
        width: 85% !important; 
    }
    .searchbarAgency
    {
        width: 85% !important;
    }
    .searchbarAgencyAdv
    {
        width: 85% !important;
        margin-left: 30% !important;
    }
    .pacingAgency
    {
        width: 24% !important;
        float: right !important;
        margin-right: 70% !important;
    }
    #input_Ag
    {
        width: 100% !important;
        height: 0% !important;
        float: right !important;
        margin-right: 4% !important;
        padding-right: 0% !important;
    }
    #select_Ag
    {
        width: 100% !important;
        height: 0% !important;
        float: right !important;
        margin-right: 4% !important;
        padding-right: 0% !important;
    }
 }
 /*Chaitanya-task-4187-Agency side-My reports- Report title not showing full name on report list page*/
 @media only screen and (min-width: 425px) and (max-width: 768px)
 {
    .CreateReportAgency
    {
        margin-left: 18% !important;
        width: 88% !important;
    }
    .EditReportAgency
    {
        margin-left: 18% !important;
        width: 88% !important;
    }
    .selectreportAgencyPub
    {
        width: 80% !important;
    }
    .selectreportAgencyAdv
    {
        width: 80% !important; 
    }
    .searchbarAgency
    {
        width: 80% !important;
    }
    .searchbarAgencyAdv
    {
        width: 80% !important;
        margin-left: 26% !important;
    }
    .pacingAgency
    {
        width: 20% !important;
        float: right !important;
        margin-right: 76% !important;
    }
    #input_Ag
    {
        width: 100% !important;
        height: 0% !important;
        float: right !important;
        margin-right: 4% !important;
        padding-right: 0% !important;
    }
    #select_Ag
    {
        width: 100% !important;
        height: 0% !important;
        float: right !important;
        margin-right: 4% !important;
        padding-right: 0% !important;
    }
 }
 /*Chaitanya-task-4187-Agency side-My reports- Report title not showing full name on report list page*/
 @media only screen and (min-width: 768px) and (max-width: 1023px)
 {
    .MyReportsAgencyAdv
    {
        margin-left: 30% ;
    }
    .createReportAgencyAdv
    {
        float:Left !important;
    }
    .CreateReportAgency
    {
        margin-left: 0% !important;
        width: 100% !important;
    }
    .EditReportAgency
    {
        margin-left: 0% !important;
        width: 100% !important;
    }
    .selectreportAgencyPub
    {
        width: 176% !important;
        margin-left: 8px!important;
    }
    .selectreportAgencyAdv
    {
        width: 282% !important;
        padding-left: 0% !important;
        margin-left: -30% !important;
    }
    .searchbarAgency
    {
        width: 80% !important;
    }
    .searchbarAgencyAdv
    {
        width: 100% !important;
        float: left;
        margin-left: 85% !important;
    }
    .pacingAgency
    {
        width: 26% !important;
        float: right !important;
        margin-right: 33% !important;
    }
    #input_Ag
    {
        width: 99% !important;
        height: 0% !important;
        float: right !important;
        margin-right: 5% !important;
        padding-right: 0% !important;
    }
    #select_Ag
    {
        width: 99% !important;
        height: 0% !important;
        float: right !important;
        margin-right: 5% !important;
        padding-right: 0% !important;
    }
 }
 /*Chaitanya-task-4187-Agency side-My reports- Report title not showing full name on report list page*/
 @media only screen and (min-width: 1024px) and (max-width: 1440px)
 {  
    .selectreportAgencyPub
    {
        width: 202% !important;
        float: right !important;
        margin-right: 12% !important;
    }
    .searchbarAgency
    {
        width: 70% !important;
        float: left;
        margin-left: 63% !important;
    }
    .selectreportAgencyAdv
    {
        width: 197% !important;
        padding-left: 0% !important;
        margin-left: -30% !important;
    }
    .searchbarAgencyAdv
    {
        width: 70% !important;
        float: left;
        margin-left: 63% !important;
    }
    #input_Ag
    {
        width: 99% !important;
        height: 0% !important;
        float: right !important;
        margin-right: 4% !important;
        padding-right: 0% !important;
    }
    #select_Ag
    {
        width: 99% !important;
        height: 0% !important;
        float: right !important;
        margin-right: 4% !important;
        padding-right: 0% !important;
    }
 }
 /*Chaitanya-task-4187-Agency side-My reports- Report title not showing full name on report list page*/
 @media only screen and (min-width: 1440px)
 {
    .selectreportAgencyPub
    {
        width: 135% !important;
        float: right !important;
        margin-right: 12% !important;
    }
    .searchbarAgency
    {
        width: 75% !important;
        float: left;
        margin-left: 23% !important;
    }
    .selectreportAgencyAdv
    {
        width: 132% !important;
        padding-left: 0% !important;
        float: left;
        margin-left: 14% !important;
    }
    .searchbarAgencyAdv
    {
        width: 70% !important;
        float: left;
        margin-left: 50% !important;
    }
    #input_Ag
    {
        width: 99% !important;
        height: 0% !important;
        float: right !important;
        margin-right: 4% !important;
        padding-right: 0% !important;
    }
    #select_Ag
    {
        width: 99% !important;
        height: 0% !important;
        float: right !important;
        margin-right: 4% !important;
        padding-right: 0% !important;
    }     
 }
/*4314-R-29-Prod issue-My Report-create new report-cancel button-Agency*/
@media only screen and (min-width: 320px) and (max-width: 375px)
 {
    .CreateReportAgency {
        margin-left: 0% !important;
        width: 99% !important;
    }
    .EditReportAgency {
        margin-left: 0% !important;
        width: 99% !important;
    }
 }
 @media only screen and (min-width: 375px) and (max-width: 425px)
 {
    .CreateReportAgency {
        margin-left: 10% !important;
        width: 85% !important;
    }
    .EditReportAgency {
        margin-left: 10% !important;
        width: 85% !important;
    }
 }
 @media only screen and (min-width: 425px) and (max-width: 768px)
 {
    .CreateReportAgency {
        margin-left: 15% !important;
        width: 75% !important;
    }
    .EditReportAgency {
        margin-left: 15% !important;
        width: 75% !important;
    }
 }
 @media only screen and (min-width: 768px) and (max-width: 768px)
 {
    .CreateReportAgency {
        margin-left: 0% !important;
        width: 100% !important;
    }
    .EditReportAgency {
        margin-left: 0% !important;
        width: 100% !important;
    }  
 }