
/* Copyright(c) 2022 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*
@author Chaitanya shinde
@Filename:publisherCreativeUploads.css
@Desc:css changes done
@param  Task-4107,PublisherCreativeUploads

*/
@media only screen and (min-width:320px) and (max-width:375px)
{
 .CampIDPub
 {
    display: contents !important;
 } 
 .CampNamePub
 {
    display: contents !important;
 } 
 .StartDatePub
 {
    display: contents !important;
 }
 .EndDatePub
 {
    display: contents !important;
 }
 .LPStatusPub
 {
    display: contents !important;
 }
 .NewsletterPub
 {
    display: contents !important;
 }
 .CSStatusPub
 {
    display: contents !important;
 }
}
@media only screen and (min-width:375px) and (max-width:425px)
{
 .CampIDPub
 {
    display: contents !important;
 } 
 .CampNamePub
 {
    display: contents !important;
 } 
 .StartDatePub
 {
    display: contents !important;
 }
 .EndDatePub
 {
    display: contents !important;
 }
 .LPStatusPub
 {
    display: contents !important;
 }
 .NewsletterPub
 {
    display: contents !important;
 }
 .CSStatusPub
 {
    display: contents !important;
 }
}
@media only screen and (min-width:425px) and (max-width:767px)
{
 .CampIDPub
 {
    display: contents !important;
 } 
 .CampNamePub
 {
    display: contents !important;
 } 
 .StartDatePub
 {
    display: contents !important;
 }
 .EndDatePub
 {
    display: contents !important;
 }
 .LPStatusPub
 {
    display: contents !important;
 }
 .NewsletterPub
 {
    display: contents !important;
 }
 .CSStatusPub
 {
    display: contents !important;
 }
}
@media only screen and (min-width:768px) and (max-width:768px)
{
  .CampIDPub
 {
    display: contents !important;
 } 
 .CampNamePub
 {
    display: contents !important;
 } 
 .StartDatePub
 {
    display: contents !important;
 }
 .EndDatePub
 {
    display: contents !important;
 }
 /* .LPStatusPub
 {
    display: inline !important;
 } */
 .NewsletterPub
 {
    display: contents !important;
 }
 .CSStatusPub
 {
    display: contents !important;
 }  
}
@media only screen and (min-width:769px) and (max-width:1024px)
{
.CampIDPub
 {
    display: contents !important;
 } 
 .CampNamePub
 {
    display: contents !important;
 } 
 .StartDatePub
 {
    display: contents !important;
 }
 .EndDatePub
 {
    display: contents !important;
 }
 .LPStatusPub
 {
    display: contents !important;
 }
 .NewsletterPub
 {
    display: contents !important;
 }
 .CSStatusPub
 {
    display: contents !important;
 }
}
@media only screen and (min-width:1024px) and (max-width:1024px)
{
 .CampIDPub
 {
    display: contents !important;
 } 
 .CampNamePub
 {
    display: contents !important;
 } 
 .StartDatePub
 {
    display: contents !important;
 }
 .EndDatePub
 {
    display: contents !important;
 }
 .LPStatusPub
 {
    display: contents !important;
 }
 .NewsletterPub
 {
    display: contents !important;
 }
 .CSStatusPub
 {
    display: contents !important;
 }
}

/* kiran-4173-added media queries */
@media only screen and (min-width:320px) and (max-width:375px)
{
   .CreativesReviewPublisher
   {
     margin-right: 10% !important;
   }
.AllAgencyList
{
  margin-right: 35% !important;
}
}

@media only screen and (min-width:375px) and (max-width:425px)
{
   .CreativesReviewPublisher
   {
     margin-right: 15% !important;
   }
.AllAgencyList
{
  margin-right: 35% !important;
}
}
@media only screen and (min-width:425px) and (max-width:767px)
{
   .CreativesReviewPublisher
{
  margin-right: 20% !important;
}
.AllAgencyList
{
  margin-right: 35% !important;
}
}
