/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : publisherDeliverLead.css
 *Desc: CSS code changes
 */
 @media only screen and (min-width: 320px) and (max-width: 425px) {
  
   #filediv{
   
    margin-left: 0px!important;
    margin-top: -8px!important;
}
.head13{
    font-size: 18px!important;
}
#searchAdj{
    margin-top: 12px!important;
    
}
#searchAdj1{
    width: 233px!important;
    
}
hr{
    margin-top: 15px!important;
}
#selfil{
    width: 223px !important;
}

  /* Sandeep-task-3499-added code for loader alignment  */
  #loader_uploadLead{
    position: absolute;
    width: 50%;
    height: 23vh !important;
    background: white url('/../new_loader.gif') no-repeat center;
    z-index: 999;
    margin-left: 90px !important; 
    margin-top: 80px !important;
 } 

  }
  @media only screen and (min-width: 768px) {
   
     /* Sandeep-task-3499-added code for loader alignment  */
     #loader_uploadLead{
        position: absolute;
        width: 20%;
        height: 23vh !important;
        background: white url('/../new_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 300px !important; 
        margin-top: 80px !important;
     } 
    #searchAdj1{
        width: 250px!important;    
    }
  hr{
        margin-top: -10px!important;
    }
    .to1{
        margin-top: 0px!important;
    }
    #selfil{
        width: 250px !important;
    }
   }
   /* @media only screen and (max-width: 768px) { */
    @media only screen and (min-width: 426px) and (max-width: 768px) {
   #filediv{
    margin-left: 0px!important;
    margin-top: -8px!important;
}
/* kiran-bug 4253-added visible property for displaying full file name */
#fileUp{
    text-overflow: ellipsis!important;
    overflow: visible !important;
    white-space: nowrap!important;
    width:150px!important;
    margin-left: 13px!important;
    margin-top: 9px!important;
}
   }
   @media only screen and (min-width: 1024px) and (max-width: 1440px){
    #fileUp{
        text-overflow: ellipsis!important;
        overflow: visible !important;
        white-space: nowrap!important;
        width:154px!important;
      margin-left:6px!important;
    }
    /* Sandeep-task-3499-added code for loader alignment  */
    #loader_uploadLead{
        position: absolute;
        width: 20%;
        height: 23vh !important;
        background: white url('/../new_loader.gif') no-repeat center;
        z-index: 999;
        margin-left: 400px !important; 
        margin-top: 80px !important;
     } 
   
hr{
        margin-top: -1px!important;
    }
   }
   @media only screen and (min-width: 1024px) {
    #searchAdj1{
        width: 215px!important;
    }
}
  
   @media only screen and (min-width: 1440px) {

    #searchAdj1{
        width: 280px!important;
        
    }   
  
   }
   @media only screen and (max-width: 320px) {
  #statusDiv{
float: right!important;
margin-right: -28px!important;
  }
  #selfil{
      width: 208px !important;
  }
  #searchAdj1{
    width: 208px !important;
  }
  /* Sandeep-task-3499-added code for error msg alignment */
  .msg{
      margin-left: -2px !important;
  }
}
.btn11 {
    border: 2px solid #193D8F;
    background-color:transparent;
    border-radius: 2px;
    color: #193D8F;
    padding: 2px 8px;
    font-size: 16px;
    cursor: pointer;
  }
  .info11 {
    border-color:#193D8F;
    color:#193D8F;
  }
.info11:hover {
    background:#193D8F;
    color: white;
  } 

/* Sandeep-task-3499-added code for loader */
@media only screen and (min-width: 1041px) and (max-width: 2560px){
  #loader_uploadLead{
    position: absolute;
    width: 20%;
    height: 20vh !important;
    background: white url('/../new_loader.gif') no-repeat center;
    z-index: 999;
    margin-left: 500px !important; 
    margin-top: 80px !important;
 } 
}
/* kiran-bug 4253-added media query for responsiveness */
@media only screen and (max-width: 320px) {
    #fileUp{
        text-overflow: ellipsis!important;
        overflow: visible !important;
        white-space: nowrap!important;
        width:154px!important;
        margin-left:-20%!important;
    }
}
@media only screen and (max-width: 375px) {
   
}
@media only screen and (max-device-width: 425px) {
    #searchAdj1{
      margin-top: 10% !important;
      width: 225px!important;
      }
      #searchAdj{
        margin-top: -2px!important;
        margin-bottom: 17px !important;
        width: 31%!important;
    }
    #selfil{
        width: 223px !important;
    }
}
@media only screen and (max-width: 320px) {
    #searchAdj1{
        width: 200px!important;
        }
}
@media only screen and (max-width: 375px) {
    #searchAdj1{
        width: 211px!important;
        }
}
@media only screen and (min-width: 321px) and (max-width: 375px) {
    #fileUp{
        text-overflow: ellipsis!important;
       overflow: visible !important;
       white-space: nowrap!important;  
       width:150px!important;
       margin-left: 3px!important;
       margin-top: -6px!important;  
    }
    #filediv{
    margin-top: -8px!important
}}

@media only screen and (min-width: 0px) and (max-width: 320px) {
}
