/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : rfpReminderSetup.css
 *Desc: CSS code changes
 */

#rfpReminderSetup .heading-container {
	display: flex;
	justify-content: center;
	width: 100%;
}
#rfpReminderSetup .heading-container .heading-text {
	font-size: 28px;
	font-weight: 500;
	color: #193d8f;
	font-family: Roboto;
	margin-bottom: 15px;
}
#rfpReminderSetup .sub-heading {
	font-family: Roboto;
	font-size: 20px;
	font-weight: 600;
	color: #f28c0f;
}
#rfpReminderSetup .dropdown-parent {
	cursor: pointer;
	font-size: 14px;
	font-family: sans-serif;
	border: 1px solid #cccccc;
	background-color: #ffffff;
	border-radius: 3px;
	color: #555555;
	height: 34px;
	/* width: 181px; */
}
#rfpReminderSetup .dropdown-parent:disabled {
	background-color: #eeeeee;
}
#rfpReminderSetup .dropdown-heading {
	color: #14254a;
	font-family: sans-serif;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 6px;
}
#rfpReminderSetup .dropdown-heading::after {
	content: "*";
	color: red;
	margin-left: 3px;
}
#rfpReminderSetup .btn-add-rfp {
	width: 100%;
	border: none;
	outline: none;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-bottom: 10px;
	background-color: #e0e0e0;
	border-radius: 4px;
}
#rfpReminderSetup .btn-add-rfp:disabled {
	opacity: 0.65;
}
#rfpReminderSetup .btn-confirm-save {
	background-image: linear-gradient(to left, #0777cb, #103784) !important;
	color: white !important;

	padding: 6px 12px;
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.4;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	border: 1px solid transparent;
	border-radius: 4px;
}
#rfpReminderSetup .btn-confirm-save:disabled {
	opacity: 0.65;
}

#rfpReminderSetup .btn-add-rfp .btn-add-rfp-text {
	margin-left: 6px;
	color: black;
	font-weight: 500;
}
#rfpReminderSetup .btn-add-rfp .anticon svg {
	background-color: #e0e0e0 !important;
	color: black !important;
}
#rfpReminderSetup .action_context_menu_icon {
	transform: rotate(90deg);
	display: inherit;
	font-weight: bold;
	position: relative;
	left: 23px;
	cursor: pointer;
}
/* For action context menu */
@keyframes fadeIn {
	0% {
		transform: translateY(-25%);
	}
	50% {
		transform: translateY(4%);
	}
	65% {
		transform: translateY(-2%);
	}
	80% {
		transform: translateY(2%);
	}
	95% {
		transform: translateY(-1%);
	}
	100% {
		transform: translateY(0%);
	}
}
#rfpReminderSetup .popup-rfp-reminder-action {
	animation-name: fadeIn;
	animation-duration: 0.4s;
	background-clip: padding-box;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	right: 0px;
	list-style-type: none;
	margin: 0;
	outline: none;
	padding: 0;
	position: absolute;
	text-align: left;
	top: 0px;
	overflow: hidden;
	-webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
#rfpReminderSetup .popup-rfp-reminder-action div {
	clear: both;
	color: rgba(0, 0, 0, 0.65);
	cursor: pointer;
	font-size: 14px;
	font-weight: normal;
	line-height: 22px;
	margin: 0;
	padding: 5px 12px;
	transition: all 0.3s;
	white-space: nowrap;
	-webkit-transition: all 0.3s;
	display: flex;
	align-items: center;
}
#rfpReminderSetup .popup-rfp-reminder-action div:hover {
	background-color: #e6f7ff;
}
#rfpReminderSetup .popup-rfp-reminder-action div > img {
	margin-right: 8px;
}
@media (max-width: 767px) {
	#rfpReminderSetup .dropdown-parent {
		width: 220px;
	}
}
@media (max-width: 576px) {
	#rfpReminderSetup .heading-container .heading-text {
		font-size: 22px;
	}

	#rfpReminderSetup .sub-heading {
		font-size: 18px;
	}
}
/* Start - Loader */
#rfpReminderSetup #loader_reminder_table {
	position: absolute;
	width: 25%;
	height: 21vh;
	background: white url("/../new_loader.gif") no-repeat center;
	z-index: 999;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	margin-top: 81px !important;
}
@media only screen and (max-width: 320px) {
	#rfpReminderSetup #loader_reminder_table {
		width: 70% !important;
	}
}
@media only screen and (min-width: 321px) and (max-width: 375px) {
	#rfpReminderSetup #loader_reminder_table {
		width: 70% !important;
	}
}
@media only screen and (min-width: 376px) and (max-width: 425px) {
	#rfpReminderSetup #loader_reminder_table {
		width: 70% !important;
	}
}
@media only screen and (max-width: 768px) {
#colForRfp{
	margin-top: -8rem;
}
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
	#rfpReminderSetup #loader_reminder_table {
		width: 40% !important;
	}
	
}
@media only screen and (min-width: 768px) { 
	/* Mufiz-bug-4111-responsiveness */
	#colForRfp{
		margin-top: -7rem;
	}
}
/* End - Loader */