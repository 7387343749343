/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Godbole
 *@fileName : salesforceLead.css
 *Desc: css code changes
 */

@media only screen and (min-width: 375px) and (max-width: 425px) {
  #clientName {
    width: 100% !important;
  }
  #campaignID {
    width: 100% !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  #campaignID {
    width: 100% !important;
  }
  select#pacing {
    width: 100% !important;
  }
  #clientName {
    width: 100% !important;
  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  #campaignID {
    width: 100% !important;
  }
  select#pacing {
    width: 100% !important;
  }
  #clientName {
    width: 100% !important;
  }
}

/* kiran- task 3873- added media query */
