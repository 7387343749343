/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :kiran mate
 *@fileName : publisherAllocationHelp.css
 *Desc: css code changes
 */
/* @Auther Mufiz Khan
    Bug no:- 3910
    Title:- rsposiveness
    File:-publisherAllocationHelp.css
    In this file I am covered Help 3 file
    
    1)publisherAllocationHelp.css
    2)createCampaignHelp.js
    3)editCampaignHelp.js
    */
@media only screen and (max-width: 320px) {
  #artical {
    margin-left: 34px !important;
  }
  #helpmainoption {
    margin-left: 20px !important;
  }
}
@media only screen and (min-width: 375px) {
  #artical {
    margin-left: 67px !important;
  }
  #btnYes{
    margin-left: 6rem;
  }
 
  #helpmainoption {
    margin-left: -23px !important;
  }
}
@media only screen and (min-width: 425px) {
  #artical {
    margin-left:60px !important;
  }
  #helpmainoption {
    margin-left: -74px !important;
  }
}
@media only screen and (min-width: 1024px) {
  #h4CreateCH {
    margin-left: "104px";
  }
  #helpoptiontop {
    margin-top: 20px !important;
  }
  #artical {
    margin-left: 200px !important;
  }
}
@media only screen and (min-width: 1440px) {
  #h4CreateCH {
    margin-left: "104px";
  }
  #artical {
    margin-left: 200px !important;
  }
  #helpmainoption {
    margin-left: -84px !important;
  }
}

/*this is style for CreateCampaignHelp.js => */
@media only screen and (max-width: 320px) {
  #roww {
    margin-left: 3rem;
  }
  #rowwPub{
    width: 32rem;
  }
  #btnYes{
    margin-left: 58px;
  }
}


@media only screen and (min-width: 425px) and (max-width: 768px) {
  #roww {
    margin-top: 14px;
    width: 30rem;
    margin-bottom: 3rem;
    margin-left: 9rem;
  }
  #rowwPub{
    margin-bottom: 2rem;
  }
  .img-responsive {
    margin-bottom: 3rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .CCHP1 {
    margin-left: 4rem;
  }
  .createCampvdo {
    margin-left: 4rem;
  }
  .createCamp {
    margin-left: 9rem;
  }
  #roww {
    margin-left: 30rem;
    width: 35rem;
    margin-bottom: 2rem;
  }
  .img-responsive {
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .CCHP1 {
    margin-left: 4rem;
  }
  .createCampvdo {
    margin-left: 4rem;
  }
  .createCamp {
    margin-left: 14rem;
  }
  #roww {
    margin-left: 44rem;
    margin-bottom: 2rem;
  }
  .img-responsive {
    margin-bottom: 1rem;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 2000px) {
  #ContainerPubAl {
    padding-top: 0px;
    width: 76%;
    margin-left: 7rem;
  }
  #h4CreateCH {
    margin-left: "104px";
    color: "#14254A";
    opacity: "75%";
    font-weight: "bold";
  }
  .createCamp {
    margin-left: 15rem;
  }
  .createCampPub {
  }
  .createCampvdo {
    margin-left: 19rem;
  }
  #roww {
    margin-top: 14px;
    margin-bottom: 3rem;
    margin-left: 50rem;
  }
  #rowwPub {
    margin-left: 16rem;
    margin-bottom: 2rem;
  }
  .img-responsive {
    margin-bottom: 3rem;
  }
}
@media only screen and (min-width: 2000px) and (max-width: 2560px) {
  #h4CreateCH {
    margin-left: "104px";
    color: "#14254A";
    opacity: "75%";
    font-weight: "bold";
  }
  .createCamp {
    margin-left: 26rem;
  }
  .createCampvdo {
    margin-left: 15rem;
    font-size: 3rem;
  }
  #roww {
    margin-top: 14px;
    margin-bottom: 3rem;
    margin-left: 105rem;
  }
  #rowwPub{
    margin-left: 74rem;
    margin-bottom: 3rem;
  }
  .img-responsive {
    margin-bottom: 3rem;
  }
}

@media only screen and (min-width: 357px) and (max-width: 425px) {
  #roww {
    margin-left: 8rem;
    width: 35rem;
  }
}
