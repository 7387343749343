/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : rfpAnalysis.css
 *Desc: CSS code changes
 */
a.camp-details:hover,a.camp-details:active{
  color:#F1F2F8 !important

}
/* small devices(mobile upto 576)*/
@media only Screen and (max-width: 576px){
  .heading1{
      margin-left: 97px !important;
       margin-top: -30px !important;
      /* font-size: 5px;  */
  }
  .maindivrfp-data{
    height:699px !important;
    background: #ffffff !important;
  }
}
@media only screen and (max-width:538px) and (min-width:320px){
  .maindivrfp-data{
    height:955px !important;
  }
}
@media only screen and (max-width: 320px)
{
.records{
  /* font-size: 4px !important ; */
}
/* #pacing1{
  font-size: 4px !important;
} */
.maindivrfp-data{
  /* height:564px !important; */
  /* max-height: 900px !important; */
  background: #ffffff !important;
}
.inputstylesearch{
  width: 200px !important;
}
}
/* @media only screen and (max-width:375px) */
@media only screen and  (min-width: 375px) and (max-width: 425px)

{
.maindivrfp-data{
  height:1000px !important;
  padding-bottom: 400px !important;
  background: #ffffff !important;
}
}
/* //shivani-3508-added below code responsiveness */
/* //shivani - 3601 - added css to new class for resposiveness  */
@media only screen and  (min-width:320px) and (max-width: 425px){
  .filter_block_lead_Filter{
    margin-left:0px !important;
  }
  .tablewidth{
    flex: 0 0 110% !important;
    margin-left: -13px !important;
  }
  .maindivrfp-data{
    height:1050px !important;
    padding-bottom: 400px !important;
    background: #ffffff !important;
  }
  /* .dwnld_rfp1{
  } */
.rfpdetails{
  font-size: 13px  !important;
  padding-right: 0px;
  padding-left: -1px !important;
  flex:0 0 94%  !important;
  height: 156px !important;
  margin-bottom: -89px !important;
}
.rfpdetailsinwebkit{
  height: 259px!important;
  flex: 0 0 80%!important;
}
.rfpdetails1{
  font-weight: bold;
  font-size: 11px !important; 
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;   
  text-align: center;
  margin-Left :-5px;
}
.rfpdetails2{
  font-size: 12px !important;
}
.rfpResponse{
  font-size:13px  !important;
  padding-top: 100px !important;
  padding-left: 0px !important;
  width: max-content;
}
.heading1{
  margin-top: -30px !important;
  /* margin-left: 97px !important; */
  margin-left: 38px !important;
  /* display: inline !important; */
}
.rfpanalysishead{
/* margin-top: 26px;
margin-left: -160px !important; */

}
.inputstyle1{
/* width: 86% !important;  */
}
.clear{
margin-left: -2px !important;
margin-top: -18px !important;
}
.region{
padding-left: 35px;
}
.maindivrfp{

}
.ant-table-thead > tr > th {
/* font-size: 7px !important; */
overflow-wrap: inherit;
padding: 8px 1px !important; 
}
.ant-table-tbody > tr > td {
/* font-size: 5px !important; */
overflow-wrap: inherit;
padding: 8px 1px !important;
}
.records{
/* font-size: 7px; */
/* font-size: 6px; */
margin-left: -11px !important;
}
div.countrylist{
/* font-size: 5px !important; */
width: 112px !important;
/* margin-left: 22px !important */
padding-left: 37px !important;
}
.countrylist1{
    /* font-size: 8px !important; */
  }
.clr1{
margin-top: 5px !important;
margin-left: 4px !important;
}
/* .ant-table-wrapper {
margin-left: -31px;
margin-right: -30px;
} */
.ant-table-column-title{
/* font-size: 7px !important; */
/* font-size:5px !important; */
}
.ant-pagination-item a {
/* margin-left: -22px;
margin-right: -14px; */
margin-left: -13px;
margin-right: -10px;
font-size: 7px;
}
.select1{
white-space: nowrap !important; 
  /* width: 10px !important;  */
overflow: scroll !important;
 text-overflow: ellipsis !important; 
 font-size:9px !important; 
/* text-align: left; */
}
/* .select1 option{
height:100px 
} */
.existingRFPHeading{
/* font-size: 6px !important; */
/* margin-left:46px; */
/* padding-bottom: 22px !important; */
}
/* #mytablewidth{
  width:0px !important; 
  } */
  #filter_block3,#filter_block2,#filter_block1{
    margin-left:0px !important;
  }
  #filter_block5{
    margin-top:5px !important;
  }
  #filter_block4{
    margin-left:0px !important;
  }
  .tablewidth{
    flex: 0 0 110% !important;
    margin-left: -13px !important;
  }
}
/* //shivani-3508-added below code responsiveness */
@media only screen and (min-width:768px) and (max-width:768px){
  #filter_block5{
    margin-left:330px !important;
    margin-top:-80px !important;
  }
  #filter_block3{
    margin-left:0px !important;    
  }
/* //shivani - 3601 - added css to new class for resposiveness  */
  .filter_block_lead_Filter{
    margin-left:54px !important;
  }
  #dwnld_rfp{
    margin-left:0px !important;
  }
    .dwnld_rfp1{
      margin-left:241px !important;
  }
  .rfpdetails2{
      font-size: 10px !important;
      font-weight: normal;
  }
  .inputstyle1{
      font-size:10px;
      width: 100% !important;      
  }
  .md-number-input.number-input {
    border: 1px solid #ddd;
    width: 11.8rem;
  }
  .maindivrfp-data{
    /* height:629px !important; */
    height:955px !important;
  }
  .region{
    margin-left: -20px !important;
    margin-right: 27px !important;
  }
  .heading1{
    margin-left: 107px !important;
  }
  /* .clear{
    font-size: 10px;
  } */
  .campName{
    font-size: 7px !important;
    /* height:10px !important; */
  }
  .ant-table-thead > tr > th {

    font-size: 12px !important;
    overflow-wrap: inherit;
    word-wrap: unset !important;  
  }
 .ant-table-tbody > tr > td {
    /* font-size: 11px !important; */
    /* font-size: 8px !important; */
    overflow-wrap: inherit;
  }
  div.countrylist{
    font-size: 15px !important;
    margin-left: -9px !important;
  }
  .countrylist-country{
    font-size: 15px !important;
  }
  .select1{
    /* width:129px !important; */
    width:119px !important;
    padding:5px !important;
  }
  .clear{
    /* margin-left: -17px !important; */
    /* padding-left: 0px;
    font-size: 8px ;
    margin-top:-8px !important; */
  }
  .clrbtn{
    /* color:"#1267b5" !important;
    border-radius: 10px !important;
    background-color: rgb(18,103,181) !important; */
  }
  .clr1{
    margin-left:-15px;
    margin-top:-23px;
  }
  .ant-pagination-item a {
    font-size: 8px !important;
  }
.rfpdetails{
  flex: 0 0 90% !important;
}
.select1{
  overflow: scroll !important;
}
/* .rfptype{
   margin-left: 46px !important;  
   font-weight: bold;
  font-size: 11px !important;
} */

.inputstyle1{
  /* width:149px !important; */
  /* width:165px !important */
}
/* #rfpexthead{
  margin-left: -35px!important;
} */
.inputstylesearch{
  width: 145px!important;
}
.tablewidth{
  flex:0 0 100%;
}
/* .filter_block4,.filter_block3,.filter_block2{
  margin-left:0px !important;

} */
/* #mytablewidth{
  width:435px !important;
  
  } */
}
@media only screen and (max-width:320px){
  .rfpdetails2{
      font-size: 11px !important;
  }
  .maindivrfp{
    /* height:694px !important; */
  } 
  .inputstyle1{
    width: 86% !important;
  }


}
@media only screen and (max-width:375px){
.inputstyle1{
  width: 88% !important;
}
.inputstylesearch{
  width:215px !important;
}
}

@media only screen and (max-width:425px){
.inputstyle1{
  width: 90% !important;
}
}


@media only screen and (min-width: 1024px) and (max-width: 1024px)
{
  .clear{ 
    margin-left: -2px !important;
    /* padding-top: 8px !important; */
  }
  .dwnld_rfp1{
    margin-left:725px !important;

}
  .inputstylesearch{
    width: 205px !important;
  }
  .heading1{
    /* margin-left: 189px !important; */
  }
  .ant-pagination-item a {
    font-size: 11px !important;
  }

  /* @media (min-width: 992px) */
.select-container.col-lg-4 {
  /* -ms-flex: 0 0 33.333333%; */
  flex: 0 0 28.333333%;
  /* max-width: 33.333333%; */
}
.rfpdetails{
/* flex: 0 0 93% !important; */
}
div.countrylist{
font-size: 11px !important;
margin-left: -9px !important;

}
.select1{
overflow: hidden !important;
overflow: scroll !important;

} 
.rfpmainhead{
 padding-top:126px !important;
 
} 

.existingRFPHeading{
/* font-size: 13px !important; */
margin-left: 6px !important;
}
.inputstyle1{
/* width:180px !important; */
}
/* #mytablewidth{
width:634px !important;

} */
}
/* .table{
  width: 94% !important;
  margin-left: 19px;
  margin-bottom:0px !important;
} */
@media only screen and (min-width: 1025px)
{
 /* .rfpdetails{
flex: 0 0 96% !important; 
} */

.select1{
/* overflow: hidden !important; */

overflow: scroll !important;
}
}
@media (min-width: 1200px){
.records {
flex: 0 0 90.666667%;
}
}


input[type="number"] {
  -webkit-appearance: textfield;
    -moz-appearance: textfield;
          appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}
.number-input {
  margin: 3rem;
}
.number-input button {
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  position: relative;
}
.number-input button:before,
.number-input button:after {
  display: inline-block;
  position: absolute;
  content: '';
  height: 2px;
  transform: translate(-50%, -50%);
}
.number-input button.plus:after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.number-input input[type=number] {
  text-align: center;
}
.md-number-input.number-input {
  border: 1px solid #ddd;
  width: 11.8rem;
}
.md-number-input.number-input button {
  outline: none;
  width: 3rem;
  height: 2rem;
  padding-top: .8rem;
}
.md-number-input.number-input button.minus {
  padding-left: 8px;
}
.md-number-input.number-input button.plus {
  padding-left: 2px;
}
.md-number-input.number-input button:before,
.md-number-input.number-input button:after {
  width: 1rem;
  background-color: #212121;
}
.md-number-input.number-input input[type=number] {
  max-width: 5rem;
  padding: .5rem;
  border: solid #ddd;
  border-width: 0 2px;
  font-size: 2rem;
  height: 3rem;
  font-weight: bold;
  outline: none;
} 
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .number-input.md-number-input.safari_only button:before, 
  .number-input.md-number-input.safari_only button:after {
    margin-top: -.6rem; 
   } 
 }}
.number-input {
  margin: 0rem;
}
div.ant-table-thead{
max-height: 187px;
  overflow-y: scroll;
}
.select1{
white-space: nowrap; 
 width: 135px;
overflow: hidden;
/* text-overflow: ellipsis;    */
text-align: left;
}
.countrylist{
white-space: nowrap; 
 width: 135px; 
overflow: hidden;
text-overflow: ellipsis;   
text-align: center;
margin-Left :-5px;
}
/* //shivani-3508-added loader */
#loader_rfp{
  position: absolute;
	width: 40%;
	height:20vh;
    background: white url('/../new_loader.gif') no-repeat center;
	z-index: 999;
    margin-left: 250px;
    margin-top: 35px;
}
/* //shivani-3508-added below code responsiveness */
@media only screen and (min-width:1440px)and (max-width:1440px){
#filter_block2,#filter_block3{
  margin-left: 65px!important;
}
#filter_block4{
  margin-left: 40px!important;
}
 /* //shivani-3781-added css for responsiveness of accept RFP button (#acceptRFP_btn) . */
#acceptRFP_btn{
  margin-left:242px !important;
 }
}
/* //shivani-3508-added below code responsiveness */
@media only screen and (min-width:1024px)and (max-width:1024px){
  #filter_block2,#filter_block3{
    margin-left: 100px!important;
  }
  #p_lbl{
    word-wrap:normal!important;
  } 
  #filter_block5{
    margin-left: 100px!important;
    margin-top: -6px!important;  
  }
/* //shivani - 3601 - added css to new class for resposiveness  */
 .filter_block_lead_Filter{
    margin-left:2px !important;
  }
   /* //shivani-3781-added css for responsiveness of accept RFP button (#acceptRFP_btn). */
  #acceptRFP_btn{
    margin-left:180px !important;  
   }
  }
  @media only screen and (min-width:320px)and (max-width:425px){
 #acceptRFP_btn{
  margin-left:20px !important;
 }
  }
  @media only screen and (min-width:768px)and (max-width:768px){
    #acceptRFP_btn{
      margin-left:128px !important;   
     }
  }