/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Godbole
 *@fileName : publisherAllocation.css
 *Desc: css code changes
 */
 /* Mufiz-Task-4352-Prod issue--On Allocation page--ABM file count issue--Agency side  */
 @media only screen  and (max-width: 320px){

 .tblFirst {
  width: 32rem !important;
}
.publisherAlloPage{
  width: 40rem;
}
 }
@media only screen and (min-width: 0px) and (max-width: 374px) {
  #cpl11 {
    width: 90px !important;
  }
}

@media only screen and (min-width: 375px) {
  #campallocation {
    margin-left: -78px !important;
  }
  #cpl11 {
    width: 90px !important;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  #campallocation {
    margin-left: -107px !important;
  }
}
@media only screen and (min-width: 320px)and (max-width: 426px){

.tblFirst {
 
    width: 40rem !important;
}
}
@media only screen and (min-width: 768px) {
  .campAllocation {
    margin-top: 64px !important;
  }
}
@media only screen and (min-width: 767px) and (max-width:769px) {
.startDateId{
  margin-left: -37rem !important;
}
.startDateClass{
  margin-left: 5px;
}
}
@media only screen and (min-width: 1024px) {
  .campAllocation {
    margin-top: 64px !important;
  }
}
