/* Copyright(c) 2020 APSS Media Pvt. Ltd.
*  All Rights Reserved
*
/*
@author:Sandeep Dhawale/kiran mate
*@fileName : successPage.css
@Description:css file for successPage
*/

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .editButton {
    padding-left: 100px !important;
  }
}

/*Chaitanya-4285-R.28.9-Prod Issue- Allocation page--column header alignment issue--Agency side*/
@media only screen and (min-width: 320px)and (max-width: 375px) {
  .EditCampButton
  {
    margin-left: -2rem;
  }
  .successPageImg
  {
    height: 125px !important;
  }
}
/*Chaitanya-4285-R.28.9-Prod Issue- Allocation page--column header alignment issue--Agency side*/
@media only screen and (min-width: 375px)and (max-width: 425px) {
  .successPageImg
  {
    height: 150px !important;
  }
}
/*Chaitanya-4285-R.28.9-Prod Issue- Allocation page--column header alignment issue--Agency side*/
@media only screen and (min-width: 992px)and (max-width: 1200px) {
  .BasicDetailsAgency
  {
    float:inherit;
  }
  .LinkCampTable {
    width: 100% !important;
  }
}
/*Chaitanya-4285-R.28.9-Prod Issue- Allocation page--column header alignment issue--Agency side*/
@media only screen and (min-width: 1200px)and (max-width: 1440px) {
  .LinkCampTable {
    width: 100% !important;
  }
}
/*Chaitanya-4285-R.28.9-Prod Issue- Allocation page--column header alignment issue--Agency side*/
@media only screen and (min-width: 1440px) {
  .LinkCampTable {
    width: 100% !important;
  }
}