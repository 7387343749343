/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author :kiran mate
 *@fileName : labelDI.css
 *Desc: css code changes
 */
#labelDI{
font-family:roboto!important;
font-size: 28px !important;
font-weight: 500!important;
text-align:center!important;
Color : #193D8F
}