/* Copyright(c) 2020 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Sanjana Godbole/kiran mate
@fileName : allocatingCampaign.css
*Desc: css code changes
*/

.nav-tabs {
  border-bottom: 1px solid #2196f3;
  font-size: 12px !important;
}
.nav-tabs > li.active > a {
  background-color: #144c9b !important;
  color: white !important;
  border: 1px solid #2196f3;
}
.nav > li > a:hover {
  text-decoration: none;
  background-color: transparent;
}
.nav-tabs > li > a {
  margin-right: 2px !important;
  line-height: 1.42857143 !important;
  /* border: 1px solid #2196f3 !important;
    border-radius: 4px 4px 0 0 !important; */
  background-color: #9c9a9863;
  color: black;
}

ul.nav > li > a:hover {
  background-color: #144c9b;
  color: white;
}

.bgColor {
  background-color: rgba(218, 224, 224, 0.39);
}

.tip {
  position: relative !important;
  display: inline-block !important;
  /* border-bottom: 1px dotted black !important; */
}

.tip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #2196f3;
  border-style: solid;
  border-width: 1px;
  border-color: #555;
  color: #fff !important;
  font-size: 1vw;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1031;
  bottom: 125%;
  left: 150%;
  margin-left: -200px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
