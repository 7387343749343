/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author :kiran mate
 *@fileName : helpOption.css
 *Desc: css code changes
 */
@media only screen and (min-width: 0px) and (max-width: 320px) {
  #signup {
    margin-top: 39px !important;
  }
  .allpublisher {
    font-size: 10px !important;
  }
  #labelNew {
    height: 175px !important;
  }
}
@media only screen and (max-width: 375px) {
  #signup {
    margin-top: 39px !important;
  }
  .allpublisher {
    font-size: 10px !important;
  }
  #labelNew {
    height: 175px !important;
  }
}
@media only screen and (min-width: 425px) {
  #signup {
    margin-top: 39px !important;
  }
  #labelNew {
    height: 175px !important;
  }
}
@media only screen and (min-width: 768px) {
  #signup {
    margin-top: 39px !important;
  }
}

@media only screen and (min-width: 1024px) {
  #signup {
    margin-top: 0px !important;
  }
}
@media only screen and (min-width: 1440px) {
  #signup {
    margin-top: 0px !important;
  }
}
@media only screen and (min-width: 2560px) {
  #signup {
    margin-top: 0px !important;
  }
}
