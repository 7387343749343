/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
*/

/*@author : Chaitanya Shinde
 *@fileName : quickUpload.css
 *Desc: css code changes-task 4340
*/

@media only screen and (min-width: 320px) and (max-width: 375px){
    .addDocs
    {
        float: left !important;
        margin-left: 36% !important;
        margin-top: -10% !important; 
    }
    }
    @media only screen and (min-width: 320px) and (max-width: 375px){ /* Musbir -Task-4152*/
        .proceedbtn
        {
            width: 64%; /* Musbir -Task-4152*/
            height: 34px; /* Musbir -Task-4152*/
            font-size: 12.5px; /* Musbir -Task-4152*/
        }
        }
        @media only screen and (min-width: 375px) and (max-width: 425px){ /* Musbir -Task-4152*/
            .proceedbtn
            {
                
                margin-left: 38%; /* Musbir -Task-4152*/
                margin-top: -8%; /* Musbir -Task-4152*/
                width: 64%; /* Musbir -Task-4152*/
                height: 34px; /* Musbir -Task-4152*/
            }
            }
    