/* Copyright(c) 2012 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author: Aman Shaikh 4660
@Creation Date:17-04-2023
@Description: css for LeadFileTrackingReport page
*/

/* added this css for LeadTracking clender filter icon */
.cl-LeadTracking input[type="date"]:before {
  margin-top: 6px;
  color: transparent;
  background: none;
  display: block;
  font-family: 'FontAwesome';
  content: '\f073';
  width: 15px;
  height: 20px;
  position: absolute;
  top: 1px;
  right: 6px;
  color: #000080;
}


