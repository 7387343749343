/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : rfpDashboardFE.css
 *Desc: CSS code changes
 */
@media (max-width: 991px) {
	.md-screen-padding {
		padding: 0px !important;
	}

	.md-screen-margin-top {
		margin-top: 8px !important;
	}
}
@media screen and (max-width: 320px) {
	.page-heading-rfp-dashboard {
		font-size: 18px !important;
	}
	.create-rfp-label {
		font-size: 12px !important;
	}
	.create-rfp-img {
		height: 18px !important;
		width: 18px !important;
	}
}
.fix-margin-row {
	margin-left: 0px !important;
	margin-right: 0px !important;
}
.page-heading-rfp-dashboard {
	font-family: Roboto;
	font-size: 22px;
	font-weight: 450;
	border-bottom: 3px solid #144c9b;
	padding-bottom: 10px;
	color: rgba(0, 0, 0, 0.85);
}
.create-rfp-label {
	cursor: pointer;
	text-align: center;
	background-color: #ffaf42;
	font-size: 15px;
	color: white;
	font-weight: inherit;
	padding-top: 2px;
	padding-bottom: 2px;
	padding-left: 5px;
	padding-right: 5px;
	border-radius: 3px;
}
.create-rfp-img {
	cursor: pointer;
	display: inline-block;
	height: 22px;
	width: 22px;
}
.padding-left-col {
	padding-left: 0px;
	padding-right: 30px;
}
.padding-right-col {
	padding-right: 0px;
	padding-left: 30px;
}
.max-width-custom {
	max-width: 367px !important;
}