/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author : vrushabh patil
 *@fileName : updateExistingLead.css
 *Desc:Task-4231-Add Responsive updateExistingLead/publisherupdate deliver lead.js
 */
@media only screen and (min-width: 320px) {

  .Existing {
    margin-top: 120px !important;
  }

}

@media only screen and (min-width: 375px) {

  .Existing {
    margin-top: 100px !important;
  }

}

@media only screen and (max-width: 425px) {
  .Existing {
    margin-top: 80px !important;
  }

}

@media only screen and (min-width: 768px) {
  .Existing {
    margin-top: 55px !important;
  }
}

@media only screen and (min-width: 1024px) {

  .Existing {
    margin-top: 40px !important;
  }

}

@media only screen and (min-width: 1440px) {

  .Existing {
    margin-top: 15px !important;
  }

}
.mech {
  overflow: hidden !important;
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
  .mech {
    margin-top: 25px !important;

  }
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .infolead {
    margin-left: 211px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
  .infolead {
    margin-left: 273px;

  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  .infolead {
    margin-left: 323px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 768px) {
  .infolead {
    margin-left: 660px;
  }
}

@media only screen and (min-width: 769px)and(max-width: 1023px) {
  .infolead {
    margin-left: 660px;

  }
}

@media only screen and (min-width: 1024px)and(max-width: 1440px) {
  .infolead {
    float: right;

  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  .margineincrese {
    margin-left: 2px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 768px) {
  .margineleftincress {
    margin-right: 42px;
  }
}