/* Copyright(c) 2012 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*
@author: Nilesh Zinzurao
@Creation Date:19-09-2022
@Description:UI for Marketo,hubspot,salesforce lead delivery
*/

@media only screen and (max-width: 320px) {
    #campaignDetails, #myTable {
     min-width: 640px !important;
     /* margin-top: auto !important; */
     overflow: hidden;
   }
}

@media only screen and (max-width: 425px) {
    #campaignDetails, #myTable {
     min-width: 640px !important;
   }
}


@media only screen and (max-width: 375px) {
    #campaignDetails, #myTable {
     min-width: 640px !important;
   }
}



@media only screen and (max-width: 375px) {
  #labelheading{
   min-width: 640px !important;
   text-align: center;
   /* margin-top: auto !important; */
 }
}

@media only screen and (max-width: 425px) {
  #labelheading{
   min-width: 640px !important;
   text-align: center;
   /* margin-top: auto !important; */
 }
}

@media only screen and (max-width: 768px) {
  #labelheading{
   min-width: 640px !important;
   text-align: center;
   /* margin-top: auto !important; */
 }
}




@media only screen and (max-width: 320px) {
  #sfDetails {
  
   line-break:anywhere !important;
   
 }
 .label1{
  margin-left: -14px;
 }
}

@media only screen and (max-width: 425px) {
  #sfDetails {
 
   line-break:anywhere !important;
   
 
  }
  .label1{
    margin-left: -14px;
   }
}

@media only screen and (max-width: 375px) {
  #sfDetails {

   line-break:anywhere !important;
   
 }
 .label1{
  margin-left: -14px;
 }
}

@media only screen and (max-width: 768px) {
  #sfDetails {
  
   line-break:anywhere !important;
   
 }
 .label1{
  margin-left: -14px;
 }
}


@media only screen and (max-width: 1024px) {

 .label1{
  margin-left: -14px;
 }

}