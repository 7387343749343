/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Chaitanya Shinde
 *@fileName : addCustomRegion.css
 *Desc: Task-3982-css code changes
 */

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .regionCancelAdd{
    padding-left: 21%;
  }
  .regionNamePopup{
    height: 285px;
  }
  .backArrowCustomRegion{
    margin-left: -20%;
  }
}
@media only screen and (min-width: 375px) and (max-width: 426px) {
  .regionCancelAdd{
    padding-left: 26%;
  }
  .regionNamePopup{
    height: 285px;
  }
}