/* Copyright(c) 2024 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author : Nilesh Zinzurao - 5112
 *@fileName : welcomeEdit.css
 *Desc:Task-5112- Add css on Camapign button and back button responsiveness
 */
 

@media only screen and (min-width: 320px) and (max-width: 650px) {
    .welcomeEdit {
        margin-top: 60px;
     }
}

@media only screen and (min-width: 650px) and (max-width: 768px) {
    .welcomeEdit {
        margin-top: 40px;
     }
}

@media only screen and (min-width: 867px) and (max-width: 1024px) {
    .welcomeEdit {
       margin-top: 100px;
    }
}

