/* Copyright(c) 2024 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/*
@author:Chaitanya
*@fileName : dashboardDC.css
@Description:5421-UI for campaign List page for agency and publisher 
*/
#loader_campaign_list {
  position: absolute;
  width: 25%;
  height: 21vh;
  background: white url("/../new_loader.gif") no-repeat center;
  z-index: 999;
  margin-left: 500px;
  margin-top: 110px;
}
@media only screen and (max-width: 320px) {
  #loader_campaign_list {
    margin-top: 110px !important;
    width: 70% !important;
    margin-left: 30px !important;
  }
}
@media only screen and (min-width: 321px) and (max-width: 375px) {
  #loader_campaign_list {
    margin-top: 110px !important;
    width: 70% !important;
    margin-left: 30px !important;
  }
}
@media only screen and (min-width: 376px) and (max-width: 425px) {
  #loader_campaign_list {
    margin-top: 110px !important;
    width: 70% !important;
    margin-left: 50px !important;
  }
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
  #loader_campaign_list {
    width: 40% !important;
    margin-left: 220px !important;
    margin-top: 110px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  #loader_campaign_list {
    margin-left: 350px !important;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1440px) {
  .reportType_myreport {
    float: right !important;
    margin-right: -21px !important;
  }
}
@media only screen and (min-width: 1441px) {
  #loader_campaign_list {
    /* karan-task-3695-centering the loader */
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

/* //shivani-3611-added below css for custome div which is used for action column(context menu) */
.popup-campaign-progress {
  animation-name: fadeIn;
  animation-duration: 0.4s;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  right: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.popup-campaign-progress div {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  /* padding: 5px 12px;  */
  transition: all 0.3s;
  white-space: nowrap;
  -webkit-transition: all 0.3s;
  display: flex;
  align-items: center;
}

.popup-campaign-progress div:hover {
  background-color: #e6f7ff;
}

.popup-campaign-progress div > img {
  margin-right: 8px;
}


/* kiran 4310 chnaged classname to resolve conflicts */
.popup-campaign-progressAgency {
  animation-name: fadeIn;
  animation-duration: 0.4s;
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  right: 0px;
  list-style-type: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.popup-campaign-progressAgency div {
  clear: both;
  color: rgba(0, 0, 0, 0.65);
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  margin: 0;
  transition: all 0.3s;
  white-space: nowrap;
  -webkit-transition: all 0.3s;
  display: flex;
  align-items: center;
}

.popup-campaign-progressAgency div:hover {
  background-color: #e6f7ff;
}

.popup-campaign-progressAgency div > img {
  margin-right: 8px;
}
