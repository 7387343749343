/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : createXLSRFP.css
 *Desc: CSS code changes
 */
#loader_xlsx{
	position: absolute;
	width: 25%;
	height: 20vh;
    background: white url('/../new_loader.gif') no-repeat center;
	z-index: 999;
    margin-left: 500px;
    margin-top: 70px;
} 
#loader_file_rfp_xlsx{
	position: absolute;
	width: 25%;
	height: 20vh;
    background: white url('/../File_Loader.gif') no-repeat center;
	z-index: 999;
    margin-left: 500px;
    margin-top: -23px;
} 
@media only screen and (max-width: 320px){
    #loader_file_rfp_xlsx{
        margin-top: -70px !important;
        width: 70% !important;
        margin-left: 30px !important;
    }
    #loader_xlsx{
        margin-top: 80px !important;
        width: 70% !important;
        margin-left: 30px !important;
    }
    .search_xlsx_rfp{
        width: 98% !important;
        margin-left: 0px !important;
    }
 }
@media only screen and (min-width: 321px) and (max-width: 375px){
    #loader_file_rfp_xlsx{
        margin-top: -70px !important;
        width: 70% !important;
        margin-left: 30px !important;
    }
    #loader_xlsx{
        margin-top: 80px !important;
        width: 70% !important;
        margin-left: 30px !important;
    }
    .search_xlsx_rfp{
        width: 73% !important;
        margin-left: 0px !important;
    }
 }
 @media only screen and (min-width: 376px) and (max-width: 425px){
    #loader_file_rfp_xlsx{
        margin-top: -70px !important;
        width: 70% !important;
        margin-left: 50px !important;
    }
    #loader_xlsx{
        margin-top: 80px !important;
        width: 70% !important;
        margin-left: 50px !important;
    }
    .search_xlsx_rfp{
        width: 66% !important;
        margin-left: 0px !important;
    }
}
@media only screen and (min-width: 426px) and (max-width: 768px){
    #loader_file_rfp_xlsx{
        margin-top: -29px !important;
        width: 40% !important;
        margin-left: 220px !important;
    }
    #loader_xlsx{
        width: 40% !important;
        margin-left: 220px !important;
    }
	.search_xlsx_rfp{
        width: 66% !important;
		margin-left: 224px !important;
    }
}
@media only screen and (min-width: 769px) and (max-width: 1024px){
    #loader_file_rfp_xlsx{
        margin-top: -3px !important;
        margin-left: 350px !important;
    }
    #loader_xlsx{
        margin-left: 350px !important;
    }
    .search_xlsx_rfp{
        width: 123% !important;
		margin-left: -38px !important;
    }
}
@media only screen and (min-width: 1025px) and (max-width: 1440px){
    #loader_file_rfp_xlsx,#loader_xlsx{
        margin-left: 500px !important;
    }
    .search_xlsx_rfp{
        width: 100% !important;
    }
}
@media only screen and (min-width: 1441px){
    #loader_file_rfp_xlsx,#loader_xlsx{
        margin-left: 1000px !important;
    }
}