/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author :kiran mate
 *@fileName : welcomePageStyle.css
 *Desc: css code changes
 */
body {
  background-color: whitesmoke;
}
.welcomeCreateCampaign {
  color: #193d8f;
  /* font-size:35px; */
  font-size: 32px;
  font-family: "roboto";
  font-weight: 500;
  margin-left: 32px;
}
.welcomeCreateCampaignEdit {
  color: #193d8f;
  /* font-size:25px; */
  font-size: 32px;
  font-weight: 500;
  font-family: "roboto";
}
.card_title {
  color: #193d8f;
}
.para1 {
  font-size: 16px;
}
.para2 {
  font-size: 14px;
  list-style-type: disc;
  text-align: justify !important;
  /* margin-left: '126px' */
}
.welcome_cards1,
.welcome_cards2 {
  margin-bottom: 20px;
}
.ant-steps-item-description {
  color: #193d8f !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "roboto" !important;
}
.ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #193d8f !important;
}
.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot {
  background: #193d8f !important;
}
/* Small devices (mobile 375 and down) */
@media only screen and (max-width: 320px) {
  .welcomeCreateCampaign {
    font-size: 21px;
    margin-left: 80px;
  }
  .welcome_cards1 {
    margin-right: -160px;
  }
  .welcome_cards2 {
    margin-right: -160px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .div_welcomeCreateCampaign {
    margin-right: -150px;
  }
  /* .para2{
        font-size: 14px;
    } */
  .old_edit {
    margin-top: -40px;
    float: right;
  }
  .welcomeCreateCampaignEdit {
    margin-left: 0px !important;
    margin-bottom: 20px;
  }
} /* medium devices (Tablet 425 and down) */
@media only screen and (min-width: 320px) and (max-width: 425px) {
  .welcomeCreateCampaign {
    font-size: 21px;
    margin-left: 100px;
    font-weight: 500;
    font-family: "roboto";
  }
  .welcome_cards1 {
    margin-right: -160px;
    margin-top: -20px;
  }
  .steps_css {
    margin-right: -120px;
  }
  .welcome_cards2 {
    margin-right: -160px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .div_welcomeCreateCampaign {
    margin-right: -150px;
  }
  .para2 {
    font-size: 14px;
    margin-left: 170px !important;
  }
  .welcomeCreateCampaignEdit {
    font-size: 25px;
    margin-bottom: 20px;
    margin-left: 50px;
    font-weight: 500;
    font-family: "roboto";
  }
  .old_edit {
    margin-top: -40px;
    float: right;
  }
}
/* medium devices (Tablet 768 and down) */
@media only screen and (min-width: 426px) and (max-width: 768px) {
  .welcomeCreateCampaign {
    /* font-size: 24px; */
    font-size: 18px;
    margin-top: 10px;
    margin-left: 50px;
    font-weight: 500;
    font-family: "roboto";
  }
  .welcome_cards2 {
    margin-left: 120px;
    margin-right: -120px;
    margin-top: 30px;
  }
  .welcomeCreateCampaignEdit {
    margin-top: 25px;
    font-size: 25px;
    margin-bottom: 20px;
    margin-top: 10px;
    font-weight: 500;
    font-family: "roboto";
  }
  .old_edit {
    margin-top: -40px;
    margin-right: 100px;
    float: right;
  }
}
/*  Landscape (Laptop 1024 and down) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .welcomeCreateCampaign {
    margin-top: 40px;
    font-size: 30px;
    margin-bottom: 20px;
    font-family: "roboto";
    font-weight: 500;
  }
  .welcomeCreateCampaignEdit {
    margin-top: 25px;
    font-size: 30px;
    margin-bottom: 20px;
    margin-left: 14px;
    font-family: "roboto";
    font-weight: 500;
  }
  .old_edit {
    margin-top: -30px;
    margin-right: 100px;
    float: right;
  }
}
/*  Large (Laptop 1024 and down) */
@media only screen and (min-width: 1025px) {
  .button_upload {
    /* margin-top: 20px; */
  }
  .welcomeCreateCampaign {
    /* font-size: 35px; */
    font-size: 32px;
    font-family: "roboto";
    font-weight: 500;
    margin-left: 66px;
  }
  .welcomeCreateCampaignEdit {
    /* font-size: 25px; */
    font-size: 32px;
    font-weight: 500;
    font-family: "roboto";
  }
  .old_edit {
    margin-top: -30px;
    margin-right: 200px;
    float: right;
  }
}
