/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : advertiserCompanyInformation.css
 *Desc: css code changes
 */
@media only screen and (min-width : 768px) {

    .captcha_advertiser
    {
    padding-left: 60px;
    }
    }
@media only screen and (min-width : 992px) {

    .captcha_advertiser
    {
    padding-left: 123px;
    }
    }

    @media only screen and (min-width: 1200px){
    .captcha_advertiser
    {
    padding-left: 187px;
    }
    }
    @media only screen and (min-width: 375px) and (max-width: 425px){
        .logo-1{
          margin-left:165px!important;
        }

    }
    @media only screen and (min-width: 320px)and (max-width: 375px){
        .logo-1{
          margin-left:140px!important;
        }

    }
    @media only screen and (max-width: 320px){
        .logo-1{
          margin-left:135px!important;
        }

    }
    @media only screen and (min-width: 768px)and (max-width: 1024px){
        .logo-1{
            width:150%!important;
        }

    }
/* kiran-4201 added media query for captcha alignment */
    @media screen and (min-width: 1024px) {
        .captcha_advertiser {
           margin-left: -10% !important;
        }
      }
      @media screen and (min-width: 1440px) {
        .captcha_advertiser {
           margin-left: -10% !important;
        }
      }
      @media screen and (min-width: 320px) {
        .captcha_advertiser {
           margin-left: -15% !important;
        }
      }
      @media screen and (min-width: 375px) {
        .captcha_advertiser {
           margin-left: -4% !important;
        }
      }

      @media screen and (min-width: 425px) {
        .captcha_advertiser {
           margin-left: 4% !important;
        }
      }