/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Godbole
 *@fileName : uploadCommonSupression.css
 *Desc: css code changes
 */

/* @auther Mufiz Khan 
Bug-4117 Responsive Issu*/

@media only screen and (min-width: 320px) and (max-width: 425px) {
  #clientName-1 {
    margin-right: 142px;

    width: 100% !important;
  }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
  #clientName-1 {
    width: 255% !important;
  }
}
@media only screen and (min-width: 425px) and (max-width: 576px) {
  #clientName-1 {
    width: 100% !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  #clientName-1 {
    width: 180% !important;
  }
}
@media only screen and (min-width: 200px) and (max-width: 319px) {
  #clientName-1 {
    width: 163px !important;
  }
}
