/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : advertisers.css
 *Desc: css code changes
 */
 .chooseFile
{
    font-weight: 400;
    padding: 5px;
    background-image:linear-gradient(to left,#0777cb, #103784)!important;
    color: white;
    width: min-content;
    height: 31;
    font-size: 14;
    }
    .chooseFile:hover{
        color: white;
    }

/*sunita-task-3160-added below css for proper alignment*/
    @media only screen and (min-width: 320px) and (max-width: 425px){
        #lead_button{
            margin-top:0px!important;
         }
         #advbtn
         {
            margin-left: 0px !important;
         }

         /* sunita-task-3271- added code for proper alignement*/
         .campaignslistsadv
         {
            margin-bottom: 500px !important;
         }
         /* sunita-task-3271- added code for proper alignement*/
         #labeladvertiser
         {
            margin-left: 98px!important;
            font-size: 18px!important;
         }
    }
    @media only screen and (min-width: 320px)
{
    .advsubmitBtn
    {
       margin-left: 0px!important; 
    } 
}
@media only screen and (min-width: 375px)
{
    .advsubmitBtn
    {
        margin-left: 0px!important;
    }  
}
@media only screen and (min-width: 425px)
{
    .advsubmitBtn
    {
        margin-left: 0px !important;
    }    
}
@media only screen and (min-width: 768px)
{ 
    .advsubmitBtn
    {
       /* margin-left: 0px !important; */
       margin-left: -50px !important; /* sunita-task-3271- added code for proper alignement*/
    }  
    #advbtn
    {
       margin-left: 68px !important;
    }
    #labeladvertiser
    {
        margin-left: 80px !important;/* sunita-task-3271- added code for proper alignement*/      
    } 
}

@media only screen and (min-width: 1024px)
{     
     .advsubmitBtn
     {
        margin-left: -54px !important; /* sunita-task-3271- added code for proper alignement*/
     } 
     #advbtn
     {
        /* margin-left: 48px !important;   */
        margin-left: 58px !important;  /* sunita-task-3271- added code for proper alignement*/
    } 

    #labeladvertiser
    {
        margin-left: 15px !important;/* sunita-task-3271- added code for proper alignement*/      
    }   
}

@media only screen and (min-width: 1200px)
{
    .advsubmitBtn
     {
        margin-left: -55px !important; /* sunita-task-3271- added code for proper alignement*/
     }
     
     #advbtn
     {
        margin-left: 99px !important;
     }

     #labeladvertiser
    {
       margin-left: 32px !important;/* sunita-task-3271- added code for proper alignement*/
       /* font-size: 23px !important;  */      
    }
}
/* Sandeep-task-3441-added code for loader */
/* #loader_review{
    position: absolute;
    width: 25%;
    height: 20vh !important;
    background: white url('/../new_loader.gif') no-repeat center;
    z-index: 999;
    margin-left: 300px !important;
    margin-top: 80px !important;
}  */
#loader_review{
    position: absolute;
    width: 25%;
    height: 20vh !important;
    background: white url('/../new_loader.gif') no-repeat center;
    z-index: 999;
    margin-left: 300px !important;
    margin-top: 80px !important;
} 
/*Chaitanya-4267-RESPONSIVE-Advertiser login-Advertiser Creatives Review Page responsiveness issue */
@media only screen and (min-width: 320px) and (max-width: 425px)
{
    .CampaignIDAdv
    {
        display: contents !important;
    }
    .CampaignNameAdv
    {
        display: contents !important;
    }
}
/*Chaitanya-4267-RESPONSIVE-Advertiser login-Advertiser Creatives Review Page responsiveness issue */
@media only screen and (min-width: 425px) and (max-width: 768px)
{
    .CampaignIDAdv
    {
        display: contents !important;
    }
    .CampaignNameAdv
    {
        display: contents !important;
    }
}
/*Chaitanya-4267-RESPONSIVE-Advertiser login-Advertiser Creatives Review Page responsiveness issue */
@media only screen and (min-width: 768px) and (max-width: 1024px)
{
    .CampaignIDAdv
    {
        display: contents !important;
    }
    .CampaignNameAdv
    {
        display: contents !important;
    }
}