/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : advertiserMyReports.css
 *Desc: CSS code changes
 */

 @media only screen and (min-width: 320px) and (max-width: 426px){ 
    /* Sandeep-removed css from file beacuse of reflecting css issue---(shivani-task-3555) */
      #create_btn_report{
          margin-left: 0px!important;
          }
          .head_crediv{
        font-size:15px!important;
          }
          #fr_mdl{
              margin-left: 0px!important;
          }
          .selectreport{
              margin-left: 0px!important;
          } 
          .reportType_myreportAdv {
              float: left !important;
              margin-left: 3px !important;
          } 
      }
      @media only screen and (min-width: 768px) and (max-width: 768px){
              .selectreport{
                  margin-left: -45px!important;
              }
                  #create_btn_report{
                      margin-left: 0px!important;
                      }
                      #create_btnMr{
                          width: 115px !important;
                          height: 30px !important;
                          margin-top: -1px!important;
                      }
                      #fr_mdl{
                          margin-left: 0px!important;
                      } 
                      .reportType_myreportAdv {
                          float: left !important;
                          margin-left: -10px !important;
                      }                        
      }
      @media only screen and (min-width: 1024px) and (max-width: 1024px){       
              .selectreport{
                  margin-bottom: -13!important;
                  margin-left:-50px!important;
              }
              #CampStatDrop{
                  margin-left:2px!important;
              }
              #CampStatDrop1  
              {
                  margin-left:0px!important;
              }
              #create_btn_report{
                  margin-left: -23px!important;
                  }
                  #create_btnMr{
                      width: 115px !important;
                      height: 30px !important;
                      margin-top: -1px!important;
                  }
      }
      @media only screen and (min-width:320px) and (max-width:320px){
          #cancel-btnMr{
              margin-left: 0px!important;
          }
          #create_btnMr{
              width: 115px !important;
              height: 30px !important;
              margin-left: -20px!important;
              margin-top: 10px!important;
          }
          #selectMr{
              width: 195px!important;
          }
          #input_Mr{
              width:195px!important;
          }
         /* Sandeep-added code for alignment issue */
          .generateButtonAdv
          {
              text-align: left !important;
              margin-left: 7px !important;
          }
      }
      .btnMr {
          border: 2px solid #193D8F;
          background-color:transparent;
          border-radius: 2px;
          color: #193D8F;
          padding: 2px 8px;
          font-size: 14px;
          cursor: pointer;
        }
        .infoMr {
          border-color:#193D8F;
          color:#193D8F;
        }        
      .infoMr:hover {
          background:#193D8F;
          color: white;
        }
       #to_truncate_new{
        text-overflow: ellipsis!important;
        overflow: hidden !important;
        white-space: nowrap!important;  
        width: 100%!important;
        /*chaitanya-4099-Report name displays full name in Report listing page*/
        }
       #to_truncate{
          text-overflow: ellipsis!important;
          overflow: hidden !important;
          white-space: nowrap!important;  
          width:108px!important;
       }
      #to_truncate1{
          text-overflow: ellipsis!important;
          overflow: hidden !important;
          white-space: nowrap!important;  
          width:120px!important;
       }
       #loader{
          position: absolute;
          width: 25%;
          height: 25vh;
          background: white url('/../new_loader.gif') no-repeat center;
          z-index: 999;
          margin-left: 500px;
          margin-top: 90px;
      } 
      #loader_report{
          position: absolute;
          width: 25%;
          height: 20vh;
          background: white url('/../new_loader.gif') no-repeat center;
          z-index: 999;
          margin-left: 500px;
          margin-top: 80px;
      } 
      /* Sandeep-task-3485-added code for loader issue */
      #loader_report3{
          position: absolute;
          width: 25%;
          height: 20vh;
          background: white url('/../new_loader.gif') no-repeat center;
          z-index: 999;
          margin-left: 500px;
          margin-top: 80px;
      } 
      #loader_report1{
          position: absolute;
          width: 25%;
          height: 19vh;
          background: white url('/../new_loader.gif') no-repeat center;
          z-index: 999;
          margin-left: 500px;
          margin-top: 70px;
      }
      #loader_reportadv
      {
          position: absolute;
          width: 25%;
          height: 19vh;
          background: white url('/../new_loader.gif') no-repeat center;
          z-index: 999;
          margin-left: 500px;
          margin-top: 70px;
      }
      #gen_loader{
          position: absolute;
          width: 25%;
          height: 25vh;
          background: white url('/../generate_loader.gif') no-repeat center;
          z-index: 999;
          margin-left: 500px;
       margin-top: 90px;
      }
       #for_popUp_generate1{

          color:#F28C0F;
          font-weight:500;
          font-size:17px;
       }
       #for_popUp_generate{
          color:#056FB9;
          font-weight:500;
          font-size:17px;
       }
       #success_popUp{
          color:#14254A;
          font-weight:500;
          font-size:17px;
       }
       @media only screen and (min-width: 320px) and (max-width: 375px){
          #gen_loader, #loader{
              margin-top: 117px !important;
              width: 70% !important;
              margin-left: 30px !important;
          }
          #loader_report{
              margin-top: 80px !important;
              width: 70% !important;
              margin-left: 30px !important;
          }
           /* Sandeep-task-3485-added code for loader issue */
          #loader_report3{
              margin-top: 80px !important;
              width: 70% !important;
              margin-left: 30px !important;
          }
          #loader_report1{
              margin-top: 80px !important;
              width: 70% !important;
              margin-left: 30px !important;
              height: 20vh!important;
          }
          #loader_reportadv
          {
              margin-top: 80px !important;
              width: 70% !important;
              margin-left: 30px !important;
              height: 20vh!important;
          }
          /* Sandeep-added code for alignment issue */
          .generateButtonAdv
          {
              text-align: left !important;
              margin-left: 7px !important;
          }
       }
       @media only screen and (min-width: 376px) and (max-width: 425px){
          #gen_loader,#loader{
              margin-top: 110px !important;
              width: 70% !important;
              margin-left: 50px !important;
          }
          #loader_report{
              margin-top: 80px !important;
              width: 70% !important;
              margin-left: 50px !important;
          }
           /* Sandeep-task-3485-added code for loader issue */
          #loader_report3{
              margin-top: 80px !important;
              width: 70% !important;
              margin-left: 50px !important;
          }
          #loader_report1{
              margin-top: 80px !important;
              width: 70% !important;
              margin-left: 50px !important;
              height: 20vh!important;
          }
          #loader_reportadv
          {
              margin-top: 80px !important;
              width: 70% !important;
              margin-left: 50px !important;
              height: 20vh!important;
          }
  /* Sandeep-added code for alignment issue */
          .generateButtonAdv
          {
              text-align: left !important;
              margin-left: 7px !important;
          }
      }
      @media only screen and (min-width: 426px) and (max-width: 768px){
          #gen_loader,#loader{
              margin-top: 110px !important;
              width: 40% !important;
              margin-left: 220px !important;
          }
          #loader_report{
              width: 40% !important;
              margin-left: 220px !important;
          }
           /* Sandeep-task-3485-added code for loader issue */
          #loader_report3{
              width: 40% !important;
              margin-left: 220px !important;
          }
          #loader_report1{
              width: 40% !important;
              margin-left: 220px !important;
              height: 20vh!important;
          }
          #loader_reportadv
          {
              width: 40% !important;
              margin-left: 220px !important;
              height: 20vh!important;   
          }
      }
      @media only screen and (min-width: 769px) and (max-width: 1024px){
          #gen_loader,#loader{
              margin-top: 110px !important;
              margin-left: 350px !important;
          }
          #loader_report{
              margin-left: 350px !important;
          }
           /* Sandeep-task-3485-added code for loader issue */
          #loader_report3{
              margin-left: 350px !important;
          }
          #loader_report1{
              margin-left: 350px !important;
              height: 20vh!important;
          }
          #loader_reportadv
          {
              margin-left: 350px !important;
              height: 20vh!important; 
          }
          /* Sandeep-added code for alignment issue */
          .generateButtonAdv
          {
              text-align: right !important;
              /* margin-left: 7px !important; */
          }
      }
      @media only screen and (min-width: 1025px) and (max-width: 1440px){
           /* Sandeep-task-3485-added code for loader issue */
          #gen_loader,#loader,#loader_report,#loader_report3{
              margin-left: 500px !important;
          }
         #loader_report1{
              margin-left: 500px !important;
              height: 20vh!important;
          }
          #loader_reportadv
          {
              margin-left: 500px !important;
              height: 20vh!important;
          }
  /* Sandeep-added code for alignment issue */
          .generateButtonAdv
          {
              text-align: right !important;
              /* margin-left: 7px !important; */
          }
      }
      @media only screen and (min-width: 1441px){
          #gen_loader,#loader,#loader_report{
              margin-left: 1000px !important;
          }
          #loader_report1{
              margin-left: 1000px !important;
              height: 20vh!important;
          }
          #loader_reportadv
          {
              margin-left: 1000px !important;
              height: 20vh!important;
          }
      }
      /*Chaitanya-4181-Advertiser- My report- advertiserDeliveryTrakingReport*/
      @media only screen and (min-width: 320px)and (max-width: 320px){
            #deliveryTracking_Agency
            {
            margin-left: 0px!important;
            margin-inline-end: 79px;
            }
            #deliveryTracking_Timeline
            {
            margin-left: 0px!important;
            margin-inline-end: 90px;
            }
            #modal-content_1 
            {
            font-size: 12px !important;
            width: 115% !important;
            margin-left: 4px;
            }
            .reportName_Advertiser
            {
                width: 103% !important;
                float: right !important;
                margin-right: 0% !important;
            }
            .report_type_createNewReport
            {
                width: 103% !important;
                float: right !important;
                margin-right: 0% !important;
            }
            .reportType_myreportAdv
            {
                width: 108% !important;
                float: right!important;
                margin-right: 0px !important;
            }
            .report_type_input_Adv
            {
                width: 103% !important;
                float: right !important;
                margin-right: 0% !important;
            }
            .search_myreport_advertiser
            {
                width: 108% !important;
                margin-bottom: 5px;
                float: right !important;
                margin-right: 0% !important;
            }
            #edit_button
            {
                float: left;
                margin-left: 12% !important;
            }
            #cancle_button
            {
                float: left;
                margin-left: 8% !important;
            }
            #create_button
            {
                float: left;
                margin-left: 16% !important;
            }
            #deliveryTracking_generateReport
            {
                float: left;
                margin-left: 23px !important;
            } 
            #recordsPerPageAdv
            {
                width: 51% !important;
            }
            .pacing_Adv
            {
                margin-left: 28px!important;
            }
            .pacing_deliveryTracking
            {
                margin-left: 4%!important;
                width: 40% !important;
            }
        }
         /*Chaitanya-4181-My report-create new report*/ 
        @media only screen and (min-width: 375px) and (max-width:425px)
        {
            #modal-content_1 
            {
                font-size: 12px !important;
                margin-left: 30px;
            }
            #edit_button
            {
                float: left;
                margin-left: 14% !important;
            }
            #cancle_button
            {
                float: left;
                margin-left: 31px !important;
            }
            #create_button
            {
                float: left;
                margin-left: 14% !important;
            }
            #deliveryTracking_generateReport
            {
                float: left;
                margin-left: 23px !important;
            }
            .reportName_Advertiser
            {
                width: 101% !important;
            }
            .report_type_createNewReport
            {
                width: 100% !important;
            }
            .reportType_myreportAdv
            {
                width: 100% !important;
                float: right!important;
                margin-right: 0px !important;
                margin-bottom: 5px !important;
            }
            .report_type_input_Adv
            {
                width: 101% !important;
            }
            .search_myreport_advertiser
            {
                width: 100% !important;
                margin-bottom: 5px;
                margin-left: 0px;
            }
            #recordsPerPageAdv
            {
                width: 65% !important;
            }
            .pacing_Adv
            {
                margin-left: 4px!important;
                width: 73px !important;
            } 
            .pacing_deliveryTracking
            {
                margin-left: 4%!important;
                width: 40% !important;
            } 
        }
         /*Chaitanya-4181-My report-create new report*/ 
        @media only screen and (min-width: 425px) and (max-width:767px)
        {
            #modal-content_1 {
                font-size: 12px !important;
                width: 94% !important;
                margin-left: 15% !important;
            }
            .reportName_Advertiser
            {
                width: 94% !important;
            }
            .report_type_createNewReport
            {
                width: 95% !important;
            }
            .reportType_myreportAdv
            {
                width: 100% !important;
                float: right!important;
                margin-right: 0px !important;
                margin-bottom: 5px !important;
            }
            .report_type_input_Adv
            {
                width: 94% !important;
            }
            .search_myreport_advertiser
            {
                width: 100% !important;
                margin-bottom: 5px;
                margin-left: 0px;
            }
            #edit_button
            {
                float: left;
                margin-left: 11% !important;
            }
            #cancle_button
            {
                float: left;
                margin-left: 31px !important;
            }
            #create_button
            {
                float: left;
                margin-left: 12% !important;
            }
            #deliveryTracking_generateReport
            {
                float: left;
                margin-left: 23px !important;
            } 
            #recordsPerPageAdv
            {
                width: 57% !important;
            }
            .pacing_Adv
            {
                margin-left: 4px!important;
                width: 73px !important;
            }  
            .pacing_deliveryTracking
            {
                margin-left: 4%!important;
                width: 40% !important;
            } 
        }
         /*Chaitanya-4181-My report-create new report*/ 
        @media only screen and (min-width: 768px) and (max-width: 768px)
        {
            #advertiser_reportType
            {
                margin-left: 4% !important;
            }
            #advertiser_searchbar
            {
                margin-left: 4% !important ;
            }
            .search_myreport_advertiser
            {
                float: right !important;
                margin-right: 0px !important;
            } 
            .reportName_Advertiser
            {
                width: 101% !important;
            }
            .report_type_createNewReport
            {
                width: 101% !important;
            }
            .reportType_myreportAdv
            {
                width: 81% !important;
                float: right!important;
                margin-right: 30% !important;
                margin-bottom: 5px !important;
            }
            .report_type_input_Adv
            {
                width: 101% !important;
            }
            #cancle_button
            {
                float: left;
                margin-left: 34px !important;
            }
            #create_button
            {
                float: left;
                margin-left: 30% !important;
            }
            #edit_button
            {
                float: left;
                margin-left: 30% !important;
            }  
        }
        /*Chaitanya-4181-My report-create new report*/ 
        @media only screen and (min-width: 1024px) and (max-width: 1024px)
        {
            .reportType_myreportAdv
            {
                width: 201% !important;
                float: right!important;
                margin-right: 30% !important;
                margin-bottom: 5px !important;
            }
            .search_myreport_advertiser
            {
                float: right !important;
                width: 150% !important;
            }
            #CampStatDrop_Adv
            {
                margin-left:2px!important;
                margin-inline-end: 26px !important;
            }
        }
        /*Chaitanya-4181-My report-create new report*/ 
        @media only screen and (min-width: 1440px)
        {
            .reportType_myreportAdv
            {
                width: 133% !important;
                margin-bottom: 5px !important;
            }
        }
/*4314-R-29-Prod issue-My Report-create new report-cancel button-Advertiser-(Removed add.button)*/
@media only screen and (min-width: 320px) and (max-width:375px)
{
    #modal-content_1 {
        width: 100% !important;
        margin-left: 0%;
    }
}
@media only screen and (min-width: 375px) and (max-width:425px)
{
    #modal-content_1 {
        width: 86% !important;
        margin-left: 8%;
    }
}
@media only screen and (min-width: 425px) and (max-width:768px)
{
    #modal-content_1 {
        width: 78% !important;
        margin-left: 12% !important;
    }
}
@media only screen and (min-width: 768px) and (max-width:768px)
{
    #modal-content_1 {
        width: 100% !important;
        margin-left: 0% !important;
    }
}