/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : requestForProposal.css
 *Desc: CSS code changes
 */
 @import '~antd/dist/antd.css';
/* Small devices (mobile 375 and down) */ 
.backButton{
    color: #056eb8;
    /* right: 33%; */
    right: 34%;/*sunita-task-3226- changed the css as per requirement*/
}
.rfpAllCustomInput
{  
    width:160px !important; /*sunita-task-3246-added here for proper alignment */ 
}
.rfpAllCustomdiv{
    /* width:78%; */
    width: 100%;/*sunita-task-3226- changed the css as per requirement*/
    /* height: 150px; */
    height: 230px;
    overflow-y: scroll;
}
.inputStyle_rfp{
    width: 100% !important;
}
.inputStyle_rfp2{
    width: 109% !important;
}
@media only screen and (max-width: 320px)
{
     .backFrom_rfp{
        margin-bottom: -20px !important;
    } 
    .companyRevenueStyle{
        width: 325px !important;
    }
    .employeeSizeStyle{
        margin-left: -20px !important;
        width: 25rem !important;
        margin-right: 20px !important;
    }
    .step {
        font-size: 15px;
        font-weight: 600;
        color: #193D8F;
        padding-top: 7%;
        padding-left: 18%;
    }
    .submitButton{
    width: 50px;
    height: 25px;
    font-size: 10px;
    padding: 0px;
    }
    .backButton{
    color: #056eb8;
    right: 40%;
    top:60%;
    }
    .svgsize{
        width: 22px !important;
    }
    .rfpAllCustomdiv{
        /* width: 64%; */
        width: 100%; /*sunita-task-3246-increased the width proper alignment*/
        height: 150px;
        overflow-y: scroll;
    } /* this class is for custom job function custom job level custom employee size custom company Revenue (DIV) */    
    .rfpAllCustomInput
    {
        /* width: 136px !important;   sunita-task-3246- commented this css added in another media query as it's giving pblm while setting responsiveness*/  
         
    }/* this class is for custom job function custom job level custom employee size custom company Revenue (INPUTS) */
    .otherSpecsDiv{
        padding-left:4%   
    }
    .jobTitle_CustomQuestions{
      
        margin-left: -20px !important;
    }   
}
/* Sandeep-task-3164-added media query to handle back Button */
@media only screen and  (min-width: 321px) and (max-width: 375px)
{
    .backButton {
        color: #056eb8;
        right: 40%;
        top: 70% !important;
    }
 /*sunita-task-3226- changed the css as per requirement*/
    .step {
        font-size: 16px;
        font-weight: 600;
        color: #193D8F;
        padding-top: 7%;
        padding-left: 18%;
    }
    }
@media only screen and  (min-width: 376px) and (max-width: 425px){
    .backButton {
        color: #056eb8;
        right: 8%;
        top: 52% !important;
    }
/*sunita-task-3226- changed the css as per requirement*/
    .step {
        font-size: 16px;
        font-weight: 600;
        color: #193D8F;
        padding-top: 7%;
        padding-left: 0%;
        }  
}

/* medium devices (Tablet 425 and down) */ 
@media only screen and  (min-width: 320px) and (max-width: 425px)
{
    .backFrom_rfp{
        margin-bottom: -20px !important;
    } 
    .companyRevenueStyle{
        width: 27rem !important;
        margin-left: 32px !important;
        margin-right: -54px !important;
        margin-top: 0px !important; /*sunita-task-3248-added css for proper alignment */
    }
    .employeeSizeStyle{
        margin-left: 30px !important;
        margin-right: -54px !important;
        width: 27rem !important;
    }
    #leadAllocation,#CPL{
        height: 50% !important;
    }
    #jobFunction_rfp,#jobLevel_rfp,#industry_rfp{
        width:27rem !important;
    }
    #regions_rfp{
        padding-right: 300px;
    }
 #specdiv1{
     margin-left: -8px!important;
 }
 /* Sandeep-task-3164-added code to handle back Button */ /*commented sandeep's code as it's giving pblm while setting to responsiveness so added as per devise wise*/
 /* .step {
    font-size: 16px;
    font-weight: 600;
    color: #193D8F;
    padding-top: 7%;
    padding-left: 8%;
 } */

 /*sunita-task-3226- added below css as per requirement*/
 .empSize
 {
     padding:0px !important;
 }
 .t1{
    width: 269px !important;
    margin-left: -10px !important;
 }
 .t2
 {
    width: 267px !important;
    /* margin-left: -10px !important; */
 }
 .jobTitle_CustomQuestions
 {
    /* width: 269px !important; */
    margin-left: 0px !important;
 }
 .rfpAllCustomInput
 {  
     width:221px !important /*sunita-task-3246-added for peroper alignment*/

 }/* this class is for custom job function custom job level custom employee size custom company Revenue (INPUTS) */

/* sunita-task-3645-added below css for countrylead msg */
 .countryLead
 {
    margin-left: -3px !important;
 }
}
/* medium devices (Tablet 768 and down) */ 
@media only screen and (min-width: 426px) and (max-width: 768px)
{
    .backFrom_rfp{
        margin-left: 130px !important;
    }
    .inputStyle_rfp{
        width:104% !important;
    }
    .Card_rfp{
    width: 100% !important;
    }
    .companyRevenueStyle{
        width: 300px !important;
        margin-top: -190px !important;
        margin-left: 20px !important;
    }
    .employeeSizeStyle{
        margin-left: 26px !important;
        margin-top: 20px !important;  
        width: 300px !important;
    }
    #leadAllocation,#CPL{
        height: 50% !important;
    }
    #jobFunction_rfp,#jobLevel_rfp,#industry_rfp{
        width:230px !important;
    }
    #regions_rfp{
        padding-right: 588px;
    }
    #specdiv1{
        margin-left: 0px!important;
        /* margin-left: -10px!important; */
    }
    #other{
        margin-bottom: 14px !important;
    }
    /* Sandeep-task-3164-added code to handle back Button */ /*sunita-task-3226- added below css as per requirement*/
    .step {
    /* font-size: 16px; */
    font-size: 20px;
    font-weight: 600;
    color: #193D8F;
    padding-top: 7%;
    /* padding-left: 8%; */
 }
 .backButton {
    color: #056eb8;
    right: 33%;
    top: 55% !important;
}
 /* Sandeep-task-3179-added code to for submit Button  give top margin */
#submit{
     margin-top: 315px !important;
}

/*sunita-task-3226- added below css as per requirement*/
.empSize1{
    /* padding-bottom: 48px !important;
    margin-top: 3px  !important; */
    /* height:428px !important; */
    }
    .empSizena1
    {
        margin-top: 22px !important;
    }
    .empSizena2
    {
        margin-top: 9px !important;
    }
    .t1{
        width: 300px !important;
        margin-left: -2px !important;
    }
    .t2{
        width: 300px !important;
        margin-left: -2px !important;
    }
    .rfpAllCustomdiv
    {
        height:70px !important;
    }
    #customemployeeSizeText{
        height: 255px !important;/*sandeep-task-3518-added css for proper alignment*/
    }
    /* sunita-task-3645-added below css for countrylead msg */
 .countryLead
 {
    /* margin-left: -3px !important;  */
     margin-left: -250px !important; /*sunita-task-3737-added css as per requirement */
    /* margin-top: -36px !important; */
    /* margin-left: 480px !important;
    margin-top: -34px !important; */
 }
}
/*  Landscape (Laptop 1024 and down) */ 
@media only screen and (min-width: 769px) and (max-width: 1024px)
{
    .backFrom_rfp{
        margin-left: 50px !important;
    }
    .inputStyle_rfp{
        width:90% !important;
    }
    .Card_rfp{
        margin-left: 0px !important;
        width: 135% !important;
    }
    .employeeSizeStyle{
        margin-top:1131px; /*sunita-task-3248-increased the margin as it's giving pblm for responsiveness*/
        margin-left: 20px !important;
        width: 300px !important;
        margin-left: -994px !important;
    }
    .companyRevenueStyle{
        margin-top:30px;
        margin-left: 35px !important;
        margin-top: 926px!important; /*sunita-task-3248-increased the margin as it's giving pblm for responsiveness*/
        width: 300px !important;
    }
    .jobTitle_CustomQuestions{
        margin-top: -400px !important;
        margin-left: 675px !important;
        }
    .card_supportingDocument{
        margin-left: 10px !important;
    }
    #jobFunction_rfp,#jobLevel_rfp,#industry_rfp{
        width:32rem !important;
    }
    #specdiv1{
        margin-left: -8px!important;
    }
    /* Sandeep-task-3164-added code to handle back Button */
    .backButton {
        color: #056eb8;
        right: 33%;
        top: 14% !important;
    }
    /*sunita-task-3226- added below css as per requirement*/
    .empSize1{
    /* padding-bottom: 48px !important;
    margin-top: 3px  !important; */
    /* height:428px !important; */
    }
    .empSizena1
    {
        margin-top: 22px !important;
    }
    .empSizena2
    {
        margin-top: 9px !important;
    }
    .t2{
        width: 313px !important; 
    }
    .rfpAllCustomInput
    {
        width:248px !important; /*sunita-task-3246-added css for proper alignment*/
    }/* this class is for custom job function custom job level custom employee size custom company Revenue (INPUTS) */
    .rfpAllCustomdiv
    {
        height:68px !important;/*sunita-task-3248-added css for proper alignment*/
    }
    #customemployeeSizeText{
        height: 263px !important;/*sandeep-task-3518-added code for proper alignment*/
    }
/*sunita-task-3737-added css as per requirement */
    .countryLead
 {
    margin-left: 10px !important;
    /* margin-top: -35px !important;
     */
     /* margin-top: -35px !important; */
 }
}
/*  Large (Laptop 1024 and down) */ 
@media only screen and (min-width: 1025px)
{
    .employeeSizeStyle{
        margin-left: 26px !important;
        width: 300px !important;
    }
    .companyRevenueStyle{    
    margin-left: 28px !important;
    width: 300px !important;
    /* margin-top: 85px !important; */
    }    
    /* Sandeep-task-3157-added code for alignment of job title */
    .jobTitle_CustomQuestions{
        margin-top: -70px !important; 
    }
    #jobFunction_rfp,#jobLevel_rfp,#industry_rfp{
        width:32rem !important;
    } 
}

/*sunita-task-3226- added the css as per requirement for setting devise wise*/
@media only screen and (min-width: 1200px) 
{
    #specdiv1{
    margin-left: -4px !important;
    }
    .rfpAllCustomInput
    {
        width:245px !important; /*sunita-task-3248-added css for proper alignment*/
    }/* this class is for custom job function custom job level custom employee size custom company Revenue (INPUTS) */

/*sunita-task-3737-added css as per requirement */
    .countryLead
    {
       margin-left: 10px !important;
       /* margin-top: -35px !important;
        */
        /* margin-top: -29px !important; */  
    }
}
/*sunita-task-3818-added below css as per requirement as showing white patches behind the icons*/
.iconbackground.anticon svg
{
    background-color: #f5f5f5 !important;   
}