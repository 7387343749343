/* Copyright(c) 2021 APSS Media Pvt. Ltd.
*  All Rights Reserved
*
/*
@author:Sandeep Dhawale/Sanjana Godbole
@Filename:addUserAgency.css
@Creation Date:06-05-2021
@Description:css file for addUserAgency Page/css changes done
*/
@media only screen and (min-width: 0px)and (max-width: 320px)
{
  /*sunita-task-3782-added below css as per requirement*/
    /* #userName
    {
     width: 100% !important;
    } */
    
/* kiran task 3911 */
#role{
    width: 100% !important;  
}
#firstName{
    width: 100% !important;
}
#lastName{
    width: 100% !important;
}
/* kiran- bug 3911- added new css as per requirement */
#userNameNew{
    width: 100% !important;
}

/*sunita-task-3782-added below css as per requirement*/
.roleinputbox
{
    padding-top: 12px !important; 
}
}

/* kiran- bug 3911- added new css as per requirement */
@media only screen and (min-width: 375px)and (max-width: 768px)
{
    #userNameNew{
        width: 100% !important;
    }
    #role{
        width: 100% !important;  
    }
    #firstName{
        width: 100% !important;
    }
    #lastName{
        width: 100% !important;
    }
}


/*sunita-task-3782-added below css as per requirement*/
@media only screen and (min-width: 375px) and (max-width: 426px)
{
    .roleinputbox
    {
        padding-top: 12px !important; 
    }

   
}

#loader_report1{
    position: absolute;
    width: 25%;
    height: 19vh;
    background: white url('/../new_loader.gif') no-repeat center;
    z-index: 999;
    margin-left: 500px;
    margin-top: 70px;
} 
/* Mufiz-Task 3910 */
@media only screen and (max-width: 312px)
{
  #AddUser{
    padding-bottom: 34px;
}
}