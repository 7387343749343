/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :kiran mate
 *@fileName : qaloginCss.css
 *Desc: css code changes
 */

 /* Rutuja Jagtap task-3879  responsive*/
@import '~antd/dist/antd.css';

.qacampaignDetailsDiv{
    background:#FFFFFF!important;
    height:auto!important;
   
}
/* sunita-task-3271- commented below css as it's giving pblm for responsiveness */
/* .mywidth{
width: 180px;
} */
@media only screen and (min-width:320px) and (max-width: 320px)
{
    /* .mobileCss{
        padding-left: 33px!important;
    } */
.card-body2{
    margin-bottom: 43px!important;
}
.qacampaignDetailsDiv{
    background:#FFFFFF!important;
    height:auto!important;
}
 /* Rutuja Jagtap task-3879  responsive*/
.qacampaignDetails{
    margin-top: 50px;
}

/* sunita-task-3271- commented below css as it's giving pblm for responsiveness */
/* .mySelect.ant-select{
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 9px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    outline: 6;
    
} */
/* .myFont.ant-table-tbody > tr > td {
    font-size: 7px!important;
    overflow-wrap:inherit ;
} */
/* .mywidth{
    width: 130px;
} */

/*sunita-task-3160-added css for proper alignment*/
.btn_Submit
{
    margin-left: 0px !important;
    text-align: none;
}

#submitButton
{
    margin-left: 0px !important;
}
}
@media only screen and (min-width:375px) and (max-width: 375px){
 /* Rutuja Jagtap task-3879  responsive*/
    .qacampaignDetails{
        margin-top: 50px;
    }
    /* sunita-task-3271- commented below css as it's giving pblm for responsiveness */
    /* .mySelect.ant-select{
        -webkit-box-sizing: border-box; 
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, 0.65);
        font-size: 9px;
        font-variant: tabular-nums;
        line-height: 1.5;
        list-style: none;
        -webkit-font-feature-settings: 'tnum';
        font-feature-settings: 'tnum', "tnum";
        position: relative;
        display: inline-block;
        outline: 6;
        
    } */
    /* .myFont.ant-table-tbody > tr > td {
        font-size: 7px!important;
        overflow-wrap:inherit ;
    }   */
    /* .mywidth{
        width: 130px;
    }  */
/*sunita-task-3160-added css for proper alignment*/
    .btn_Submit
    {
        margin-left: 0px !important;
        text-align: none;
    }

    #submitButton
    {
        margin-left: 0px !important;
    }
}
@media only screen and (min-width:425px) and (max-width: 425px)
{
    .mobileCss{
        /* padding-left: 137px!important; */
            padding-left: 0px!important; /* sunita-task-3271- added code for proper alignement*/
    }
.card-body2{
    margin-bottom: 43px!important;
}
.qacampaignDetailsDiv{
    background:#FFFFFF!important;
    height:auto!important;
}
 /* Rutuja Jagtap task-3879  responsive*/
.qacampaignDetails{
    margin-top: 50px;
}

/* sunita-task-3271- commented below css as it's giving pblm for responsiveness */
/* .mySelect.ant-select{
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 9px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    outline: 6;
} */
/* .myFont.ant-table-tbody > tr > td {
    font-size: 9px!important;
    overflow-wrap:inherit ;
} */
/* .mywidth{
    width: 127px;
} */

/*sunita-task-3160-added css for proper alignment*/
.btn_Submit
{
    margin-left: 0px !important;
    text-align: none;
}

#submitButton
{
    margin-left: 0px !important;
}

}
@media only screen and (min-width:768px) and (max-width: 768px)
{
.card-body2{
    margin-bottom: 43px!important;
}
.qacampaignDetailsDiv{
    background:#FFFFFF!important;
    height:auto!important;
}
.ant-table-tbody > tr > td{
    font-size: 12px!important;
}
 /* Rutuja Jagtap task-3879  responsive*/
.qacampaignDetails{
    margin-top: 50px;
}

/* sunita-task-3271- commented below css as it's giving pblm for responsiveness */
/* .mySelect.ant-select{
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 10px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    outline: 6;
} */
/* .myFont.ant-table-tbody > tr > td {
    font-size: 11px!important;
    overflow-wrap:inherit ;
} */
/* .mywidth{
    width: 117px;
} */

/*sunita-task-3160-added css for proper alignment*/
#submitButton
{
    margin-left: 58px !important;
}
}
@media only screen and (min-width:1024px)and (max-width: 1024px)
{
    .mobileCss{
        padding-left: 20px!important;
    }
.card-body2{
    margin-bottom: 43px!important;
}
.qacampaignDetailsDiv{
    background:#FFFFFF!important;
    height:auto!important;
    
}

/* sunita-task-3271- commented below css as it's giving pblm for responsiveness */
/* .mySelect.ant-select{
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 11px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    outline: 6; */
/* } */

/* .myFont.ant-table-tbody > tr > td {
    font-size: 12px!important;
    overflow-wrap:inherit ;
} */
/* .mywidth{
    width: 130px;
} */

/*sunita-task-3160-added css for proper alignment*/
#submitButton
{
    margin-left: 81px !important;
}
}

@media only screen and (min-width:1200px)
{
    #submitButton
    {
        margin-left: 58px !important;
    }
}
/* Sandeep-task-3441-added code for loader */
#loader_review{
    position: absolute;
    width: 25%;
    height: 20vh !important;
    /* sandeep-task-3566-added code for loader issue */
    background: white url('/../new_loader.gif') no-repeat center;
    z-index: 999;
    margin-left: 300px !important;
    margin-top: 50px !important;/*snehal-task-3566-Loader issue--Review leads
    /* sandeep-task-3566-added code for loader issue */
} 


