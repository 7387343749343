/* //shivani-3517-added loader */
#loader_mapping{
    position: absolute;
    width: 25%;
    height: 25vh;
    background: white url('/../new_loader.gif') no-repeat center;
    z-index: 999;
    margin-left: 500px;
    margin-top: 50px;
} 
/* // shivani-3517-added code for button responsiveness. */
@media only screen and (min-width: 320px) and (max-width: 425px){
#add_jobLevel_btn{
    margin-top: -30px!important;
}
}
@media only screen and (min-width: 1024px) and (max-width: 1024px){
    #add_jobLevel_btn{
        margin-right: 520px!important;
       margin-top: -85px!important;

    }
    }
/* @uther Mufiz Khan
   Bug-4114
   File-jobLevelMapping.css
   Title-RESPONSIVE -Prod issue-Ref-(3910)-Agency-setting option-Responsiveness issue(On priority) */


    #labelDIJlm{
        font-family: roboto!important;
    font-size: 28px !important;
    font-weight: 500!important;
    text-align: center!important;
    Color: #193D8F;
    }
    @media only screen and (max-width: 700px){
   #container-fluidJblm{
       width: 700px;
   }
   
    }
    .form-control-1{
        margin-top: 5px;
    }