/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : agencyUnreadAlerts.css
 *Desc: css code changes
 */
   @media only screen and(min-width:320px) and(max-width:1024px) {
        .container-fluid{
            margin-top: 0px;
            overflow-y: scroll;
          
         }
        }
        @media only screen and(min-width:1024px) and(max-width:1440px) {
            .search{
                margin-right: 50px;
              
             }
            }
            @media only screen and(min-width:768px) and(max-width:1024px) {
                .search{
                    margin-left: 100px;
                    margin-bottom: 50px;
                    
                  
                 }
                }
                @media only screen and(min-width:768px){
                    .float-lg-right{
                        /* padding-left:100px !important; */
                        margin-bottom: 20px !important;
                    margin-left: 580px !important;
                       float: right;
                        
                          }
                    }
                    @media only screen and(min-width:0px) and(max-width:320px) {
                        .second_div{
                            width:350px !important;
                          
                         }
                        }
            @media only screen and(min-width:768px) and(max-width:1024px) {
                .first_div{
                    margin-right: 15px;
                    overflow-y: scroll;
                  
                 }
                }
                @media only screen and(min-width:768px) and(max-width:1024px) {
                    .second_div{
                        margin-left: 15px;
                        overflow-y: scroll;
                      
                     }
                    }