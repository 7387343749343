/* Copyright(c) 2022 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/
/**
* @author Somnath Keswad/Sanjana Godbole
@Filename:agencyCreativesReview.css
@Desc:css changes done
* @param  Task-3832,AgencyCreativeReview
* @return  New UI for Creative Review
*/
.getcampId {
  overflow: hidden !important;
  max-width: 200px !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  display: inline-block !important;
  margin: 0 !important;
  vertical-align: top !important;
}
/*Chaitanya-4107-Agencyside side- Review - Creatives review - status shows wrong for call script*/
@media only screen and (min-width:320px) and (max-width:375px)
{
.CreativesReviewAgency
{
  margin-right: 10% !important;
}
.AllAgency
{
  margin-right: 35% !important;
}
}
/*Chaitanya-4107-Prod Issue-Agencyside-Creatives review-status shows wrong for call script */
@media only screen and (min-width:375px) and (max-width:425px)
{
.CreativesReviewAgency
{
  margin-right: 15% !important;
}
.AllAgency
{
  margin-right: 35% !important;
}
}
/*Chaitanya-4107-Prod Issue-Agencyside-Creatives review-status shows wrong for call script */
@media only screen and (min-width:425px) and (max-width:767px)
{
.CreativesReviewAgency
{
  margin-right: 20% !important;
}
.AllAgency
{
  margin-right: 35% !important;
}
}