/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : PublisherRequest.css
 *Desc: css code changes
 */
#loader_campaign_list_publisher_request {
	position: absolute;
	width: 25%;
	height: 21vh;
	background: white url("/../new_loader.gif") no-repeat center;
	z-index: 999;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	margin-top: 47px !important;
}
@media only screen and (max-width: 320px) {
	#loader_campaign_list_publisher_request {
		margin-top: 47px !important;
		width: 70% !important;
	}
}
@media only screen and (min-width: 321px) and (max-width: 375px) {
	#loader_campaign_list_publisher_request {
		margin-top: 47px !important;
		width: 70% !important;
	}
}
@media only screen and (min-width: 376px) and (max-width: 425px) {
	#loader_campaign_list_publisher_request {
		margin-top: 47px !important;
		width: 70% !important;
	}
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
	#loader_campaign_list_publisher_request {
		width: 40% !important;
		margin-top: 47px;
	}
}