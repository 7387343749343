/* Copyright(c) 2020 APSS Media Pvt. Ltd.
*  All Rights Reserved
*
/*
@author: Karan Jagtap/kiran mate
*@fileName : supportingDocumentCreate.css
@Description: css file for supportingDocumentCreate.js
*/
body {
  overflow-x: hidden;
  font-family: Roboto;
}
.main-heading {
  padding-top: 90px;
  justify-content: center;
  width: "100%";
  display: flex;
}
.main-heading-title {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
  color: #193d8f;
  text-align: center;
}
.underline {
  border-bottom: 1px solid #1890ff;
}
.step-icon {
  width: 32px;
  height: 32px;
}
.card-addon {
  margin-top: 25px;
  margin-left: 50px;
  margin-right: 50px;
  /* margin-bottom: 25px; */
  margin-bottom: 61px !important;
}

.ant-card-body {
  width: 100%;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0a61ad;
  border-color: #0a61ad;
}

.ant-checkbox-wrapper {
  margin-left: 0 !important;
}

.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: white !important;
}

.ant-upload.ant-upload-drag {
  margin: 0px !important;
  width: 100% !important;
  height: auto !important;
}

.card-navigation {
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}

.btn-save-and-next {
  background-image: linear-gradient(to left, #0777cb, #103784) !important;
  color: white !important;

  padding: 6px 12px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn-small {
  padding: 3px 6px;
  margin: 0;
  font-size: 12px;
}

.sub-content {
  margin-top: 30px;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 3px;
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 2px solid #f5f5f5;
}

.sub-content-heading {
  position: relative;
  align-items: center;
}

.sub-content-title {
  color: orange;
  font-size: 18px;
  font-weight: 500;
  margin-right: 3px;
  margin-bottom: 0;
}

.sub-content-subtitle {
  color: black;
  font-size: 17px;
  font-weight: 500;
  margin: 0;
}

.asterisk {
  color: red;
  font-size: 18px;
  margin-bottom: 0;
}

.row-margin-fix {
  margin-left: 15px;
  margin-right: 15px;
}

.dashed-box {
  border: 2px dashed #bebec0;
}

.files-message {
  width: 100%;
  color: #989daa;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}

.total-uploaded-message {
  color: #989daa;
  font-weight: normal;
}

.checkbox-grid {
  border: 1px solid #cccccc;
  padding: 10px 15px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.files-box {
  border: 0.5px solid #cccccc;
  border-radius: 5px;
  padding: 10px 15px;
}

.files-box-header {
  font-weight: 500;
  color: black;
}

.drag-and-drop-container {
  position: relative;
  height: 170px;
  width: 100%;
  cursor: pointer;
}

.drag-and-drop-ui {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  border: 1px dashed #bebec0;
  cursor: pointer;
}

.drag-and-drop-files {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.anticon-check.anticon svg {
  background-color: inherit !important;
  color: #52c41a !important;
}

.anticon-close.anticon svg {
  background-color: inherit !important;
  color: #f5222d !important;
}

#error-list li {
  list-style-type: disc;
}

#error-list li b {
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 300;
  color: #ff0000;
}

.table-guideline {
  font-family: sans-serif;
}

.table-guideline thead tr th div {
  font-size: 12px;
  font-weight: 500;
}

.custom-classPopup {
  /* background-image: linear-gradient(to right, #144999, #144999); */
  background-color: #144999;
  color: white;
}

.custom-classPopup h4 {
  font-family: "roboto";
  font-size: 17px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 0;
}

@media (max-width: 1000px) {
  .checkbox-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 767px) {
  .main-heading-title {
    font-size: 16px;
  }

  .card-addon {
    margin-top: 15px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 61px !important;
  }

  .ant-checkbox-wrapper {
    font-size: 14px;
  }

  .sub-content-title {
    font-size: 15px;
  }

  .btn-save-and-next {
    padding: 4px 9px;
    margin: 0;
    font-size: 13px;
    font-weight: 400;
  }

  .sub-content-subtitle {
    font-size: 15px;
  }

  .files-message {
    font-size: 14px;
  }

  .checkbox-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 575px) {
  .ant-card-body {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .float-right {
    float: right !important;
  }
  .checkbox-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
