/* Copyright(c) 2020 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author: Snehal More/Sanjana Godbole
*@fileName :clientSetup.css
 *Desc: New UI Client Setup/css code changes
*/

.step {
  font-size: 24px;
  font-weight: 600;
  color: #193d8f;
  padding-top: 8%;
}
.cardStep1 {
  /* width:70%!important; */
  background-color: white !important;
  padding: 30px 0px 20px 50px;
  margin: 20px 0px 20px 0px;
}
/* kiran - task 3889- changes made for required new screen  */
.inputStyle_marketo {
  width: 95% !important;
}
.labelStyle_marketo {
  color: #337ab7;
}

@media only screen and (min-width: 0px) and (max-width: 320px) {
  .cardStep1 {
    /* width:70%!important; */
    background-color: white !important;
    padding: 0px 0px 20px 0px;
    margin: 20px 0px 20px 0px;
  }

  #marketoClientName {
    width: 94% !important;
    height: calc(2.25rem + 12px);
    width: 180px;
    border-radius: 3px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  .cardStep1 {
    background-color: white !important;
    padding: 0px 0px 20px 0px;
    margin: 20px 0px 20px 0px;
  }

  #marketoClientName {
    width: 94% !important;
    height: calc(2.25rem + 12px);
    width: 180px;
    border-radius: 3px;
  }
}

@media screen and (min-width: 375px) {
  .cardStep1 {
    background-color: white !important;
    padding: 0px 0px 20px 15px;
    margin: 20px 0px 20px 0px;
  }

  #marketoClientName {
    width: 94% !important;
    height: calc(2.25rem + 12px);
    width: 180px;
    border-radius: 3px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    margin-top: -3px !important;
    margin-left: 36px !important;
  }

  .backFrom_salesforce
  {
    margin-left: -39px !important;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
}

@media only screen and (min-width: 1441px) and (max-width: 2560px) {
}


.word-wrapmargin {/*class add vrushabh 4428 task*/
  word-break: break-all;
  margin-top: -17px;
}