/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Sanjana Godbole
 *@fileName : publisherBankDetailsEdit.css
 *Desc: css code changes
 */

@media only screen and (max-width: 768px) {
  .ant-form label {
    font-size: 9px !important;
    font-weight: 800 !important;
  }
}
@media only screen and (max-width: 1024px) {
  .ant-form label {
    font-size: 9px !important;
    font-weight: 800 !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 425px) {
  .ant-form label {
    font-size: 12px !important;
    font-weight: 700 !important;
  }
}
