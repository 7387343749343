@media only screen and (max-width: 320px){
    .camp-id{
        width:157px !important;
    }
    .camp-type{
        width:157px !important;
    }
    .start-date{
        width:157px !important;
    }
    .total-budget{
        width:157px !important;
    }
    .total-lead{
        width:157px !important;
    }
    .camp-name{
        width:157px !important;
       
    }
    .camp-region{
        width:157px !important;
    }
    .end-date{
        width:157px !important;
    }
    .remaining-budget{
        width:157px !important;
    }
    .allocated-lead{
        width:157px !important;
    }
     
    .lead-delivery{
        width:157px !important;
    }
    .camp-cpl{
        width:157px !important;
    }
    .pending-lead{
        width:157px !important;
    }



}

@media only screen and (min-width: 375px) and (max-width: 425px){
    .camp-id{
        width:157px !important;
    }

    .camp-type{
        width:157px !important;
    }
    .start-date{
        width:157px !important;
    }
    .total-budget{
        width:157px !important;
    }
    .total-lead{
        width:157px !important;
    }
    .camp-name{
        width:157px !important;
    }
    .camp-region{
        width:157px !important;
    }
    .end-date{
        width:157px !important;
    }
    .remaining-budget{
        width:157px !important;
    }
    .allocated-lead{
        width:157px !important;
    }
     
    .lead-delivery{
        width:157px !important;
    }
    .camp-cpl{
        width:157px !important;
    }
    .pending-lead{
        width:157px !important;
    }



}

@media only screen and (min-width: 768px){
    .camp-id{
        width:104px !important;
    }
    .camp-type{
        width:104px !important;
    }
    .start-date{
        width:104px !important;
    }
    .total-budget{
        width:104px !important;
    }
    .total-lead{
        width:104px !important;
    }
    .camp-name{
        width:121px !important;
        display: inline !important;
    }
    .camp-region{
        width:121px !important;
    }
    .end-date{
        width:121px !important;
    }
    .remaining-budget{
        width:121px !important;
    }
    .allocated-lead{
        width:121px !important;
    }
     
    .lead-delivery{
        width:150px !important;
    }
    .camp-cpl{
        width:150px !important;
    }
    .pending-lead{
        width:150px !important;
    }
}

    @media only screen and (min-width: 1200px){
        .camp-id{
            width:104px !important;
        }
        .camp-type{
            width:104px !important;
        }
        .start-date{
            width:104px !important;
        }
        .total-budget{
            width:104px !important;
        }
        .total-lead{
            width:104px !important;
        }
        .camp-name{
            width:121px !important;
        }
        .camp-region{
            width:121px !important;
        }
        .end-date{
            width:121px !important;
        }
        .remaining-budget{
            width:121px !important;
        }
        .allocated-lead{
            width:121px !important;
        }
         
        .lead-delivery{
            width:150px !important;
        }
        .camp-cpl{
            width:150px !important;
        }
        .pending-lead{
            width:150px !important;
        }
    }

    @media only screen and (min-width: 2560px){
        .camp-id{
            width:104px !important;
        }
        .camp-type{
            width:104px !important;
        }
        .start-date{
            width:104px !important;
        }
        .total-budget{
            width:104px !important;
        }
        .total-lead{
            width:104px !important;
        }
        .camp-name{
            width:121px !important;
        }
        .camp-region{
            width:121px !important;
        }
        .end-date{
            width:121px !important;
        }
        .remaining-budget{
            width:121px !important;
        }
        .allocated-lead{
            width:121px !important;
        }
         
        .lead-delivery{
            width:150px !important;
        }
        .camp-cpl{
            width:150px !important;
        }
        .pending-lead{
            width:150px !important;
        }
    }
    /* kiran-bug 4336- added css for responsiveness */
    @media only screen and (min-width: 768px){
        .lineNewAllocation{
            margin-top: 141px!important;
        }
    }
    @media only screen and (min-width: 1440px){
        .lineNewAllocation{
            margin-top: 21px!important;
        }
    }






