/* Copyright(c) 2020 APSS Media Pvt. Ltd.
*  All Rights Reserved
*/

/*@author Sanjana Shende,Raunak Thakkar,Snehal More,kiran mate
*@fileName :createCampaignNew.css
 *Desc: New UI for Create Campaign 
*/
.searchCount {
  padding-left: 75% !important;
}
.countrySearchInput {
  width: 25% !important;
  border-radius: 15px !important;
  margin-top: -31px !important;
  float: right !important;
}
.main {
  background-color: #f5f7f8;
}
.stepName {
  /*for the div with id stepName*/
  text-align: center;
}
.step {
  font-size: 24px;
  font-weight: 600;
  color: #193d8f;
  padding-top: 8%;
}
.stepcss.ant-steps-navigation {
  padding-top: 12px;
  border-bottom: 1px solid #1890ff;
}
.cardStep1 {
  /* width:70%!important; */
  background-color: white !important;
  padding: 0px 0px 20px 50px;
  margin: 20px 0px 20px 0px;
}

.cardStep1 .col-md-3 {
  padding-bottom: 30px;
}
.cardStep1 .col-md-6 {
  padding-bottom: 30px;
}
.cardStep1 .col-sm-12 {
  padding-bottom: 30px;
}
.inputStyle {
  width: 181px;
}
/* Snehal-Task-2937-close icon on sweet alert */
.swal2-close {
  color: #404040 !important;
  font-weight: bolder !important;
  font-size: 30px !important;
}
/* i am using this below css to increase the height of the select box */
select#CreativesApprovalRequired,
select#CreativesApprovalRequiredHrs,
select#CreativeApprovalRequiredfor,
select#Industry,
select#EmployeeSize,
select#RegionCountries,
select#CompanyRevenue,
select#JobLevel,
select#JobFunction,
.form-control {
  height: calc(2.25rem + 12px);
  border-radius: 4px;
}
.labelStyle {
  color: #14254a;
}
.asterisk {
  color: red;
}
/* //snehal-task-3112-Custom Question Alias */
.instructions p {
  color: #f28c0f !important;
}
/*Snehal-task-3112-Custom Question Alias*/
.customAlias {
  resize: none;
  height: 4em;
  width: 117% !important;
}
.customQue {
  resize: none;
  height: 4em;
  width: 111% !important;
}
.QueBox {
  padding-left: 7px !important;
  margin-left: 0px;
  padding-right: 20px !important;
  padding: 7px;
  width: 110% !important;
}
/*Snehal-task-3112-Custom Question Alias*/
.AliasBox {
  padding-left: 7px !important;
  margin-left: 0px;
  padding-right: 20px !important;
  padding: 7px;
  width: 110% !important;
}
.add_more {
  width: 100%;
  margin-left: 13px;
}

.forScroll {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 183px;
}
/* / Dropdown validation for create campaign / */
.invalidate {
  border: 1px solid red !important;
  border-radius: 4px;
}

.validate {
  border: 1px solid lightgray !important;
  border-radius: 4px;
}

.picky.pickystyle {
  width: 80%;
  position: relative;
}
.picky__input.pickystyle {
  width: 80%;
  background-color: white;
  border: 0;
  border-width: 1px;
  border-color: #eee;
  border-style: solid;
  border-radius: 2px;
  padding: 5px;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  height: 37px;
}

input#leadInteractionDays,
select#pacing {
  width: 263px !important;
}

.ant-checkbox-wrapper {
  font-weight: 400 !important;
}

/* Small devices (mobile 375 and down) */
@media only screen and (max-width: 320px) {
  .stepName {
    /*for the div with id stepName*/
    margin-top: 70px;
    font-size: 24px;
  }
  .ant-steps-item-title {
    font-size: 16px;
  }
  input#leadInteractionDays,
  select#pacing {
    width: 180px !important;
  }
  .picky__input {
    width: 60% !important;
  }
  .QueBox {
    padding-left: 59px !important;
    margin-left: 3px !important;
    padding-right: 57px !important;
    padding: 7px !important;
    width: 89% !important;
  }
  /*Snehal-task-3112-Custom Question Alias*/
  .AliasBox {
    padding-left: 59px !important;
    margin-left: 3px !important;
    padding-right: 57px !important;
    padding: 7px !important;
    width: 89% !important;
  }
  .customQue {
    resize: none;
    height: 4em !important;
    width: 77% !important;
    margin-left: -13px !important ;
  }
  /*Snehal-task-3112-Custom Question Alias*/
  .customAlias {
    resize: none;
    height: 4em !important;
    width: 77% !important;
    margin-left: -13px !important ;
  }
  .add_more {
    width: 91% !important;
    margin-left: 2px !important;
  }
  .countryWiseAllocation_popup {
    height: 280px !important;
  }

  .countrySearchInput {
    width: 61% !important;
    border-radius: 15px !important;
    margin-top: -31px !important;
    float: right !important;
  }
  .searchCount {
    padding-left: 45% !important;
  }
  /* snehal-task-3675-changes for responssiveness */
  .row.region_display_table {
    width: 226px !important;
  }
}
@media only screen and (min-width: 321px) and (max-width: 375px) {
  .row.region_display_table {
    width: 279px !important;
  }
}
@media only screen and (min-width: 376px) and (max-width: 425px) {
  .row.region_display_table {
    width: 327px !important;
  }
}
/* medium devices (Tablet 425 and down) */
@media only screen and (min-width: 321px) and (max-width: 425px) {
  .stepName {
    /*for the div with id stepName*/
    margin-top: 50px;
  }
  .ant-steps-item-title {
    font-size: 16px;
  }
  input#leadInteractionDays,
  select#pacing {
    width: 180px !important;
  }
  .picky__input {
    width: 60% !important;
  }
  .QueBox {
    padding-left: 59px !important;
    margin-left: 14px !important;
    padding-right: 74px !important;
    padding: 7px !important;
    width: 86% !important;
  }
  /*Snehal-task-3112-Custom Question Alias*/
  .AliasBox {
    padding-left: 59px !important;
    margin-left: 14px !important;
    padding-right: 74px !important;
    padding: 7px !important;
    width: 86% !important;
  }
  .customQue {
    resize: none;
    height: 4em;
    width: 82% !important;
    margin-left: -31px;
  }
  .customAlias {
    resize: none;
    height: 4em;
    width: 82% !important;
    margin-left: -31px;
  }
  .add_more {
    width: 83% !important;
    margin-left: 13px !important;
  }
  .countryWiseAllocation_popup {
    height: 280px !important;
  }
  .countrySearchInput {
    width: 61% !important;
    border-radius: 15px !important;
    margin-top: -31px !important;
    float: right !important;
  }
  .searchCount {
    padding-left: 45% !important;
  }
}
@media only screen and (min-width: 426px) and (max-width: 768px) {
  .stepName {
    /*for the div with id stepName*/
    margin-top: 50px;
  }
  .ant-steps-item-title {
    font-size: 13px;
  }
  /* //snehal-task-2798-UI/UX-Changes-3.1(start) */
  .textarea_industry {
    height: 116px !important;
    margin-top: -24px !important;
  }
  .MonthlyLeadAllocation_h1 {
    font-size: 22px;
  }
  input#leadInteractionDays,
  select#pacing {
    width: 186px !important;
  }
  .picky__input {
    width: 60% !important;
  }
  .QueBox {
    padding-left: 7px !important;
    margin-left: 14px !important;
    padding-right: 25px !important;
    padding: 7px !important;
    width: 96% !important;
  }
  /*Snehal-task-3112-Custom Question Alias*/
  .AliasBox {
    padding-left: 7px !important;
    margin-left: 14px !important;
    padding-right: 25px !important;
    padding: 7px !important;
    width: 96% !important;
  }
  .industry_picky,
  .jobFunction_picky,
  .jobLevel_picky {
    font-size: 11px;
  }
  /*Snehal-task-3112-Custom Question Alias*/
  .customQue {
    resize: none;
    height: 4em !important;
    width: 105% !important;
    margin-left: 15px !important;
  }
  .customAlias {
    resize: none;
    height: 4em !important;
    width: 105% !important;
    margin-left: 15px !important;
  }
  .add_more {
    width: 94% !important;
    margin-left: 13px !important;
  }
  .countryWiseAllocation_popup {
    height: 280px !important;
  }
  .countrySearchInput {
    width: 30% !important;
    border-radius: 15px !important;
    margin-top: -31px !important;
    float: right !important;
  }
  .searchCount {
    padding-left: 71% !important;
  }
  /* snehal-task-3675-changes for responssiveness */
  .row.region_display_table {
    width: 605px !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .countrySearchInput {
    width: 30% !important;
    border-radius: 15px !important;
    margin-top: -31px !important;
    float: right !important;
  }
  .searchCount {
    padding-left: 71% !important;
  }
  .stepName {
    /*for the div with id stepName*/
    margin-top: 50px;
  }
  .card_industry {
    width: 260px !important;
  }
  .ant-steps-item-title {
    font-size: 16px;
  }
  input#leadInteractionDays,
  select#pacing {
    width: 222px !important;
  }
  /*Snehal-task-3112-Custom Question Alias*/
  .QueBox {
    padding-left: 7px !important;
    margin-left: 7px !important;
    padding-right: 8px !important;
    padding: 7px !important;
    width: 110% !important;
  }
  .AliasBox {
    padding-left: 7px !important;
    /* margin-left: 1px  !important; */
    padding-right: 8px !important;
    padding: 7px !important;
    width: 116% !important;
  }
  .add_more {
    width: 102% !important;
    margin-left: 1px !important;
  }
  .countryWiseAllocation_popup {
    height: 280px !important;
  }
  /* snehal-task-3675-changes for responssiveness */
  .row.region_display_table {
    width: 830px !important;
  }
}
@media only screen and (min-width: 1025px) {
  .ant-steps-item-title {
    font-size: 16px;
  }
  .card_industry {
    width: 300px !important;
  }
  .QueBox {
    padding-left: 7px !important;
    /* margin-left: 1px  !important; */
    padding-right: 25px !important;
    padding: 7px !important;
    width: 110% !important;
  }
  /*Snehal-task-3112-Custom Question Alias*/
  .AliasBox {
    padding-left: 7px !important;
    /* margin-left: 1px  !important; */
    padding-right: 25px !important;
    padding: 7px !important;
    width: 116% !important;
  }
  .add_more {
    width: 102% !important;
    margin-left: 0px !important;
  }
  .countryWiseAllocation_popup {
    height: 350px !important;
  }
  /* snehal-task-3675-changes for responssiveness */
  .row.region_display_table {
    width: 1000px !important;
  }
}

{/*Chaitanya-Task-4333-Prod Issue-Agency-CountryViz Lead allocation-Done button Alignment issue on Popup*/}
@media only screen and (min-width: 320px) and (max-width: 375px) {
  .CountryVizLead{
    margin: 80px auto;
  }
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  .CountryVizLead{
    margin: 80px auto;
  }
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
  .CountryVizLead{
    margin: 80px auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .CountryVizLead{
    margin: 80px auto;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  .CountryVizLead{
    margin: 80px auto;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
  .CountryVizLead{
    margin: 80px auto;
  }
}
@media only screen and (min-width: 1441px){
  .CountryVizLead{
    margin: 80px auto;
  }
}
/* 
Auther Mufiz Khan
page Create Under 4355 Task For Responsiveness
Bug-4355-Prod issue-Agency-Create Campaign-Company Revenue Custom values issue
File Name:-NewEditCampaign.css
*/
div.scrollmenuForCamp {
  
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenuForCamp a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}
@media only screen and (max-width: 320px){
.scrollmenuForCamp {
    width: 282px;
}
#backBtnOfCamp{
  margin-left: 2rem;
}
}
@media only screen  and (max-width: 440px){
.scrollmenuForCamp {
    width: 106%;
}
}
@media only screen and (max-width: 991.98px){

.table-responsive-lg {
    display: block;
    width: 76rem;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
.scrollmenuForCamp {
    width: 100%;
}
}