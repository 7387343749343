/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author :Mufiz Khan/kiran mate
 *@fileName : customMapping.css
 *Desc: css code changes
 */
/* Bug -4117 */
#clientNameForM {
  height: 35px;
  width: 182px;
}
@media only screen and (min-width: 100px) and (max-width: 320px) {
  .custom-card.card {
    border: none !important;
  }
  input {
    margin: 3px;
  }
  #myTable3-customMapping {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 538px) and (min-width: 320px) {
  #myTable3-customMapping {
    font-size: 12px !important;
  }
  .customMapping-data {
    font-size: 12px !important;
  }
  .custom-card.card {
    border: none !important;
  }
  
  .form-control {
    width: 66% !important;
  }
  
  .duration_dropdown { /*Musbir-Task-4389 added duration_dropdown class*/
    width: 100% !important;
  }
}
@media only screen and (max-width: 500px) {
  input {
    border: solid #959595;
    border-width: 1px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 717px) {
  #myTable3-customMapping {
    font-size: 15px !important;
  }
  .customMapping-data {
    font-size: 12px !important;
  }

  .duration_dropdown { /*Musbir-Task-4389 added duration_dropdown class*/
    width: 100% !important;
  }
}
@media only screen and (max-width: 356px) {
  #labelDICM {
    width: 130%;
  }
}
@media only screen and (min-width: 650px) {
  #containerr {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 1224px) and (max-width: 1284px) {
  .container-fluidCM {
    margin-top: 103px;
  }
  #labelDICM {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1024px) {
  .container-fluidCM {
    margin-top: 103px;
  }
  #labelDICM {
    margin-top: 100px;
  }
  .duration_dropdown { /*Musbir-Task-4389 added duration_dropdown class*/
    width: 100% !important;
    margin-right: 2px;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 1440px) { /*Musbir-Task-4389 added media query with duration_dropdown class*/
  .duration_dropdown {
    width: 100% !important;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1670px) {
  #containerr {
    margin-left: 13%;
  }
  
}
@media only screen and (min-width: 1700px) and (max-width: 2560) {
  #containerr {
    margin-left: 26%;
  }
}

#labelDICM {
  width: 100%;
  margin-right: 48px;
  font-family: roboto !important;
  font-size: 39px !important;
  font-weight: 500 !important;
  text-align: center !important;
  color: #193d8f;
}

/* kiran-4299-added media query for responsiveness */
@media only screen and (min-width: 320px) and (max-width: 425px){
  #format{
    width: 100% !important;
  }

}
@media only screen and  (min-width: 425px)  {
#clientName{
  width: 66%; /* Musbir-Task-4388*/

 /* width: 66% !important; */
}
#format{
  width: 100% !important;
}
}
@media only screen and  (min-width: 768px)  {
  #format{
    width: 100% !important;
    /* width: 64% !important; */
  } 
  #clientName{
    
    width: 64%; /* Musbir- task-4388*/

    /*width: 64% !important;*/
  }
}
.accordion {
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 5px 0;
  font-size: 14px; /* Adjust font size for a smaller overall look */
}


/* rutuja 3478  */
.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff; /* White background */
  padding: 5px 10px; /* Reduce padding for a more compact header */
  cursor: pointer;
  font-weight: bold;
  color: #193d8f; /* Blue text */
  border-bottom: 1px solid #ddd;
}

.accordion-header img {
  margin-right: 20px; /* Adds space between the icon and text */
  color: #193d8f; /* Blue icon */
  margin-left: 30px; 
}

.accordion-header div:first-child {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds consistent spacing between icon and text */
}

.accordion-header div:last-child {
  color: #007bff; /* Blue dropdown arrow */
  
}

.accordion-content {
  display: none;
  padding: 15px;
  border-top: 1px solid #ccc;
}

.accordion.active .accordion-content {
  display: block;
}

.details-table {
  width: 100%;
  border-collapse: collapse;
}

.details-table th,
.details-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  width: calc(100% / 3);
}

.details-table th {
  background-color: #fff;
  font-weight: bold;
  text-align: center;
}

.details-table input {
  width: 95%;
  padding: 5px;
  box-sizing: border-box;
}

.details-table textarea {
  width: 95%;
  padding: 5px;
  height: 70px;
  box-sizing: border-box;
}
.details-table tr {
  height: 1px; /* Set the height of the row */
  line-height: 1; /* Adjust line height */
  padding: 5px 0; /* Reduce padding for a compact look */
}


.modal-backdrop {
  z-index: 1040; /* Ensure it's below the modal but above other content */
  background-color: rgba(0, 0, 0, 0); /* Lighter background */
  pointer-events: none;
}

.modal {
  z-index: 1050; /* Higher than the backdrop */
}


@media only screen and (min-width: 1023px) and (max-width: 1670px)
{
#adv {
  width: 20%; /* rutuja 5523 changed the width */
}
#dropdown {
  width: 16%; /* rutuja 5523 changed the width */
}

#save {
  width: 20%; /* rutuja 5523 changed the width */
}
#save1{
  width: 18%;
}
}


@media only screen and (min-width: 768px) and (max-width: 1020px)
{
  #adv {
    width: 50%;
  }
  #dropdown {
    width: 50%;
  }
  
  #save {
    width: 50%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 760px)

{
  #adv {
  width: 100%;
}
#dropdown {
  width: 100%;
}

#save {
  width: 50%;
}
}


/* end rutuja 3478  */