//* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : advertiserEmailConfig.css
 *Desc: 
 */
.container-ADVMC {
  margin-top: -40px;
}
.can-btn-email-adv {
  display: inline-block;
  padding: 4px 11px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.selectAllADV {
  float: right;
}
#labelDIADV {
  font-family: roboto !important;
  font-size: 28px !important;
  font-weight: 500 !important;
  text-align: center !important;
  color: #193d8f;
}
@media only screen and (min-width: 130px) and (max-width: 334px) {
  #submitBtnAdv {
    float: right;
    margin-right: -80px;
  }
  .can-btn-email-adv {
    float: right;
    margin-right: -148px;
  }
}
@media only screen and(min-width : 100px) and (max-width: 334px) {
  #submitBtnadv {
    float: right;
    margin-right: -80px;
  }
  .can-btn-email-adv {
    float: right;
    margin-right: -135px;
  }
}
@media only screen and (min-width: 334px) and (max-width: 349px) {
  .can-btn-email-adv {
    margin-left: -21px;
  }
}
@media only screen and (min-width: 298px) and (max-width: 432px) {
  .hFourAD {
    width: 25rem;
  }
}
@media only screen and (max-width: 348px) {
  .selectAllADV {
    margin-right: 13px;
  }
}
@media only screen and (min-width: 500px) and (max-width: 540px) {
  .selectAllADV {
    margin-right: 9rem;
  }
}
@media only screen and (min-width: 1224px) and (max-width: 1280px) {
  .emailformADV {
    margin-top: 3px;
    margin-left: 173px;
  }
}
@media only screen and (min-width: 1000px) and (max-width: 1024px) {
  .selectAllADV {
    margin-right: 202px;
  }
  .emailformADV {
    margin-top: 3px;
    margin-left: 100px;
  }
}
.can-btn-email-adv:hover {
  background: #193d8f;
  color: white;
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .container-ADVMC {
    width: 100%;
    margin-left: 0px;
  }
  .selectAllADV {
    margin-right: 255px;
    float: right;
  }
  .emailformADV {
    margin-left: 111px;
    margin-right: 47px;
  }
}

@media only screen and (min-width: 1199px) and (max-width: 1199px) {
  .container-ADVMC {
    width: 970px;
    margin-left: 195px;
  }
  .selectAllADV {
    margin-right: 255px;
    float: right;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1223px) {
  .emailformADV {
    margin-left: 205px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 426px) {
  .hFourAD {
    margin-bottom: 53px;
  }
  .selectAllADV {
    margin-right: -7rem;
  }
}

@media only screen and (min-width: 550px) and (max-width: 768px) {
  .selectAllADV {
    margin-right: 215px;
  }
  .emailformADV {
    margin-left: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 769px) {
  .emailformADV {
    margin-left: 100px;
  }
}
@media only screen and (min-width: 769px) and (max-width: 991px) {
  .emailformADV {
    margin-right: 19%;
    margin-left: 5%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1000px) {
  .emailformADV {
    margin-left: 135px;
  }
  .selectAllADV {
    margin-right: 147px;
  }
}
@media only screen and (min-width: 1281px) and (max-width: 1440px) {
  .emailformADV {
    margin-right: 179px;
    margin-left: 353px;
  }
}

@media only screen and (min-width: 1441px) {
  .emailformADV {
    margin-left: 237px;
    margin-right: -31px;
  }
}
@media only screen and (min-width: 1844px) {
  .container-ADVMC {
    margin-right: 21%;
    margin-left: 20%;
  }
}
/*
@author:Chaitanya
@Description:Responsive issue bug- 4291
File-agencyEmailConfig.css
Title-4291-R29-Prod issue-Publisher-Setting option-Email Notifications-submit button responsive issue 
*/
@media only screen and (min-width: 320px) and (max-width: 375px) {
  .container-ADVMC {
    margin-left: -7%;
}
.selectAllADV {
  margin-right: -5rem;
}
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  #submitBtnAdv{
    margin-right: -16%;
  }
  .selectAllADV {
    margin-right: -5rem;
}
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
  .container-ADVMC {
    margin-left: 10%;
}
#submitBtnAdv{
  margin-right: 8%;
}
.selectAllADV {
  margin-right: -3rem;
}
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  #submitBtnAdv{
    margin-right: 11%;
  }
  .selectAllADV {
    margin-right: 45px;
}
}
@media only screen and (min-width: 1024px) and (max-width: 1440px) {
  #submitBtnAdv{
    margin-right: 0%;
  }
}
@media only screen and (min-width: 1441px) {
  #submitBtnAdv{
    margin-right: 5%;
  }
}
