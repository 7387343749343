/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/*@author :kiran mate
 *@fileName : userLogin.css
 *Desc: css code changes
 */
 @import "~antd/dist/antd.css";
 .login-form {
   max-width: 300px;
 }
 .login-form-forgot {
   float: right;
 }
 .login-form-button {
   width: 100%;
 }
 /* ::placeholder {
   color:white;
   opacity: 1;
 }*/
 
 /* .has-error .ant-form-explain{
   color: #daf30e!important;
 } */
 .errorDisplay {
   color: #daf30e;
 }
 .anticon svg {
   background-color: rgb(216, 221, 230) !important;
 }
 @media only screen and (min-width: 0px) and (max-width: 320px) {
   #img1 {
     background-image: url("/Android.png");
   }
   .card11 {
     margin-left: 347px !important;
     margin-top: 50px !important;
   }
 
   .item1 {
     width: 257px !important;
     margin-left: 33px !important;
   }
   .Login-form1 {
     margin-top: 25px;
     margin-left: 32px;
   }
   /*Aman Task - 4619 -(added to give margin in switch Client Page container ) PC/PNC-Login - Client Switch option */
   .container-fluid .row #SwitchClient{
     margin: auto 20px;
     padding: 20px;
   }
 }
 /*Aman Task - 4619 -(added to give margin in switch Client Page container ) PC/PNC-Login - Client Switch option */
 @media only screen and (min-width: 0px) and (max-width: 600px) {
   .container-fluid .row #SwitchClient{
     margin: auto 20px;
     padding: 20px;
   }
 }
 @media only screen and (min-width: 375px) {
   #img1 {
     background-image: url("/Android.png");
   }
   .card11 {
     margin-left: 347px !important;
     margin-top: 50px !important;
   }
 
   .item1 {
     width: 257px !important;
     margin-left: 33px !important;
   }
   .Login-form1 {
     margin-top: 15px !important;
     margin-left: 30px !important;
   }
 }
 
 @media only screen and (min-width: 425px) {
   #img1 {
     background-image: url("/Android.png");
   }
   .card11 {
     margin-left: 347px !important;
     margin-top: 50px !important;
   }
 
   .item1 {
     width: 257px !important;
     margin-left: 33px !important;
   }
   .Login-form1 {
     margin-top: 25px !important;
     margin-left: 45px !important;
   }
 }
 
 @media only screen and (min-width: 768px) {
   #img1 {
     background-image: url("/Login_skeleton_4.png");
     /* background-size: 0vmax; 
     background-position: center center; */
   }
   .card11 {
     margin-left: 347px !important;
     margin-top: 50px !important;
   }
 
   .item1 {
     width: 257px !important;
     margin-left: 33px !important;
   }
   .Login-form1 {
     margin-top: 35px !important;
     margin-left: 30px !important;
   }
 }
 
 @media only screen and (min-width: 1024px) {
   #img1 {
     background-image: url("/Login_skeleton_3.png");
   }
   .card11 {
     margin-left: 347px !important;
     margin-top: 50px !important;
   }
 
   .item1 {
     width: 257px !important;
     margin-left: 33px !important;
   }
   .Login-form1 {
     margin-top: 94px !important;
     margin-left: 118px !important;
   }
 }
 
 @media only screen and (min-width: 1440px) {
   #img1 {
     background-image: url("/Login_skeleton_3.png");
   }
   .item1 {
     width: 257px !important;
     margin-left: 33px !important;
   }
   .Login-form1 {
     margin-top: 115px !important;
     margin-left: 124px !important;
   }
 }
 
 @media only screen and (min-width: 2560px) {
   #img1 {
     background-image: url("/Login_skeleton_3.png");
   }
   .card11 {
     margin-left: 347px !important;
     margin-top: 50px !important;
   }
 
   .item1 {
     width: 257px !important;
     margin-left: 33px !important;
   }
   .Login-form1 {
     margin-top: 140px !important;
     margin-left: 160px !important;
     padding-left: 210px !important;
     padding-top: 178px !important;
   }
 }
 
 /*sunita-task-3818-added below css as per requirement as showing white patches behind the icons*/
 .iconbackground1.anticon svg {
   background-color: #d8dde6 !important;
 }
 