/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */
/**
*@author :kiran mate
 *@fileName : AddPublisherRequest.css
 *Desc: css part for add publisher screen
  */
.btn-send-request {
  background-image: linear-gradient(to left, #0777cb, #103784) !important;
  color: white !important;
  padding: 6px 12px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 4px;
}

.card-add-publisher {
  border: 0.7px solid #cccccc;
  padding-left: 10;
  padding-right: 10;
  border-radius: 3px !important;
  margin-bottom: 20px;
  margin-top: 15px;
}

.label-style-add-publisher {
  margin-right: 5px;
  font-family: sans-serif;
  font-size: 14px;
  color: #14254a;
}

.btn-send-request:disabled {
  opacity: 0.65;
}

@media screen and (min-width: 768px) {
  .left-col-add-publisher {
    padding-right: 25px !important;
  }

  .middle-col-add-publisher {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .right-col-add-publisher {
    padding-left: 25px !important;
  }
}
