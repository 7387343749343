/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :kiran mate
 *@fileName : invoiceHistory.css
 *Desc: css code changes
 */

@media only screen and (max-width: 320px) {
  /* .dot_1{
text-align:center;
word-wrap: break-word;
word-break: break-all;
height: 5%;
    }
    .dot_2{
        text-align:center;
        word-wrap: break-word;
        word-break: break-all;
        height: 5%;
            } */
  .dot_3 {
    text-align: center;
    word-wrap: break-word;
    /* word-break: break-all; */
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .ant-table-thead > tr > th {
    font-size: 12px !important;
    /* overflow-wrap: inherit !important; */
    word-wrap: break-word !important;
    text-align: center !important;
  }
  .dot_3 {
    text-align: center !important;
    overflow-wrap: inherit !important;
    /* text-overflow: inherit !important; */
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .btn-text-uppercase {
    margin-right: 100px;
  }
}
