/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : addCountry.css
 *Desc: css code changes
 */
.custom-page-header {
	font-size: 28px;
	font-weight: 500;
	color: #193d8f;
}
.form-field-label {
	color: #14254a;
	font-family: sans-serif;
	font-size: 14px;
	white-space: nowrap;
	margin-right: 10px;
	margin-bottom: 0px;
}
.form-field {
	border-radius: 3px;
	color: #555555;
}
.primary-button {
	background-image: linear-gradient(to left, #0777cb, #103784) !important;
	color: white;
	padding: 6px 36px;
	margin: 0px;
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	border: 1px solid transparent;
	border-radius: 4px;
	font-family: sans-serif;
}
.primary-button:disabled {
	opacity: 0.64;
}
.swal-custom-cancel-button {
	background-color: transparent !important;
	border: 1px solid #193d8f !important;
	color: #193d8f !important;
	padding: 6px 36px;
	margin: 0px;
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	border-radius: 4px;
	font-family: sans-serif;
}
.divider {
	background-color: #cccccc;
	height: 0.5px;
	margin: 13px 0px !important;
	border: 0;
}
.error-message {
	font-size: 15px;
	font-family: sans-serif;
	font-weight: 300;
	color: #ff0000;
}
.page-label-addCountry {
	font-family: Roboto;
	font-size: 20px;
	font-weight: 500;
	color: #f28c0f;
}
.input-field-addition {
	border: 0px;
	margin: 0px 15px;
	resize: none !important;
	background-color: transparent;
}
.input-field-addition:focus {
	border: none;
	outline: none;
}
.custom-border-addCountry {
	border-radius: 4px !important;
	border-color: #ccc;
}