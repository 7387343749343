/* Copyright(c) 2019 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/* @uther:Mufiz Khan/Sanjana Godbole
   Bug-4193
   File-gencyInformation.css
   Title-RESPONSIVE -Prod issue-Ref-(3910)-Agency-setting option-Responsiveness issue(On priority) */

#labelDIAgi {
  font-family: roboto !important;
  font-size: 28px !important;
  font-weight: 500 !important;
  text-align: center !important;
  color: #193d8f;
}
#edit-logo {
  margin-right: 36px;
  margin-top: 23px;
  height: 24px;
  width: 27px;
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  #labelDIAgi {
    font-size: 57px !important;
  }
  .page-title {
    margin-left: 600px !important;
    font-size: 42px !important;
  }
  .agencyInfo-data {
    font-size: 42px !important;
  }
  .delivery-channel {
    font-size: 42px !important;
  }

  .email-data {
    font-size: 40px !important;
  }
  .country-data {
    font-size: 40px !important;
  }
  .address-data {
    font-size: 40px !important;
  }
  .rating-data {
    font-size: 40px !important;
  }
  .bank-data {
    font-size: 40px !important;
  }
  .pan-data {
    font-size: 40px !important;
  }
  #myTable3 {
    font-size: 10px !important;
  }
  #salesforce {
    font-size: 40px !important;
  }
  #marketo {
    font-size: 40px !important;
  }
  #edit-logo {
    width: 27px !important;
  }
}

@media only screen and (max-width: 320px) {
  #labelDIAgi {
    font-size: 57px !important;
  }

  .scrollmenu {
    width: 445px;
  }
  .page-title {
    margin-left: 65px !important;
    font-size: 20px !important;
  }
  .agencyInfo-data {
    font-size: 20px !important;
  }
  .delivery-channel {
    font-size: 20px !important;
  }
  .agencyBankInfo {
    font-size: 20px !important;
  }
  .agencyContactInfo {
    font-size: 20px !important;
  }
  .clientSalesforceSetup {
    font-size: 20px !important;
  }
  .clientMarketoSetup {
    font-size: 20px !important;
  }
  .email-data {
    font-size: 40px !important;
  }
  .country-data {
    font-size: 40px !important;
  }
  .address-data {
    font-size: 40px !important;
  }
  .rating-data {
    font-size: 40px !important;
  }
  .bank-data {
    font-size: 40px !important;
  }
  .pan-data {
    font-size: 40px !important;
  }
  #myTable3 {
    font-size: 17px !important;
  }
  #salesforce {
    font-size: 40px !important;
  }
  #marketo {
    font-size: 40px !important;
  }
  #edit-logo {
      margin-top: 0;
      float: right;
      height: 20px;
      width: 26px;
  }
  }


#labelDIAgi {
  font-size: 50px !important;
}

@media only screen and (min-width: 768px) {
  #labelDIAgi {
    font-size: 35px !important;
  }
}
@media only screen and (min-width: 769px) and (max-width: 847px) {
  #AgiContainer {
    margin-top: 6px;
  }
}
@media only screen and (min-width: 1400px) {
  .page-title {
    margin-left: 10% !important;
  }
}
@media only screen and (min-width: 2560px) {
  .page-title {
    margin-left: 540px !important;
  }
}
@media only screen and (max-width: 724px) {
  .scrollmenu {
    overflow: auto;
    white-space: nowrap;
  }

  .scrollmenu a {
    display: inline-block;
    text-align: center;
    padding: 14px;
    text-decoration: none;
  }
}
@media only screen and (min-width: 375px) and (max-width: 424px) {
  .page-title {
    margin-left: 32px !important;
  }
}

@media only screen and (min-width: 769px) and(max-width:1200px) {
  .page-title {
    margin-left: -93px !important;
  }
}
.emailformAG {
  margin-left: 91px;
}

/* This media Query for Publisher Agency Information */
@media only screen and (max-width: 660px) {
}
@media only screen and (min-width: 321px) and (max-width: 440px) {
  .scrollmenu {
    width: 126%;
  }
}
@media only screen and (min-width: 575px) {
  .float-righ {
    margin-right: 65px;
  }
}
@media only screen and (max-width: 767px) {
  #labelDIAgi {
    font-size: 40px !important;
  }
}
#edit-logo {
  margin-top: 0;
  float: right;
  height: 20px;
  width: 26px;
}