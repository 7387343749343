/* Copyright(c) 2020 APSS Media Pvt. Ltd.
 *  All Rights Reserved
 */

/*@author :Priyanka Patil
 *@fileName : newCampaign.css
 *Desc: css code changes
 */
.popup-campaign-progress1 {
	animation-name: fadeIn;
	animation-duration: 0.4s;
	background-clip: padding-box;
	background-color: #fff;
	border-radius: 4px;
	/* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); */
    /* box-shadow: -2 2px 8px rgb(0 0 0 / 15%); */
	right: 0px;
	list-style-type: none;
	margin: 0;
	outline: none;
	padding: 0;
	position: absolute;
	text-align: left;
	top: 0px;
	overflow: hidden;
	/* -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); */
    -webkit-box-shadow: -1 2px 8px rgb(0 0 0 / 15%);
}
.popup-campaign-progress1 div {
	clear: both;
	color: rgba(0, 0, 0, 0.65);
	cursor: pointer;
	font-size: 14px;
	font-weight: normal;
	line-height: 22px;
	margin: 0;
	/* padding: 5px 12px; */
	transition: all 0.3s;
	white-space: nowrap;
	-webkit-transition: all 0.3s;
	display: flex;
	align-items: center;
}
.popup-campaign-progress1 div:hover {
	background-color: #e6f7ff;
}
.popup-campaign-progress1 div > img {
	margin-right: 8px;
}

/* Mufiz-Khan
   Bug-4360
   title:-Prod Issue-Agency/Advertiser Side-View Campaign-Campaign name --Camp Setup Spec PDF -- Additional Long Comments not showing in PDF File */


	@media only screen and (max-width: 426px){
	.table-responsive-lgAdvTab {
		display: block;
		width: 50rem;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;
	}
	#containerAdvTab{
		background-color: whitesmoke;
        width: 60rem;
	}
}